import { Backdrop } from "@material-ui/core";
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import "./style.css";
import { AuthContext } from "../../router/Router";
import { Redirect } from "react-router-dom";
import Avatar from "react-avatar";
import Swal from "sweetalert2";
import Chat from "./Chat";
import "./style.css";
import NumberFormat from "react-number-format";

function Navbar({ children }) {
  const { dispatch } = useContext(AuthContext);
  const { state } = useContext(AuthContext);
  return (
    <div>
      <Chat />
      <header
        className="header-nav header-dark header-bg"
        style={{ minHeight: "70px" }}>
        <div className="fixed-header-bar">
          <div className="navbar navbar-main navbar-expand-lg">
            <div className="container">
              <div className="col-md-3 col-sm-3 text-center col-6">
                <NavLink className="navbar-brand" to="/pln">
                  <img
                    className="logo-dark"
                    alt=""
                    title=""
                    src="/img/logo_new.png"
                  />
                  <img
                    className="logo-light"
                    alt=""
                    title=""
                    src="/img/logo_new.png"
                  />
                </NavLink>
              </div>
              <div className="col-md-9 col-sm-9 col-6">
                <button
                  className="navbar-toggler float-right"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbar-main-collapse"
                  aria-controls="navbar-main-collapse"
                  aria-expanded="false"
                  aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse navbar-collapse-overlay"
                  id="navbar-main-collapse">
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item mr-5">
                      <NavLink
                        className="nav-link font-weight-bold text-center"
                        to="/chek">
                        Cek Pembayaran
                      </NavLink>
                    </li>
                    {state.user ? (
                      <>
                        <li className="nav-item">
                          <div className="nav-link font-weight-bold mr-3">
                            <NumberFormat
                              value={state.balance}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rp. "}
                              className="font-weight-bold text-warning"
                              renderText={(value) => <div>{value}</div>}
                            />
                          </div>
                        </li>
                        <li className="nav-item  px-dropdown">
                          <a className="nav-link" href="#">
                            {state.user}
                            <Avatar
                              name={state.user}
                              size={30}
                              round="50px"
                              className="ml-2"
                            />
                          </a>
                          <ul className="px-dropdown-menu mm-dorp-in">
                            <li>
                              <NavLink to="/cpmtr/dashboard" href="#">
                                Panel Admin
                              </NavLink>
                            </li>
                            {/* <li>
                              <NavLink to="/users/changepassword" href="#">
                                Ganti Password
                              </NavLink>
                            </li> */}
                            <li>
                              <a
                                href="#"
                                onClick={() =>
                                  Swal.fire({
                                    title: "Yakin keluar  ?",
                                    text: "",
                                    icon: "info",
                                    showCancelButton: true,
                                    confirmButtonText: "Keluar",
                                    cancelButtonText: "Batal",
                                  }).then((result) => {
                                    if (result.value) {
                                      dispatch({
                                        type: "LOGOUT",
                                      });
                                    } else if (
                                      result.dismiss ===
                                      Swal.DismissReason.cancel
                                    ) {
                                      //cencle
                                    }
                                  })
                                }>
                                Keluar
                              </a>
                            </li>
                          </ul>
                        </li>
                      </>
                    ) : (
                      <div className="m-auto">
                        <NavLink
                          className="btn btn-outline-primary btn-radius mr-2 font-weight-bold"
                          to="/login">
                          Masuk
                        </NavLink>
                        <NavLink
                          className="btn btn-primary btn-radius font-weight-bold"
                          to="/register">
                          Daftar
                        </NavLink>
                      </div>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="mains">{children}</div>
    </div>
  );
}

export default Navbar;
