import React from 'react';
import NumberFormat from 'react-number-format';
export const Telkom = (props) => (
    <>
        <tr>
            <td width="35%">ID PELANGGAN</td>
            <td className="text-right">{props.struk.id_pelanggan}</td>
        </tr>
        <tr>
            <td>NAMA</td>
            <td className="text-right">{props.struk.nama_pelanggan}</td>
        </tr>

        <tr>
            <td>BL/TH</td>
            <td className="text-right">{props.struk.bulan_thn}</td>
        </tr>

        <tr>
            <td>NO REF</td>
            <td className="text-right">{props.struk.no_reference}</td>
        </tr>

        <tr>
            <td>RP TAG</td>
            <td className="text-right"><NumberFormat value={props.struk.jumlah_tagihan} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>

        <tr>
            <td>ADM BANK</td>
            <td className="text-right"><NumberFormat value={props.struk.jumlah_adm} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>
        <tr>
            <td>BIAYA TRANSAKSI</td>
            <td className="text-right"><NumberFormat value={parseFloat(props.transaction.handling_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>
        <tr>
            <td className="font-weight-bold h6">SUBTOTAL</td>
            {props.discount.code ? (
                <>
                    <td className="font-weight-bold h6 text-right"><NumberFormat value={parseFloat(props.transaction.amount) + parseFloat(props.discount.admin_fee_before_discount)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
                </>
            ) : (<>
                <td className="font-weight-bold h6 text-right"><NumberFormat value={parseFloat(props.transaction.amount)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
            </>)}
        </tr>
        <tr>
            {props.discount.code ? (
                <>
                    <td className="font-weight-bold">PROMO BIAYA ADMIN <br /><span className="text-success">( {props.discount.code}  {props.discount.discount}% ) </span></td>
                    <td className="font-weight-bold h6 text-right align-middle"><NumberFormat value={parseFloat(props.discount.admin_fee_before_discount) - parseFloat(props.discount.admin_fee_after_discount)} displayType={'text'} thousandSeparator={true} prefix={'- Rp. '} renderText={value => <div className="text-success">{value}</div>} /></td>
                </>

            ) : (<>
                <td className="font-weight-bold">PROMO BIAYA ADMIN</td>
                <td className="font-weight-bold h6 text-right align-middle"><NumberFormat value={"0"} displayType={'text'} thousandSeparator={true} prefix={'- Rp. '} renderText={value => <div className="text-success">{value}</div>} /></td>
            </>)}
        </tr>
        <tr>
            <td className="font-weight-bold h6">TOTAL</td>
            <td className="font-weight-bold h6 text-right"><NumberFormat value={parseFloat(props.transaction.amount)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>
    </>
);