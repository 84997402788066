import React from 'react';

function eror404(props) {
    return (
        <div>
            <div className=" d-flex align-items-center min-vh-100">
                <div className="container text-center">
                <img src="https://cdn.dribbble.com/users/3167939/screenshots/10422336/media/b618a0e73996c3b24b58b2db1c881ee3.png" alt="" className="img-fluid mb-3" width="50%"/>
                <h5 htmlFor="image">404 | Page Not Found</h5>
                </div>
                </div>
        </div>
    );
}

export default eror404;