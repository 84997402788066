import Axios from 'axios';
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../router/Router';
import { useContext, useEffect  } from 'react';

function Customer(props) {
    const history = useHistory()
    const {state} = useContext(AuthContext);
    const { dispatch } = useContext(AuthContext);
    useEffect(() => {
      if(!state.isAuthenticated){
        if(localStorage.getItem('token')){
          const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Barier '+localStorage.getItem('token'),
                'Accept': 'application/json'
            }
          }
          Axios.post(`${process.env.REACT_APP_URL}/login/verify`,null ,config)
              .then((res) => {
                  if(res.data.status==200){
                    const redata = {
                        balance:res.data.data.balance,
                        isAuthenticated: true,
                        user: res.data.data.fullname,
                        email: res.data.data.email,
                        token: res.data.data.token,
                        role: res.data.data.roles_id
                      }
                      dispatch({
                        type: "REDATA",
                        payload: redata
                      })
  
                  }else{
                    dispatch({
                      type: "LOGOUT",
                      payload: null
                    })
                  }
            });
  
          }else{
            // history.push('/login');
          }
        }
    });
    return props.children
}

export default Customer
