import React, { useState, useEffect, useContext } from "react";
import ReactTable from "react-table";
import { AuthContext } from '../../../router/Router';
import ReactLoading from "react-loading";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import Moment from "react-moment";
import LaddaButton, { l, EXPAND_RIGHT } from "react-ladda";

function Advice(props){
    useEffect(() => {
        // setIsLoading(true);
        isiTabel();
        setIsLoading(false);
        console.log("aa");
    },[]);
    const token = localStorage.getItem("token");
    const user_id = localStorage.getItem("user_id");
    const [isLoading, setIsLoading] = useState(false);
    const [isReload, setReload] = useState(false);
    const [isDataSaldo, setIsDataSaldo] = useState([]);
    const [dataTabel, setDataTabel] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [filIdpel, setFilIdpel] = useState();
    const Loading = ({ type, color }) => (
        <ReactLoading
          type={type}
          color={color}
          className="text-center"
          height={"3%"}
          width={"3%"}
          className="d-flex justify-content-center"
        />
      );
    async function isiTabel() {
        fetch(process.env.REACT_APP_URL+'/transactions/check/advice/list',{
          method: "GET",
          headers:{
            'Authorization': "Barier " + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        }).then((response)=>{
            response.json().then((result)=>{
              //   console.warn("result",result.data.length);
                // this.setState({loading:false, users: result.data,size:result.data.length})
                // Swal.fire({
                //   title: 'Update Profile Success',
                //   icon: 'success',
                // })
                if(result.status=="200"){
                  // Swal.fire({
                  //     title: 'Sukses Disimpan',
                  //     text: result.data.desc,
                  //     icon: 'success',
                  //     confirmButtonText: 'oke'
                  // });
                  setDataTabel(result.data);
                }else{
                  Swal.fire({
                    title: 'Error!',
                    text: result,
                    icon: 'error',
                    confirmButtonText: 'oke'
                  });
                }
                // setReload(true);
            })
        })
        console.log("isi data : "+dataTabel);
      };
      async function Filter() {
        fetch(process.env.REACT_APP_URL+'/transactions/check/advice/list?start_date='+startDate+'?end_date='+endDate+'?user_id='+user_id+'?idpel='+filIdpel,{
        // fetch(process.env.REACT_APP_URL+'/transactions/refound/user/list?start_date='+startDate+'&end_date='+endDate,{
          method: "GET",
          headers:{
            'Authorization': "Barier " + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        }).then((response)=>{
            response.json().then((result)=>{
                if(result.status=="200"){
                  setDataTabel(result.data);
                }else{
                  Swal.fire({
                    title: 'Error!',
                    text: result.message,
                    icon: 'error',
                    confirmButtonText: 'oke'
                  });
                }
            })
        })
        
      };
      const textInput = React.useRef();
      const textInput1 = React.useRef();
      const textInput2 = React.useRef();
      const clearInput = () => (textInput.current.value = "");
      function Reset(){
        textInput.current.value = "";
        textInput1.current.value = "";
        textInput2.current.value = "";
        document.querySelectorAll('idpel');
        setIsLoading(true);
        setFilIdpel();
        setStartDate();
        setEndDate();
        isiTabel();
        setIsLoading(false);
      };
      async function Update(id){
        console.log(id);
        Swal.fire({
          title: 'Anda yakin akan melakukan transaki ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
        }).then((result)=>{
          if(result.isConfirmed){
            fetch(process.env.REACT_APP_URL+'/transactions/check/advice/'+id,{
              method: "POST",
              headers:{
                'Authorization': "Barier " + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
              },
            }).then((response)=>{
                response.json().then((result)=>{
                    if(result.status=="200"){
                      // setDataTabel(result.data);
                      Swal.fire({
                        title: 'SUCCESS!',
                        text: result.message,
                        icon: 'success',
                        confirmButtonText: 'oke'
                      });
                    }else{
                      Swal.fire({
                        title: 'Error!',
                        text: result.message,
                        icon: 'error',
                        confirmButtonText: 'oke'
                      });
                    }
                })
            })
            isiTabel();
          }else{

          }
        })
        
      }
    const columns = [
        {
          Header: "#",
          id: "no",
          maxWidth: 50,
          Cell: (row) => {
            return <div>{row.index + 1}</div>;
          },
          style: {
            textAlign: "center",
            textTransform: "uppercase",
            flexDirection: "column",
            justifyContent: "center",
          },
        },
        {
          Header: "IDPEL",
          accessor: "idpel",
          style: {
            textAlign: "left",
            textTransform: "uppercase",
            flexDirection: "column",
            justifyContent: "center",
          },
          
        },
        {
          Header: "TOTAL",
          accessor: "tagihan_amount",
          style: {
            textAlign: "left",
            // textTransform: "uppercase",
            flexDirection: "column",
            justifyContent: "center",
          },
          Cell: props => new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR',minimumFractionDigits: 0  }).format(props.value)
        },
        {
          Header: "DESKRIPSI",
          accessor: "tagihan_description",
          style: {
            textAlign: "left",
            textTransform: "uppercase",
            flexDirection: "column",
            justifyContent: "center",
          },
          
        },
        {
          Header: "TIPE BAYAR",
          accessor: "payment_method",
          style: {
            textAlign: "center",
            textTransform: "uppercase",
            flexDirection: "column",
            justifyContent: "center",
          },
        },
        {
          Header: "TANGGAL",
          accessor: "created_at",
          style: {
            textAlign: "center",
            flexDirection: "column",
            justifyContent: "center",
          },
          Cell: (row) => (
            <div>
              <Moment format="DD MMM YYYY - HH:mm:ss">{row.original.date}</Moment>
            </div>
          ),
        },
        {
          Header: "AKSI",
          maxWidth:"180",
          accessor: "approve_status",
          style: {
            textAlign: "center",
            flexDirection: "column",
            justifyContent: "center",
          },
          Cell: (row) => (
              <div>
                    <button
                    className="btn btn-flat btn-sm btn-danger"
                    onClick={() => {
                        Update(row.original.id);
                        }}
                    >
                Proses
                    </button>
              </div>
          )
        },
      ];
    return (
        <div>
            <main className="container-fluid pt-4 mt-5">
                <nav aria-label="breadcrumb my-2">
                <ol className="breadcrumb mb-4" type="none">
                    <li className="breadcrumb-item">
                    <a href="#">Mitra</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Advice
                    </li>
                </ol>
                </nav>

                <div className="row">
                <div className="col-md-12">
                    <div className="card mt-2 rounded-2 border-white shadow">
                    <div className="card-header bg-light">
                        <b>Manual Advice / Cek Status</b>
                    </div>
                    <div className="card-body">
                        <div
                        // className={"alert alert-" + displayAlert.type}
                        // style={{ display: displayAlert.display }}
                        role="alert"
                        >
                        {/* <strong>{displayAlert.message}</strong> */}
                        </div>
                        { isLoading ? (
                        <Loading type="spinningBubbles" color="#91091e" />
                        ) : (
                        <>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-1 d-flex align-items-center">
                                        <span>Idpel</span>
                                    </div>
                                    <div className="col-md-2 d-flex align-items-center">
                                        <input 
                                            className="form-control form-control-sm"
                                            type="text"
                                            name="idpel"
                                            placeholder="Masukkan idpel"
                                            onChange={(e) => setFilIdpel(e.target.value)}
                                            value={filIdpel}
                                            ref={textInput}
                                        />
                                    </div>
                                    <div className="col-md-1 d-flex align-items-center">
                                        <span>Tgl. Awal</span>
                                    </div>
                                    <div className="col-md-2 d-flex align-items-center">
                                        <input 
                                            className="form-control form-control-sm"
                                            type="date"
                                            name="idpel"
                                            placeholder="Start Date"
                                            onChange={(e) => setStartDate(e.target.value)}
                                            value={startDate}
                                            ref={textInput1}
                                        />
                                    </div>
                                    <div className="col-md-1 d-flex align-items-center">
                                        <span>Tgl. Akhir</span>
                                    </div>
                                    <div className="col-md-2 d-flex align-items-center">  
                                        <input 
                                            className="form-control form-control-sm"
                                            type="date"
                                            name="idpel"
                                            placeholder="End Date"
                                            onChange={(e) => setEndDate(e.target.value)}
                                            value={endDate}
                                            ref={textInput2}
                                        />
                                    </div>
                                    <div className="col-md-2 d-flex align-items-center">
                                        <LaddaButton
                                            loading={isLoading}
                                            className="btn btn-primary m-btn-radius w-100"
                                            data-color="#008aff"
                                            data-size='s'
                                            onClick={() => Filter()}
                                        >FILTER</LaddaButton>
                                    </div>
                                    <div className="col-md-1 d-flex align-items-center">
                                      <LaddaButton
                                          loading={isLoading}
                                          className="btn btn-danger m-btn-radius w-100"
                                          data-color="#008aff"
                                          data-size='s'
                                          onClick={Reset}
                                      >RESET</LaddaButton>
                                  </div>
                                </div>
                            </div>
                            <ReactTable
                            // defaultSorted={[{ id: "tanggal", desc: true }]}
                            data={dataTabel}
                            //   defaultPageSize={isDataSaldo.length >= 10 ? 10 : 5}
                            defaultPageSize= {5}
                            columns={columns}
                            className="mt-3 table-striped w-100"
                            />
                            {console.log("data"+dataTabel)}
                        </>
                        )}
                    </div>
                    </div>
                </div>
                </div>
            </main>
        </div>
    )
};
export default Advice;