import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import LaddaButton, { l, EXPAND_RIGHT } from 'react-ladda';

const apiUrl = process.env.REACT_APP_URL;
class data extends Component {
  constructor(props) {
    super(props);

    this.state = {
      product_id: 0,
      nominal_display: 0,
      name: "",
      idpel: null,
      description: "",
      displayMasaBerlaku: "none",
      messageError: "",
      btnDisabled: "disabled",
      dangerDisplay: "none",
      paketData: [],
      logoOperator: "",
      token: localStorage.getItem("token"),
      inquiry: false,
      payment: false,
      prod_category: null,
      trx_type: "2100",
      textButton: "Beli",
      inqres: [],
      message: "",
      amount: 0,
      token: localStorage.getItem("token"),
    };
  }

  setInfo(event) {
    let disabled = "disabled";
    if (this.state.idpel == null) {
      this.setState({
        messageError: "Masukkan nomor telepon terlebih dahulu",
      });
    } else {
      if (this.state.idpel.length > 10) {
        disabled = "";
      }
      if (event.price == null) {
        event.price = 0;
      }
      this.setState({
        nominal_display: event.price,
        name: event.nama_display,
        product_id: event.prod_id,
        amount: event.price + event.admin_fee + event.handling_fee,
        admin_fee: event.admin_fee,
        handling_fee: event.handling_fee,
        description: "",
        displayMasaBerlaku: "none",
        btnDisabled: disabled,
        textButton: "Beli",
      });
    }
  }
  getPulsa = (opr) => {
    let apiUrlOp = "";
    let logoOp = "";
    let prod_cat = 0;
    if (
      opr === "12" ||
      opr === "13" ||
      opr === "21" ||
      opr === "22" ||
      opr === "52" ||
      opr === "53" ||
      opr === "23" ||
      opr === "51"
    ) {
      // Operator telkomsel
      prod_cat = 82;
      apiUrlOp = apiUrl + "/products/" + prod_cat;
      logoOp = "telkomsel.png";
    } else if (
      opr === "14" ||
      opr === "15" ||
      opr === "16" ||
      opr === "55" ||
      opr === "56" ||
      opr === "57" ||
      opr === "58"
    ) {
      // Operator indosat
      prod_cat = 92;
      apiUrlOp = apiUrl + "/products/" + prod_cat;
      logoOp = "indosat.jpg";
    } else if (
      opr === "17" ||
      opr === "18" ||
      opr === "19" ||
      opr === "59" ||
      opr === "77" ||
      opr === "78"
    ) {
      // Operator XL
      prod_cat = 84;
      apiUrlOp = apiUrl + "/products/" + prod_cat;
      logoOp = "xl.png";
    } else if (
      opr === "30" ||
      opr === "31" ||
      opr === "32" ||
      opr === "33" ||
      opr === "34" ||
      opr === "35" ||
      opr === "36" ||
      opr === "37" ||
      opr === "38" ||
      opr === "39"
    ) {
      // Operator axis
      prod_cat = 83;
      apiUrlOp = apiUrl + "/products/" + prod_cat;
      logoOp = "axis.png";
    } else if (
      opr === "95" ||
      opr === "96" ||
      opr === "97" ||
      opr === "98" ||
      opr === "99"
    ) {
      // Operator three
      prod_cat = 89;
      apiUrlOp = apiUrl + "/products/" + prod_cat;
      logoOp = "three.png";
    } else if (
      opr === "81" ||
      opr === "82" ||
      opr === "83" ||
      opr === "84" ||
      opr === "85" ||
      opr === "86" ||
      opr === "87" ||
      opr === "88" ||
      opr === "89"
    ) {
      // Operator smartfren
      prod_cat = 95;
      apiUrlOp = apiUrl + "/products/" + prod_cat;
      logoOp = "smartfren.jpg";
    }
    if (apiUrlOp === "") {
      this.setState({
        paketData: [],
        dangerDisplay: "block",
        logoOperator: "",
      });
    } else {
      // let token ="CUAN-REST $2a$10$a1hNujOfYRHT6bGacbBkEOXBk476HswO8cNgLSJ6e2EtLEfYavqZG";
      axios
        .get(apiUrlOp, {
          headers: {
            Authorization: "Barier " + this.state.token,
          },
        })
        .then((res) => {
          console.log(res);
          this.setState({
            paketData: res.data.data,
            dangerDisplay: "none",
            logoOperator: logoOp,
            prod_category: prod_cat,
          });
        });
    }
  };
  rupiahFormat(num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  }
  validateNoTelp = (e) => {
    let noTelp = e.target.value;
    this.setState({ idpel: noTelp });
    if (noTelp.length === 0) {
      this.setState({
        messageError: "Masukkan nomor telepon terlebih dahulu",
        btnDisabled: "disabled",
        displayMasaBerlaku: "none",
        nominal_display: 0,
      });
    } else {
      let opr = noTelp.substring(2, 4);
      if (!noTelp.match(/^[0-9]+$/)) {
        this.setState({
          paketData: "",
          messageError: "Nomor telepon hanya boleh berisi angka(08..)",
          btnDisabled: "disabled",
          displayMasaBerlaku: "none",
          nominal_display: 0,
          logoOperator: "",
          prod_category: null,
        });
      } else {
        if (noTelp.length >= 4) {
          this.getPulsa(opr);
          this.setState({
            idpel: noTelp,
          });
        } else {
          this.setState({
            paketData: "",
            dangerDisplay: "none",
            displayMasaBerlaku: "none",
            nominal_display: 0,
            logoOperator: "",
            prod_category: null,
          });
        }
        if (noTelp.length < 10) {
          this.setState({
            messageError: "Nomor terlalu pendek, minimal 11 angka",
            btnDisabled: "disabled",
            displayMasaBerlaku: "none",
            nominal_display: 0,
            prod_category: null,
          });
        } else {
          this.setState({ messageError: "" });
        }
      }
    }
  };
  renderLogoOp() {
    if (this.state.logoOperator != "")
      return <img src={"img/logo_operator/" + this.state.logoOperator} />;
  }
  checkout() {
    this.setState({ loading: true });
    console.log(this.state);
    this.setState({ textButton: "proses...", btnDisabled: "disabled" });

    fetch(`${process.env.REACT_APP_URL}/transactions/inquiry`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Barier " + this.state.token,
        Accept: "application/json",
      },
      body: JSON.stringify(this.state),
    }).then((response) => {
      response.json().then((result) => {
        console.log(result);
        //inq berhasil
        if (result.status == "200") {
          if (this.state.trx_type == "2100" && result.data.rc == "0000") {
            localStorage.setItem(
              "private_inquiry",
              JSON.stringify({
                data: result.data,
                product_id: this.state.product_id,
                admin_fee: this.state.admin_fee,
                handling_fee: this.state.handling_fee,
                product_nomination: this.state.product_nomination,
              })
            );
            this.setState({
              inquiry: true,
              inqres: result.data,
            });
          } else {
            this.setState({
              message: result.data.desc,
              textButton: "Beli",
              btnDisabled: "",
            });
            Swal.fire({
              title: "Maaf",
              text: result.data.desc || "Silahkan dicoba beberapa saat lagi",
              icon: "info",
              confirmButtonText: "oke",
            });
          }
        } else {
          Swal.fire({
            title: "Maaf",
            text: result.message || "Silahkan dicoba beberapa saat lagi",
            icon: "info",
            confirmButtonText: "oke",
          });
          this.setState({
            message: result.info,
            textButton: "Beli",
            btnDisabled: "",
          });
        }
      });
      this.setState({ loading: false });
    }).catch((error) => {
      this.setState({
        loading: false,
        message: error.response.data.message.desc == "" ? error.response.data.message.status : error.response.data.message.desc,
      });
      if (error.response) {
        Swal.fire({
          title: "Maaf",
          text: error.response.data.message.desc == "" ? error.response.data.message.status : error.response.data.message.desc,
          icon: "error",
          confirmButtonText: "oke",
        });
      } else if (error.code === "ECONNABORTED")
        Swal.fire({
          title: "Maaf",
          text:
            "Maaf Produk sedang gangguan atau tidak bisa bisa di transaksikan sekarang ",
          icon: "error",
          confirmButtonText: "oke",
        });
      else throw error;
    });
  }
  render() {
    const enter = {
      fontSize: "14px",
      display: "block",
    };
    return (
      <div>
        {this.state.inquiry ? (
          <>
            <Redirect to="/checkoutv1" />
          </>
        ) : (
          <></>
        )}
        <h5 className="text-dark">Beli Paket Data</h5>
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="form-group">
              <h6 className="text-black">Nomor HP</h6>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  style={{ borderRight: "0" }}
                  className="form-control"
                  placeholder="Ketikkan nomor HP"
                  onChange={this.validateNoTelp}
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text"
                    style={{
                      backgroundColor: "white",
                      border: "white 1px black",
                    }}>
                    {this.renderLogoOp()}
                  </span>
                </div>
              </div>
              <p className="text-danger">{this.state.messageError}</p>
            </div>
          </div>
        </div>
        {this.state.paketData.length != 0 ? (
          <>
            <h6 className="font-weight-bold">Pilih Paket Data</h6>
            <div
              className="row"
              ref={(el) => {
                this.el = el;
              }}>
              {this.state.paketData.map((value, index) => {
                return (
                  <div
                    className="col-xl-2 col-md-2 col-lg-2 col-6 mt-3 value.nominal "
                    key={index}
                    onClick={(e) => {
                      this.setInfo(value);
                    }}>
                    <div
                      className="btn-group border rounded"
                      role="group"
                      style={{ backgroundColor: "#F9F9F9" }}>
                      <button
                        type="button"
                        className="btn text-center w-100 {index=='0' ? 'active' : 'active'}">
                        {" "}
                        <b
                          style={{
                            fontSize: "12px",
                            display: "block",
                            fontWeight: "normal",
                          }}>
                          {value.nama_display}
                        </b>{" "}
                        <label
                          style={{ fontSize: "14px" }}
                          className="font-weight-bold">
                          {" "}
                          Rp {this.rupiahFormat(value.price + 0)}
                        </label>
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              className="card border border-primary bg-primary-300 mt-3"
              style={{
                backgroundColor: "#dbeefd",
                display: this.state.displayMasaBerlaku,
              }}>
              <div className="card-body">
                <p className="mb-0">
                  <b className="text-primary">Keterangan</b>
                  <br />
                  <span>{this.state.masa_berlaku}</span>
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            {this.state.paketData.length == 0 &&
              this.state.prod_category != null ? (
              <>
                <div
                  className="card border border-primary bg-primary-300 mt-1"
                  style={{ backgroundColor: "#dbeefd" }}>
                  <div className="card-body">
                    <p className="small">
                      <b className="text-primary">Informasi</b>
                      <br />
                      Produk sedang kosong
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        )}
        <div className="row mt-3">
          <div className="col-md-12">
            <hr></hr>
            <div className="row">
              <div className="col-md-9 col-xs-12 text-right">
                <label>Harga</label>
                <h6 className="text-danger">
                  Rp {this.rupiahFormat(this.state.nominal_display)}
                </h6>
              </div>
              <div className="col-md-3 col-xs-12">
                <LaddaButton
                  loading={this.state.loading}
                  disabled={!(this.state.idpel != null && this.state.idpel.length > 10)}
                  onClick={() => {
                    this.checkout();
                  }}
                  data-color="#008aff"
                  data-size={l}
                  className="m-btn m-btn-theme m-btn-radius w-100"
                  data-style={EXPAND_RIGHT}
                >
                  Beli
                </LaddaButton>
              </div>
            </div>
          </div>
        </div>
        <div
          className="card border border-primary bg-primary-300 mt-3"
          style={{ backgroundColor: "#dbeefd" }}>
          <div className="card-body">
            <p className="small">
              <b className="text-primary">Keterangan</b>
              <br></br>
              1. Produk Paket Data tidak tersedia pada jam cut off/maintenance (23.00 - 01.00).<br></br>
              2. Proses verifikasi pembayaran maksimal 1 x 24 jam hari kerja.<br></br>
              3. Untuk metode pembayaran menggunakan virtual account / transfer bank akan dikenakan biaya transaksi sebesar <b>Rp. 4.500</b>.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default data;
