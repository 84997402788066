import React from 'react';
import NumberFormat from 'react-number-format';

const Parsetanggal = ({ periode }) => {
    if (periode === '01') {
        return 'January';
    }else if (periode === '02') {
        return 'Februari';
    }else if (periode === '03') {
        return 'Maret';
    }else if (periode === '04') {
        return 'April';
    }else if (periode === '05') {
        return 'Mei';
    }else if (periode === '06') {
        return 'Juni';
    }else if (periode === '07') {
        return 'Juli';
    }else if (periode === '08') {
        return 'Agustus';
    }else if (periode === '09') {
        return 'September';
    }else if (periode === '10') {
        return 'Oktober';
    }else if (periode === '11') {
        return 'November';
    }else if (periode === '12') {
        return 'Desember';
    }
}

var moment = require('moment');

export const BpjsInq = (props) => (
<>
<tr className="table-borderless">
    <td>NOMOR VA</td>
    <td className="text-right">{props.struk.no_va}</td>
</tr>
<tr>
    <td>NAMA</td>
    <td className="text-right">{props.struk.name}</td>
</tr>

<tr>
    <td>PERIODE PEMBAYARAN</td>
<td className="text-right"><Parsetanggal periode={props.struk.periode} /> {moment().add(props.struk.periode, 'M').format('YYYY')}</td>
</tr>

<tr>
    <td>SISA SEBELUMNYA</td>
    <td className="text-right"><NumberFormat value={parseInt(props.struk.sisa)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr>

<tr>
    <td>BIAYA IURAN</td>
    <td className="text-right"><NumberFormat value={props.struk.premi} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr>

<tr>
    <td>ADMIN BANK</td>
    <td className="text-right"><NumberFormat value={props.struk.admin_charge} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr>
</>
);