import React from 'react';

function Transaction(props) {
    return (
        <div>
            <>
            <main className="container pt-5 mt-5">
               
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">Home</li>
                    </ol>
                </nav>

                <div className="card my-2">
                    <div className="card-body">
                        PAGE TRANSACTION
                    </div>
                </div>
            </main>
            </>
        </div>
    );
}

export default Transaction;