import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from '../../../router/Router';
import { Redirect } from 'react-router-dom';
import { FaFilter, FaSyncAlt } from "react-icons/fa";
import Chart from "react-google-charts";
import ReactLoading from "react-loading";
import axios from "axios";
import Moment from "react-moment";
import Swal from "sweetalert2";

import "./style.css";

function Dashboard(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [paramDate, setParamDate] = useState({start_date:"", end_date:""});
  const [isReload, setReload] = useState(false);
  const [refreshDate, setRefreshDate] = useState(Date().toLocaleString());
  const [userActive, setUserActive] = useState(0);
  const Loading = ({ type, color }) => (
    <ReactLoading
      type={type}
      color={color}
      className="text-center"
      height={"4%"}
      width={"4%"}
      className="d-flex justify-content-center"
    />
  );
  const [isTrxProd, setTrxProd] = useState([
    ["Element", "Lembar", { role: "style" }],
    ["PREPAID", 0, "#003f5c"],
    ["POSTPAID", 0, "#2f4b7c"],
    ["NONTAGLIS", 0, "#665191"],
    ["BPJS", 0, "#a05195"],
    ["PDAM", 0, "#d45087"],
    ["TELKOM", 0, "#f95d6a"],
    ["MINIPACK", 0, "#ff7c43"],
    ["PULSA", 0, "#ffa600"],
    ["VOUCHER GAMES", 0, "#CB4335"],
    ["PAKET DATA", 0, "#2471A3"],
  ]);
  const [isDataTrx, setDataTrx] = useState([
    ["Task", "Persentase Sukses & Gagal"],
    ["Sukses", 0],
    ["Gagal", 0],
  ]);
  const options_dataTrxProd = {
    legend: "none",
  };
  const options_dataTrx = {
    //title: "My Daily Activities",
    // legend: "none",
    // pieSliceText: "label",
    pieHole: 0.3,
    is3D: false,
    pieStartAngle: 100,
    slices: {
      1: { offset: 0.2 },
    },
  };
  const [isAuth, setAuth] = useState(0);
  const token = localStorage.getItem("token");

  const fetchTrxProd = async () => {
    await setReload(false);
    let url = "";
    if(paramDate.start_date == "" || paramDate.end_date == ""){
      url = await "/jml/product/trx";
    }else{
      url = await "/jml/product/trx?start_date="+paramDate.start_date+"&end_date="+paramDate.end_date;
    }
    await axios
      .get(`${process.env.REACT_APP_URL}`+url, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
          console.warn("res trx prod", res.data.data);
          const arrProduk = ["Prepaid","Postpaid","Nontaglist","BPJS","PDAM","Telkom","Minipack","Pulsa","voucher_game","Paket_data"];

          for(var i=0; i< arrProduk.length; i++){
            let objProduk = res.data.data[arrProduk[i]];
            // console.warn(arrProduk[i], objProduk);
            if(arrProduk[i] in res.data.data){
              // console.warn(arrProduk[i], "true => " + objProduk[0].trx_sukses);
              isTrxProd[i+1][1] = objProduk[0].trx_sukses;
            }else{
              // console.warn(arrProduk[i], "false => 0");
              isTrxProd[i+1][1] = 0;
            }
          }
        // console.warn("init trx prod", isTrxProd);
      }).catch((err) => {
        // console.log(err.response.data)
        Swal.fire({
          title: "Uppss..",
          text: err.response ? err.response.data.message : "Terjadi Kesalahan Sistem. Coba sesaat lagi",
          icon: "error",
          confirmButtonText: "Oke",
        });
        setAuth(err.response.data.status)
        // setAuth(401);
      });
    await setReload(true);
  };
  const fetchJmlTrx = async () => {
    await setReload(false);
    let url = "";
    if (paramDate.start_date == "" || paramDate.end_date == "") {
      url = await "/jml/trx";
    } else {
      url = await "/jml/trx?start_date="+paramDate.start_date +"&end_date=" +paramDate.end_date;
    }
    await axios
      .get(`${process.env.REACT_APP_URL}`+url, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.data.trx_sukses == 0 && res.data.data.trx_gagal == 0) {
          isDataTrx[1][1] = 0;
          isDataTrx[2][1] = 0;
        } else {
          isDataTrx[1][1] = res.data.data.trx_sukses;
          isDataTrx[2][1] = res.data.data.trx_gagal;
        }
        console.warn("res jml trx", res.data.data);
        console.warn("init jml trx", isDataTrx);
      });
    await setReload(true);
  };
  const fetchUserActive = async () => {
    await setReload(false);
    await axios
      .get(`${process.env.REACT_APP_URL}/jml/users`, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setUserActive(res.data.data[0].user_active);
        console.warn("res user active", res.data.data);
        // console.warn("init jml trx", isDataTrx);
      });
    await setReload(true);
  };
  const timeoutLoading = () => {
    const timeId = setTimeout(() => {
      setIsLoading(false);
      let currDate =  Date().toLocaleString();
      setRefreshDate(currDate);
    }, 3000);
    return () => {
      clearTimeout(timeId);
    };
  }
  useEffect(() => {
    setIsLoading(true);
    fetchTrxProd();
    fetchJmlTrx();
    fetchUserActive();
    timeoutLoading();
    console.warn("param date",paramDate);
  }, []);
  const hitData = () => {
    setIsLoading(true);
    fetchTrxProd();
    fetchJmlTrx();
    fetchUserActive();
    timeoutLoading();
  };
  const filterData = async () => {
    if(paramDate.start_date == "" || paramDate.end_date == ""){
      Swal.fire({
        title: "Upss!",
        text: "Lengkapi dahulu tanggal awal dan tanggal akhir",
        icon: "info",
        confirmButtonText: "oke",
      });
    }else{
      await hitData();
    }
  }
  const resetFilter = async () => {
      // await setIsLoading(true);
      // await setParamDate({start_date:"",end_date:""});
      // await timeoutLoading();
      // await hitData();
    window.location.reload()
  }
  if(isAuth === 401){
    return <Redirect to="/login" />;
  }
  return (
    <div>
      <>
        <main className="container-fluid pt-4 mt-5">
          <nav aria-label="breadcrumb my-2">
            <ol className="breadcrumb mb-4" type="none">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard
              </li>
            </ol>
          </nav>

          <div className="row">
            <div className="col-md-12">
              <div className="card mb-2 rounded-2 border-white shadow">
                <div className="card-header bg-light">
                  <b>Filter</b>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="text-dark">Tanggal Awal</label>
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          name="start_date"
                          value={paramDate.start_date}
                          onChange={(e) =>
                            setParamDate({
                              start_date: e.target.value,
                              end_date: paramDate.end_date,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="text-dark">Tanggal Akhir</label>
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          name="end_date"
                          value={paramDate.end_date}
                          onChange={(e) =>
                            setParamDate({
                              start_date: paramDate.start_date,
                              end_date: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button
                    type="button"
                    className="btn btn-primary btn-flat btn-sm"
                    onClick={() => filterData()}
                  >
                    <FaFilter className="" /> Filter
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-flat btn-sm ml-2"
                    onClick={() => resetFilter()}
                  >
                    Reset
                  </button>
                  <span className="float-right">
                    <code className="text-secondary">Data Tanggal : </code>
                    <code className="mr-2">
                      {paramDate.start_date == "" || paramDate.end_date == "" ? (
                        <Moment format="DD MMM YYYY">{refreshDate}</Moment>
                      ) : (
                        <>
                          <Moment format="DD MMM YYYY">
                            {paramDate.start_date}
                          </Moment>
                          {" - "}
                          <Moment format="DD MMM YYYY">
                            {paramDate.end_date}
                          </Moment>
                        </>
                      )}
                    </code>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="card rounded-2 border-white shadow">
                <div className="card-header bg-light">
                  <b>Grafik Jumlah Lembar</b>
                  <span className="float-right">
                    <code className="text-secondary">Diperbarui : </code>
                    <code className="mr-2">
                      <Moment format="DD MMM YYYY - HH:mm:ss">
                        {refreshDate}
                      </Moment>
                    </code>
                    <button
                      className="btn btn-outline-success btn-sm"
                      onClick={() => hitData()}
                    >
                      <FaSyncAlt /> perbarui
                    </button>
                  </span>
                </div>
                <div className="card-body">
                  <div className="row no-gutters align-items-center py-2 position-relative border-bottom border-200">
                    {!isReload || isLoading ? (
                      <Loading type="spinningBubbles" color="#91091e" />
                    ) : (
                      <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="500px"
                        data={isTrxProd}
                        options={options_dataTrxProd}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card border-white shadow">
                <div className="card-header bg-light">
                  <b>Persentase Sukses & Gagal</b>
                  <span className="float-right">
                    <button
                      className="btn btn-outline-success btn-sm"
                      onClick={() => hitData()}
                    >
                      <FaSyncAlt /> perbarui
                    </button>
                  </span>
                </div>
                <div className="card-body">
                  {!isReload || isLoading ? (
                    <Loading type="spinningBubbles" color="#91091e" />
                  ) : (
                    <Chart
                      chartType="PieChart"
                      width="100%"
                      height="300px"
                      data={isDataTrx}
                      options={options_dataTrx}
                    />
                  )}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  <div className="card border-white shadow">
                    <div className="card-header bg-light">
                      <b>Pengguna Aktif</b>
                    </div>
                    <div className="card-body">
                      <table className="table table-borderless">
                        <tbody>
                          <tr className="text-success">
                            <th>Online</th>
                            <th>:</th>
                            <th>{userActive} Pengguna</th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    </div>
  );
}

export default Dashboard;
