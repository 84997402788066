import React, { useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { FaTelegram } from 'react-icons/fa';

function Chat(props) {
    const [toogle,setToogel] = useState();
    const [isActive, setActive] = useState("false");

    const handleToggle = () => {
        setActive(!isActive);
    };

    return (
        <div>
            <div className="wa__btn_popup" onClick={handleToggle} >
                <div className="wa__btn_popup_icon"><FaWhatsapp size={'xl'} className="p-3"/></div>
            </div>
            <div className={isActive ? 'wa__popup_chat_box wa__pending wa__lauch' : 'wa__popup_chat_box wa__pending wa__lauch wa__active'}>
                <div className="wa__popup_heading" style={{backgroundColor:'#037afb'}}>
                <div className="wa__popup_title"> Start a Conversation</div>
                <div className="wa__popup_intro">Hi! Click one of our members below to chat on <strong>WhatsApp and Telegram</strong>
                    <div id="\&quot;eJOY__extension_root\&quot;" />
                </div>
                </div>
                {/* /.wa__popup_heading */}
                <div className="wa__popup_content wa__popup_content_left">
                <div className="wa__popup_notice">Tim kami biasanya membalas dalam hitungan menit</div>
                <div className="wa__popup_content_list">
                    <div className="wa__popup_content_item ">
                    <a target="_blank" href="https://api.whatsapp.com/send?phone=6283870574102" className="wa__stt wa__stt_online">
                        <div className="wa__popup_avatar">
                        <div className="wa__cs_img_wrap" style={{background: 'url("./img/chat/wa.png") center center no-repeat', backgroundSize: 'cover'}} />
                        </div>
                        <div className="wa__popup_txt">
                        <div className="wa__member_name">Whatsapp</div>
                        {/* /.wa__member_name */}
                        <div className="wa__member_duty">Customer Support</div>
                        {/* /.wa__member_duty */}
                        </div>
                        {/* /.wa__popup_txt */}
                    </a>
                    </div>
                    <div className="wa__popup_content_item ">
                    <a target="_blank" href="https://t.me/shosha_telegram" className="wa__stt wa__stt_online">
                        <div className="wa__popup_avatar">
                        <div className="wa__cs_img_wrap" style={{background: 'url("./img/chat/tele.png") center center no-repeat', backgroundSize: 'cover'}} />
                        </div>
                        <div className="wa__popup_txt">
                        <div className="wa__member_name">Telegram 1</div>
                        {/* /.wa__member_name */}
                        <div className="wa__member_duty">Customer Support</div>
                        {/* /.wa__member_duty */}
                        </div>
                        {/* /.wa__popup_txt */}
                    </a>
                    </div>
                    <div className="wa__popup_content_item ">
                    <a target="_blank" href="https://t.me/OP_JellyNew" className="wa__stt wa__stt_online">
                        <div className="wa__popup_avatar">
                        <div className="wa__cs_img_wrap" style={{background: 'url("./img/chat/tele.png") center center no-repeat', backgroundSize: 'cover'}} />
                        </div>
                        <div className="wa__popup_txt">
                        <div className="wa__member_name">Telegram 2</div>
                        {/* /.wa__member_name */}
                        <div className="wa__member_duty">Customer Support</div>
                        {/* /.wa__member_duty */}
                        </div>
                        {/* /.wa__popup_txt */}
                    </a>
                    </div>
                </div>
                {/* /.wa__popup_content_list */}
                </div>
                {/* /.wa__popup_content */}
            </div>
        </div>
    );
}

export default Chat;