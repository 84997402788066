import React, { Component } from "react";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";
import "react-table/react-table.css";
import Select from "react-select";
import LaddaButton, { l, EXPAND_RIGHT } from "react-ladda";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import Axios from "axios";
Axios.defaults.timeout = process.env.REACT_APP_TIMEOUT_INQ;

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Loader type="ThreeDots" color="#007bff" height="100" width="100" />
      </div>
    )
  );
};
const namaBulan = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

class telkom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      struk: true,
      loading: true,
      idpel: null,
      message: null,
      nominal: null,
      trx_type: "2100",
      product_id: null,
      users: [],
      btnInq: true,
      inqRes: [],
      payRes: [],
      bulanTahun: null,
    };
  }

  componentDidUpdate() {
    // this.scrollToBottom();
  }
  componentDidMount() {
    this.getproduct();
    this.setState({
      struk: false,
      loading: false,
    });

    console.log(window.location.pathname);
  }

  getproduct() {
    fetch(`${process.env.REACT_APP_URL}/products/62`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((result) => {
        console.warn("result", result.data.length);
        this.setState({ users: result.data });
      });
    });
  }
  handleChangeIdpel(e) {
    let inIdpel = e.target.value;
    if (inIdpel.length >= 6 && inIdpel.length <= 12) {
      this.setState({ message: "" });
      this.setState({ idpel: inIdpel });
      // this.cekinquiry();
      console.log(this.state.idpel);
    } else {
      this.setState({ message: "idpel tidak sesuai" });
    }
  }

  currencyFormat(num) {
    return parseFloat(num)
      .toFixed(0)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1");
  }

  getBulan1(num) {
    return parseInt(num.toString().substr(4, 2));
  }
  getBulan2(num) {
    return parseInt(num.toString().substr(10, 2));
  }
  getTahun1(num) {
    return parseInt(num.toString().substr(0, 4));
  }
  getTahun2(num) {
    return parseInt(num.toString().substr(6, 4));
  }

  chekprodukid(data) {
    if (!data) {
      Swal.fire({
        title: "Maaf",
        text: "Pilih Wilayah dahulu",
        icon: "info",
        confirmButtonText: "oke",
      });
    }
  }

  async cekinquiry() {
    await this.setState({ loading: true });
    this.chekprodukid(this.state.product_id);
    let token =
      "CUAN-REST $2a$10$a1hNujOfYRHT6bGacbBkEOXBk476HswO8cNgLSJ6e2EtLEfYavqZG";
    var data = JSON.stringify({
      trx_type: "2100",
      trx_id: "",
      idpel: this.state.idpel,
      product_id: this.state.product_id,
      product_nomination: "",
      periode_payment: "",
    });

    var config = {
      method: "POST",
      url: `${process.env.REACT_APP_URL}/transactions/inquiry`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: data,
    };
    Axios(config)
      .then(function (result) {
        if (result.status == "200") {
          if (this.state.trx_type == "2100" && result.data.rc == "00") {
            localStorage.setItem(
              "private_inquiry",
              JSON.stringify({
                data: result.data,
                produk: "PDAM",
                product_id: this.state.product_id,
                product_nomination: this.state.product_nomination,
              })
            );
            this.setState({ inquiry: true, inqres: result.data });
          } else {
            this.setState({ message: result.data.desc });
            Swal.fire({
              title: "Maaf",
              text: result.data.desc,
              icon: "error",
              confirmButtonText: "oke",
            });
          }
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          message: error.response.data.message.desc == "" ? error.response.data.message.status : error.response.data.message.desc,
        });
        if (error.response) {
          Swal.fire({
            title: "Maaf",
            text: error.response.data.message.desc == "" ? error.response.data.message.status : error.response.data.message.desc,
            icon: "error",
            confirmButtonText: "oke",
          });
        } else if (error.code === "ECONNABORTED")
          Swal.fire({
            title: "Maaf",
            text:
              "Maaf Produk sedang gangguan atau tidak bisa bisa di transaksikan sekarang ",
            icon: "error",
            confirmButtonText: "oke",
          });
        else throw error;
      });
  }

  handleChange = (value) => {
    this.setState({ value: value });
    console.log(this.state.value);
    let produk_id = value.value;
    this.setState({ product_id: produk_id });
  };

  render() {
    const btnInq = this.state.btnInq;
    const struk = this.state.struk;
    const { users } = this.state;
    const produxs = this.state.users.map((d) => ({
      value: d.prod_id,
      label: d.name.toUpperCase(),
    }));
    return (
      <div>
        {this.state.inquiry ? (
          <>
            <Redirect to="/checkoutv1" />
          </>
        ) : (
          <></>
        )}
        {struk ? (
          <div></div>
        ) : (
          <div>
            <h5 className="text-dark">Bayar Tagihan PDAM</h5>
            <div className="mt-3">
              <h6>Pilih Area/Kota</h6>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <Select
                    options={produxs}
                    value={this.state.value}
                    onChange={(value) => this.handleChange(value)}
                    placeholder={<div>Ketik Produk</div>}
                  />
                  {/* <b className="text-warning">{this.state.message}</b> */}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="form-group">
                  <h6 className="text-black">ID Pelanggan </h6>
                  <input
                    type="number"
                    className="form-control"
                    defaultValue=""
                    placeholder="ID Pelanggan"
                    onChange={(e) => {
                      this.handleChangeIdpel(e);
                    }}
                  />
                </div>
              </div>
            </div>

            {btnInq ? (
              <div>
                <div className="col-md-12 mt-3">
                  <div className="row">
                    <div className="col-md-9 text-right">
                      <label></label>
                      <h6></h6>
                    </div>
                    <div className="col-md-3">
                      <LaddaButton
                        loading={this.state.loading}
                        disabled={!this.state.idpel}
                        onClick={() => {
                          this.setState({ loadingL: true });
                          this.cekinquiry();
                        }}
                        data-color="#008aff"
                        data-size={l}
                        className="m-btn m-btn-theme m-btn-radius w-100"
                        data-style={EXPAND_RIGHT}>
                        Cek Tagihan
                      </LaddaButton>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="col-md-12">
                  <hr></hr>
                  <div className="row">
                    <div className="col-md-10 col-12 small">
                      <div className="row small">
                        <div className="col-md-2 border-right">
                          <label>Nama Lengkap</label>
                          <br></br>
                          <b className="text-danger">
                            {this.state.inqRes.name}
                          </b>
                        </div>

                        <div className="col-md-2 border-right">
                          <label>Periode</label>
                          <br></br>
                          <b className="text-danger">{this.state.bulanTahun}</b>
                        </div>

                        <div className="col-md-2 border-right">
                          <label>Jumlah Tagihan</label>
                          <br></br>
                          <b className="text-danger">
                            {this.state.inqRes.bill_count}
                          </b>
                        </div>

                        <div className="col-md-2 border-right">
                          <label>Tagihan</label>
                          <br></br>
                          <b className="text-danger">
                            Rp. {this.state.inqRes.rp_tag}
                          </b>
                        </div>

                        <div className="col-md-2 border-right">
                          <label>Biaya Admin</label>
                          <br></br>
                          <b className="text-danger">
                            Rp.{" "}
                            {this.currencyFormat(
                              this.state.inqRes.admin_charge
                            )}
                          </b>
                        </div>

                        <div className="col-md-2">
                          <label>Total Tagihan</label>
                          <br></br>
                          <b className="text-danger">
                            Rp. {this.currencyFormat(this.state.inqRes.amount)}
                          </b>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <button
                        // type="submit"
                        className="btn btn-lg btn-danger"
                        style={{ height: "100%" }}
                        onClick={() => this.payment()}>
                        Bayar Sekarang<br></br>Rp.{" "}
                        {this.currencyFormat(this.state.inqRes.amount)}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              className="card border border-primary bg-primary-300 mt-3"
              style={{ backgroundColor: "#dbeefd" }}>
              <div className="card-body">
                <p className="small">
                  <b className="text-primary">Keterangan</b>
                  <br></br>
                  1. Produk Air PDAM tidak tersedia pada jam cut off/maintenance (23.00 - 01.00).<br></br>
                  2. Transaksi pembayaran tagihan Air PDAM membutuhkan waktu proses maksimal 1x24 jam.<br></br>
                  3. Untuk metode pembayaran menggunakan virtual account / transfer bank akan dikenakan biaya transaksi sebesar <b>Rp. 4.500</b>.
                </p>
              </div>
            </div>
            <br></br>
            <br></br>
          </div>
        )}
      </div>
    );
  }
}

export default telkom;
