import React, { Component } from "react";
import imageHero from "./img/logo.svg";
import { FaAngleRight } from "react-icons/fa";
import { Route, Switch } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaBolt } from "react-icons/fa";
import { FaWater } from "react-icons/fa";
import { FaWifi } from "react-icons/fa";
import { FaHospitalAlt } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { FaRegListAlt } from "react-icons/fa";
import { FaAddressCard } from "react-icons/fa";
import { FaGamepad } from "react-icons/fa";
import { FaGlobe } from "react-icons/fa";

//conponent produk
import Pln from "../products/pln";
import Pulsa from "../products/pulsa";
import Bpjs from "../products/bpjs";
import Telkom from "../products/telkom";
import Pdam from "../products/pdam";
import Data from "../products/data";
// import Game from '../products/game';

class MenuBatch extends Component {
  render() {
    return (
      <div>
        <section className="section"></section>
        <section className="section g-bg-gray p-0px-t section-top-up-100 bg-section">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-lg-12 col-12 m-15px-tb">
                <div className="card border-radius-20">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-1 col-lg-1 col-3 mt-2 mb-1">
                        <NavLink
                          className="card p10 pt-3 text-center white-bg box-shadow border-radius-5 theme-hover-bg text-decoration-none border-0"
                          to="/pln"
                          activeClassName="bg-primary white-color theme-hover-bg">
                          <div className="only-icon-30 d-inline-block w-100">
                            <FaBolt />
                          </div>
                          <b className="m-0px small mt-2">PLN</b>
                        </NavLink>
                      </div>
                      <div className="col-md-1 col-lg-1 col-3 mt-2 mb-1">
                        <NavLink
                          className="card p10 pt-3 text-center white-bg box-shadow border-radius-5 border-0 theme-hover-bg text-decoration-none"
                          to="/bpjs"
                          activeClassName="bg-primary white-color theme-hover-bg">
                          <div className="only-icon-30 d-inline-block w-100">
                            <FaHospitalAlt />
                          </div>
                          <b className="m-0px small mt-2">BPJS</b>
                        </NavLink>
                      </div>
                      <div className="col-md-1 col-lg-1 col-3 mt-2 mb-1">
                        <NavLink
                          className="card p10 pt-3 text-center white-bg box-shadow border-radius-5 theme-hover-bg text-decoration-none border-0"
                          to="/pulsa"
                          activeClassName="bg-primary white-color theme-hover-bg">
                          <div className="only-icon-30 d-inline-block w-100">
                            <FaMobileAlt />
                          </div>
                          <b className="m-0px small mt-2">PULSA</b>
                        </NavLink>
                      </div>
                      <div className="col-md-1 col-lg-1 col-3 mt-2 mb-1">
                        <NavLink
                          className="card p10 pt-3 text-center white-bg box-shadow border-radius-5 theme-hover-bg text-decoration-none border-0"
                          to="/telkom"
                          activeClassName="bg-primary white-color theme-hover-bg">
                          <div className="only-icon-30 d-inline-block w-100">
                            <FaWifi />
                          </div>
                          <b className="m-0px small mt-2">Telkom</b>
                        </NavLink>
                      </div>
                      <div className="col-md-1 col-lg-1 col-3 mt-2 mb-1">
                        <NavLink
                          className="card p10 pt-3 text-center white-bg box-shadow border-radius-5 theme-hover-bg text-decoration-none border-0"
                          to="/pdam"
                          activeClassName="bg-primary white-color theme-hover-bg">
                          <div className="only-icon-30 d-inline-block w-100">
                            <FaWater />
                          </div>
                          <b className="m-0px small mt-2">PDAM</b>
                        </NavLink>
                      </div>
                      <div className="col-md-1 col-lg-1 col-3 mt-2 mb-1">
                        <NavLink
                          className="card p10 pt-3 text-center white-bg box-shadow border-radius-5 theme-hover-bg text-decoration-none border-0"
                          to="/data"
                          activeClassName="bg-primary white-color theme-hover-bg">
                          <div className="only-icon-30 d-inline-block w-100">
                            <FaGlobe />
                          </div>
                          <b className="m-0px small mt-2">DATA</b>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card mt-2">
                  <div className="card-body">
                    <Route path="/pln" component={Pln} />
                    <Route path="/pulsa" component={Pulsa} />
                    <Route path="/bpjs" component={Bpjs} />
                    <Route path="/telkom" component={Telkom} />
                    <Route path="/pdam" component={Pdam} />
                    <Route path="/data" component={Data} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <div className="d-none d-lg-block  p-0px-t section-top-up-100">
                <img src="./img/bg.png" alt="" className="w-100 img-fluid"/>
            </div> */}
      </div>
    );
  }
}

export default MenuBatch;
