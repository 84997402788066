import React,{Component} from 'react';
import ReactTable from "react-table"; 
import 'react-table/react-table.css'
import  { Redirect } from 'react-router-dom'
import ReactLoading from 'react-loading';
import LaddaButton, { l, EXPAND_RIGHT } from 'react-ladda';
import Swal from 'sweetalert2'
import Select from 'react-select';
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Modal from "react-bootstrap/Modal";

const Loading = ({ type, color }) => (
    <ReactLoading type={type} color={color} height={'10%'} width={'10%'} />
);

class Produk extends Component{
  constructor(props){
    super(props)
    this.state = {
      users: [],
      loading:false,
      login:false,
      token:localStorage.getItem('token'),
      size:0,
      isEdit: false,
      produkId: [],
      prodId: null,
      valId: null,
      isLoading: false,
      filterId: null,
      filterVal: null,
    };
  }

  showModal = (type, data) => {
    if (type == "Edit") {
      this.setState({
        id: data.id,
        name: data.name,
      });
    } else {
      this.setState({
        id: 0,
        name: "",
      });
    }
    this.setState({ setIsOpen: true, typeModal: type });
  };

  hideModal = () => {
    this.setState({ setIsOpen: false });
  };

  handleChange = (e) => {
    // console.warn([e.target.name], e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeFilter = (value) => {
    this.setState({filterVal: value});
    console.log(this.state.filterVal)
    let fil_id = value.value;
    console.log(fil_id);
    this.setState({filterId: fil_id});
  };

  update() {
      // Swal.fire({
      //     title: 'Yakin akan menyimpannya?',
      //     showCancelButton: true,
      //     confirmButtonText: 'SIMPAN',
      // }).then((result) => {
      //     if(result.isConfirmed){
            fetch(process.env.REACT_APP_URL + '/roles/update/' + this.state.id, {
                  method: "put",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Barier " + this.state.token,
                    Accept: "application/json",
                  },
                  body: JSON.stringify({
                    name: this.state.name,
                  })
                })
                .then((response) =>{
                    response.json().then((result)=>{
                        if(result.status=="200"){
                            this.getproduct();
                            this.getproductId();
                            this.setState({isEdit:false,setIsOpen: false});
                            Swal.fire({
                                title: 'Sukses Disimpan',
                                text: result.data.desc,
                                icon: 'success',
                                confirmButtonText: 'oke'
                            });
                        }
                        else{
                          this.setState({ message: result.data.desc });
                          Swal.fire({
                            title: 'Upss!',
                            text: result.data.desc,
                            icon: 'error',
                            confirmButtonText: 'oke'
                          });
                        }
                    })
                });
      //     }
      // })
  }

  create() {
    // Swal.fire({
    //     title: 'Yakin akan menyimpannya?',
    //     showCancelButton: true,
    //     confirmButtonText: 'SIMPAN',
    // }).then((result) => {
    //     if(result.isConfirmed){
          fetch(process.env.REACT_APP_URL + '/roles/create', {
                method: "post",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Barier " + this.state.token,
                  Accept: "application/json",
                },
                body: JSON.stringify({
                  name: this.state.name,
                })
              })
              .then((response) =>{
                  response.json().then((result)=>{
                      if(result.status=="200"){
                          this.getproduct();
                          this.getproductId();
                          this.setState({isEdit:false,setIsOpen: false});
                          Swal.fire({
                              title: 'Sukses Disimpan',
                              text: result.data.desc,
                              icon: 'success',
                              confirmButtonText: 'oke'
                          });
                      }
                      else{
                        this.setState({ message: result.message });
                        Swal.fire({
                          title: 'Upss!',
                          text: result.message[0].msg,
                          icon: 'error',
                          confirmButtonText: 'oke'
                        });
                      }
                  })
              });
    //     }
    // })
  }

  confirmDelete(value){
    Swal.fire({
      title: 'Yakin akan Menghapus?',
      text: 'Product Id : '+value.id+' | NAME : '+value.name,
      showCancelButton: true,
      confirmButtonText: 'DELETE',
    }).then((result) => {
        if(result.isConfirmed){
          fetch(process.env.REACT_APP_URL + '/roles/delete/' + value.id, {
            method: "delete",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Barier " + this.state.token,
              Accept: "application/json",
            },
          })
          .then((response) =>{
              response.json().then((result)=>{
                  if(result.status=="200"){
                      this.getproduct();
                      this.getproductId();
                      this.setState({isEdit:false,setIsOpen: false});
                      Swal.fire({
                          title: 'Sukses Dihapus',
                          text: result.data.desc,
                          icon: 'success',
                          confirmButtonText: 'oke'
                      });
                  }
                  else{
                    this.setState({ message: result.data.desc });
                    Swal.fire({
                      title: 'Upss!',
                      text: result.data.desc,
                      icon: 'error',
                      confirmButtonText: 'oke'
                    });
                  }
              })
          });
        }
      })
  }

  componentDidMount(){
    this.getproduct();
    this.getproductId();
  }

  getproduct()
  {
      this.setState({loading:true})
      fetch(process.env.REACT_APP_URL+'/roles',{
          method: "GET",
          headers:{
            "Content-Type": "application/json",
            Authorization: "Barier " + this.state.token,
            Accept: "application/json",
          }
      }).then((response)=>{
          response.json().then((result)=>{
            //   console.warn("result",result.data.length);
              this.setState({loading:false, users: result.data,size:result.data.length})
            //   console.warn(this.state.users);
          })
      })
  }

  getproductId()
  {
      fetch(process.env.REACT_APP_URL+'/roles',{
          method: "GET",
          headers:{
            "Content-Type": "application/json",
            Authorization: "Barier " + this.state.token,
            Accept: "application/json",
          }
      }).then((response)=>{
          response.json().then((result)=>{
              this.setState({
                produkId: result.data
              })
          })
      })
  }

  getfilter()
  {
      this.setState({loading:true})
      fetch(process.env.REACT_APP_URL+'/roles/'+this.state.filterId,{
          method: "GET",
          headers:{
            "Content-Type": "application/json",
            Authorization: "Barier " + this.state.token,
            Accept: "application/json",
          }
      }).then((response)=>{
          response.json().then((result)=>{
              this.setState({loading:false, users: result.data,size:result.data.length})
          })
      })
  }
  
  reset(){
    this.getproduct();
    this.getproductId();
    this.setState({ filterVal: null, filterId: null});
  }

 render(){

    const { options, value ,options_pulsa } = this.state;
    const columns = [
      {
        Header: "#",
        maxWidth: 50,
        Cell: (row) => {
          return <div>{row.index + 1}</div>;
        },
        style: {
          textAlign: "center",
          textTransform: "uppercase",
          flexDirection: "column",
          justifyContent: "center",
        },
      },
      {
        Header: "NAMA ROLE",
        accessor: "name",
        maxWidth: "5%",
        style: {
          textAlign: "left",
          textTransform: "uppercase",
          flexDirection: "column",
          justifyContent: "center",
        },
      },
      {
        Header: "AKSI",
        accessor: "id",
        maxWidth: 150,
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
        filterable: false,
        Cell: (row) => (
          <div>
            <button
              className="btn btn-flat btn-sm btn-warning"
              onClick={() => this.showModal("Edit", row.original)}
            >
              Edit
            </button>
            &nbsp;
            <button
              onClick={() => this.confirmDelete(row.original)}
              className="btn btn-flat btn-sm btn-danger"
            >
              Hapus
            </button>
          </div>
        ),
      },
    ];
    const filter = this.state.produkId.map(d=>({
      "value" : d.id,
      "label" : d.id+" - "+d.name
    }));
    
   return (
    <>
    <div>
        <main className="container-fluid pt-4 mt-5">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb" type="none">
              <li class="breadcrumb-item">
                <a href="#">Admin</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">Master</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Role Users
              </li>
            </ol>
          </nav>
          
          <div className="card mt-2 rounded-2 border-white shadow">
            <div className="card-header text-dark bg-light">
              <b>Data Role Users</b>
            </div>
            <div className="card-body">
              <div className="row mt-3">
                <div className="col-md-6">
                  <Select 
                      name = "filterId"
                      options = {filter}
                      value={this.state.filterVal} 
                      onChange={value => this.handleChangeFilter(value)} 
                      placeholder={<div>Pilih Role</div>}
                      />
                </div>
                <div className="col-md-2">
                  <LaddaButton
                    loading={this.state.loading}
                    className="btn btn-primary m-btn-radius w-100"
                    data-color="#008aff"
                    data-size={l}
                    onClick={() => this.getfilter()}
                  >FILTER</LaddaButton>
                </div>
                <div className="col-md-2">
                  <LaddaButton
                    loading={this.state.loading}
                    className="btn btn-danger m-btn-radius w-100"
                    data-color="#008aff"
                    data-size={l}
                    onClick={() => this.reset()}
                  >RESET</LaddaButton>
                </div>
                <div className="col-md-2">
                  <LaddaButton
                    loading={this.state.loading}
                    className="btn btn-success m-btn-radius w-100"
                    data-color="#008aff"
                    data-size={l}
                    onClick={() => this.showModal("Tambah", )}
                  >TAMBAH</LaddaButton>
                </div>
              </div>
              {this.state.isLoading ? (
                <Loading type="spinningBubbles" color="#91091e" />
              ) : (
                <ReactTable
                  data={this.state.users}
                  // pageSize={this.state.dataDenom.length > 10 ? 10 : this.state.dataDenom.length}
                  defaultPageSize= {5}
                  columns={columns}
                  className="mt-3 table-striped w-100"
                />
              )}
            </div>
          </div>
        </main>
      </div>

      <Modal show={this.state.setIsOpen} onHide={() => this.hideModal()}>
          <Modal.Header>
            <Modal.Title>{this.state.typeModal} Role Users</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <input
                type="hidden"
                name="id"
                value={this.state.id}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <label>
                Nama Role <sup className="text-danger">*</sup>
              </label>

              <input
                type="text"
                name="name"
                id="name"
                className="form-control"
                required=""
                value={this.state.name}
                onChange={this.handleChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => this.hideModal()}
              className="btn btn-outline-secondary"
            >
              Batal
            </button>
            {this.state.typeModal == "Edit" ? (
            <LaddaButton
              loading={this.state.loading}
              onClick={() => {
                this.update(this.state.typeModal);
              }}
              className="btn btn-primary"
            >
              Simpan
            </LaddaButton>
            ):(
              <LaddaButton
              loading={this.state.loading}
              onClick={() => {
                this.create(this.state.typeModal);
              }}
              className="btn btn-primary"
            >
              Simpan
            </LaddaButton>
            )}
          </Modal.Footer>
        </Modal>
    </>
   )
 } 
}

export default Produk;