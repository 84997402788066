import React from 'react';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';

/* eslint eqeqeq: 0 */
const Parsetanggal = ({ periode }) => {
    if (periode == '01') {
        return 'January';
    } else if (periode == '02') {
        return 'Februari';
    } else if (periode == '03') {
        return 'Maret';
    } else if (periode == '04') {
        return 'April';
    } else if (periode == '05') {
        return 'Mei';
    } else if (periode == '06') {
        return 'Juni';
    } else if (periode == '07') {
        return 'Juli';
    } else if (periode == '08') {
        return 'Agustus';
    } else if (periode == '09') {
        return 'September';
    } else if (periode == '10') {
        return 'Oktober';
    } else if (periode == '11') {
        return 'November';
    } else if (periode == '12') {
        return 'Desember';
    }
}

var moment = require('moment');

export const Bpjs = (props) => (
    <>
        <tr>
            <td>NOMOR VA</td>
            <td className="text-right"><div style={{ wordWrap: "break-word" }}>{props.struk.no_va}</div></td>
        </tr>
        <tr>
            <td>NAMA</td>
            <td className="text-right">{props.struk.name}</td>
        </tr>

        <tr>
            <td>PERIODE</td>
            <td className="text-right"><Parsetanggal periode={props.struk.periode} /> {moment().add(props.struk.periode, 'M').format('YYYY')}</td>
        </tr>

        <tr>
            <td>NO REF</td>
            <td className="text-right"><div style={{ wordWrap: "break-word" }}>{props.struk.sw_reff}</div></td>
        </tr>

        <tr>
            <td>SISA</td>
            <td className="text-right"><NumberFormat value={parseInt(props.struk.sisa)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>

        <tr>
            <td>WAKTU LUNAS</td>
            <td className="text-right"><Moment format="DD MMM YYYY HH:mm:ss">{props.struk.datetime.substr(0, 4) + "-" + props.struk.datetime.substr(4, 2) + "-" + props.struk.datetime.substr(6, 2) + " " + props.struk.datetime.substr(8, 2) + ":" + props.struk.datetime.substr(10, 2) + ":" + props.struk.datetime.substr(12, 2)}</Moment></td>
        </tr>

        <tr>
            <td>BIAYA IURAN</td>
            <td className="text-right"><NumberFormat value={parseFloat(props.struk.premi)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>

        <tr>
            <td>ADM BANK</td>
            <td className="text-right"><NumberFormat value={parseFloat(props.struk.admin_charge)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>

        {/* <tr>
            <td>RP BAYAR</td>
            <td><NumberFormat value={parseFloat(props.struk.amount)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr> */}
        <tr>
            <td>BIAYA TRANSAKSI</td>
            <td className="text-right"><NumberFormat value={parseFloat(props.transaction.handling_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>
        <tr>
            <td className="font-weight-bold h6">TOTAL</td>
            <td className="font-weight-bold h6 text-right"><NumberFormat value={parseFloat(props.transaction.amount)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>
    </>
);