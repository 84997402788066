import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";

function LoginPage(props) {
  const { register, handleSubmit, errors } = useForm();
  const [message, setMessage] = useState();
  const [chaptha, setChaptha] = useState(false);
  const [registers, setRegisters] = useState(false);
  const recaptchaRef = React.createRef();

  const onSubmit = (data, e) => {
    if (chaptha) {
      setMessage({
        data: "Registration is in progress...",
        type: "alert-warning",
      });
      fetch(`${process.env.REACT_APP_URL}/users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Barier " + process.env.REACT_APP_TOKEN,
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          const hasError = "error" in data && data.status != 200;
          setMessage({
            data: hasError ? data.error : "Registered successfully",
            type: hasError ? "alert-danger" : "alert-success",
          });

          !hasError && e.target.reset();
          if (!hasError) {
            setRegisters(true);
          }
        });
    } else {
      setMessage({
        data: "Chek Rechaptha",
        type: "alert-warning",
      });
    }
  };

  const onChange = (value) => {
    if (value) {
      setChaptha(true);
    }

    console.log(chaptha);
  };

  return (
    <div>
      <div className="container">
        {!registers ? (
          <div className="row mt-2 ">
            <div className="col-md-6 d-none m-auto d-lg-block">
              <img src="/img/register.png" alt="" className="img-fluid" />
            </div>
            <div className="col-md-6 m-auto">
              <div className="card shadow">
                <div className="card-body">
                  <div className="text-center p-3">
                    <img
                      className="logo-light mb-3"
                      alt=""
                      title=""
                      src="/img/logo_new.png"
                    />
                    <h5>Daftar Akun</h5>
                    {/* <span className="small">Daftar Akun</span> */}
                    <div>
                      {message && (
                        <div
                          className={`alert fade show d-flex ${message.type}`}
                          role="alert">
                          {message.data}
                          <span
                            aria-hidden="true"
                            className="ml-auto cursor-pointer"
                            onClick={() => setMessage(null)}>
                            &times;
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    autoComplete="off">
                    <div className="form-group">
                      <label htmlFor="inputForName">Nama Lengkap</label>
                      <span className="mandatory">*</span>
                      <input
                        id="inputForfull_name"
                        name="full_name"
                        type="text"
                        className="form-control"
                        aria-describedby="Masukkan nama lengkap"
                        placeholder="Masukkan nama lengkap"
                        ref={register({
                          required: {
                            value: true,
                            message: "Nama lengkap masih kosong",
                          },
                          maxLength: {
                            value: 100,
                            message: "Maksimal terdiri dari 100",
                          },
                        })}
                      />
                      {errors.full_name && (
                        <span className="small text-danger text-center">
                          {errors.full_name.message}
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="inputForEmail">Email</label>
                      <span className="mandatory">*</span>
                      <input
                        id="inputForEmail"
                        name="email"
                        type="email"
                        className="form-control"
                        aria-describedby="Masukka alamat email"
                        placeholder="Masukka alamat email"
                        ref={register({
                          required: {
                            value: true,
                            message: "Email masih kosong",
                          },
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Format email tidak sesuai",
                          },
                        })}
                      />
                      {errors.email && (
                        <span className="small text-danger text-center">
                          {errors.email.message}
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="inputForName">Username</label>
                      <span className="mandatory">*</span>
                      <input
                        id="inputForName"
                        name="username"
                        type="text"
                        className="form-control"
                        aria-describedby="Masukkan username"
                        placeholder="Masukkan username"
                        ref={register({
                          required: {
                            value: true,
                            message: "Username masih kosong",
                          },
                          pattern: {
                            value: /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/,
                            message:
                              "Mohon masukkan username yang valid tanpa spasi",
                          },
                          minLength: {
                            value: 5,
                            message: "Minimum username 5 karakter",
                          },
                          maxLength: {
                            value: 15,
                            message: "Maksimal username 15 karakter",
                          },
                        })}
                      />
                      {errors.username && (
                        <span className="small text-danger text-center">
                          {errors.username.message}
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <input
                        id="inputFor"
                        name="media"
                        type="hidden"
                        value={process.env.REACT_APP_PARAM}
                        className="form-control"
                        aria-describedby="Masukka alamat email"
                        placeholder="Masukka alamat email"
                        ref={register({
                          required: {
                            value: true,
                            message: "param",
                          },
                          maxLength: {
                            value: 100,
                            message: "Maksimal terdiri dari 100",
                          },
                        })}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="inputForPassword">Password</label>
                      <span className="mandatory">*</span>
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        id="inputForPassword"
                        placeholder="Masukkan password"
                        ref={register({
                          required: {
                            value: true,
                            message: "Password masih kosong",
                          },
                          minLength: {
                            value: 6,
                            message: "Minimum password 6 karakter",
                          },
                          maxLength: {
                            value: 100,
                            message: "Maksimal password 100 karakter",
                          },
                        })}
                      />
                      {errors.password && (
                        <span className="small text-danger text-center">
                          {errors.password.message}
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="inputForName">Nomor HP</label>
                      <span className="mandatory">*</span>
                      <input
                        id="inputForPhone"
                        name="phone"
                        type="text"
                        className="form-control"
                        aria-describedby="Masukkan nomor HP"
                        placeholder="Masukkan nomor HP"
                        ref={register({
                          required: {
                            value: true,
                            message: "Nomor HP masih kosong",
                          },
                          minLength: {
                            value: 11,
                            message: "Minimum nomor HP 11 digit",
                          },
                        })}
                      />
                      {errors.phone && (
                        <span className="small text-danger text-center">
                          {errors.phone.message}
                        </span>
                      )}
                    </div>

                    <div className="w-100 px-auto d-flex">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LfHMCEaAAAAAKjWZtDNjzWaaClaj1G2KHyAD1iA"
                        onChange={onChange}
                        className="py-3 m-auto"
                      />
                    </div>
                    <button type="submit" className="btn btn-primary btn-block">
                      Daftar
                    </button>
                    <div className="row col-md-12 justify-content-center mt-2">
                      <span className="text-center">
                        Sudah punya akun ? <a href="/login">Masuk Sekarang</a>
                      </span>
                    </div>
                    <div className="text-center mt-3">
                      <label
                        htmlFor="copiright"
                        className="small text-mute m-auto">
                        © PT. Pelangi Indodata 2020
                      </label>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="row mt-2 ">
              <div className="col-md-12 m-auto">
                <div className="card shadow">
                  <div className="card-body text-center">
                    <img
                      src="https://cdn.dribbble.com/users/1818105/screenshots/13035321/media/a7896e1a56e1726b5b4276d3dce74063.png"
                      alt=""
                      width="50%"
                      className="img-fluid"
                    />
                    <h4>Thank You for Registering at Cuan Payment</h4>
                    <h5>Please check your email for activation!</h5>
                    <label htmlFor="">
                      If you cannot find the email, kindly check your spam
                      folder.
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default LoginPage;
