import React,{Component} from 'react';
import ReactTable from "react-table"; 
import 'react-table/react-table.css'
import ReactLoading from 'react-loading';
import LaddaButton, { l, EXPAND_RIGHT } from 'react-ladda';

const Loading = ({ type, color }) => (
    <ReactLoading type={type} color={color} height={'10%'} width={'10%'} />
);



class ListProduk extends Component{
  constructor(props){
    super(props)
    this.state = {
      users: [],
      loading:false,
      login:false,
      store:null,
      options: [
        {
          name: 'PDAM',
          value: '62',
        },
        {
          name: 'PLN',
          value: '42',
        },
        {
          name: 'BPJS',
          value: '78',
        },        
        {
          name: 'TELKOM',
          value: '43',
        }
      ],
      options_pulsa: [
          {
            name: 'SMARTFREN',
            value: '61',
          },
          {
            name: 'AXIS',
            value: '55',
          },
          {
            name: 'INDOSAT',
            value: '58',
          },
          {
            name: 'TELKOMSEL',
            value: '59',
          },
          {
            name: 'THREE',
            value: '60',
          },
          {
            name: 'XL',
            value: '50',
          }
        
      ],
      value: '62',
      size:0,
    }
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
    console.log(this.state.value)
  };

  componentDidMount()
  {
      this.storeCollector()
  }

  storeCollector()
  {
    // let store=JSON.parse(localStorage.getItem('login'));
    let store="CUAN-REST $2a$10$a1hNujOfYRHT6bGacbBkEOXBk476HswO8cNgLSJ6e2EtLEfYavqZG";
    this.setState({login:true,store:store})
      // if(store && store.login)
      // {
      //   this.setState({login:true,store:store})
      // }else{

      // }
  }


  getproduct()
  {
      this.setState({loading:true})
      let store=process.env.REACT_APP_TOKEN;
      // console.log(store.token)
      let token ="Bearer "+store
      // console.log(token)
      fetch(process.env.REACT_APP_URL+this.state.value,{
          method: "GET",
          headers:{
              'Authorization':token
          }
      }).then((response)=>{
          response.json().then((result)=>{
              console.warn("result",result.data.length);
              this.setState({loading:false, users: result.data,size:result.data.length})
          })
      })
  }
  


 render(){

    const { options, value ,options_pulsa } = this.state;
    const columns = [
        {   
            Header: '#',
            maxWidth: 100,
            Cell: (row) => {
                return <div>{row.index+1}</div>;
            },
            style: {
                textAlign: 'center',
                textTransform: 'uppercase'
            },
        },{  
            Header: 'Name',  
            accessor: 'name' ,
            style: {
                textAlign: 'left',
                textTransform: 'uppercase'
            },
        },{   
            Header: 'Harga / Admin',  
            accessor: 'nominal' ,
            maxWidth: 300,
            style: {
                textAlign: 'left'
            },
            Cell: props => new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR',minimumFractionDigits: 0  }).format(props.value)
        }
    ]
    
   return (
    <>
    <section className="section p-0px-t section-top-up-100 bg-white">
    <div className="container">
    {
        this.state.login?
        <></>
        :
        <>
        <div
          className="card border border-primary bg-primary-300 mt-3"
          style={{ backgroundColor: "#dbeefd" }}
        >
          <div className="card-body">
            <p className="small">
              <b className="text-primary">Keterangan</b>
              <br></br>
              Nampaknya anda belum login , login klik <a href="/login">di sini</a> atau <a href="/register" >daftar</a> sekarang juga gratis <br></br>
            </p>
          </div>
        </div>
        </>
    }

    <div className="card">
      <div className="card-header" style={{backgroundColor: '#f6f9ff'}}>
      <h5 className="mt-3">Informasi Harga Produk</h5>
      </div>
      <div className="card-body">
      <div className="row mt-3">
          <div className="col-md-9">
              <div className="form-group">
                  <select className="form-control" onChange={this.handleChange} value={value} >
                  <optgroup label="PPOB">
                  {options.map(item => (
                      <option key={item.value} value={item.value}>
                      {item.name}
                      </option>
                  ))}
                  </optgroup>

                  <optgroup label="PULSA">
                  {options_pulsa.map(item => (
                      <option key={item.value} value={item.value}>
                      {item.name}
                      </option>
                  ))}
                  </optgroup>

                  </select>
              </div>
          </div>

          <div className="col-md-3">
              {
                  this.state.login?
                  // <button className="btn btn-primary w-100" onClick={()=>{this.getproduct()}}>Cari</button>
                  <div>
                    <LaddaButton
                    loading={this.state.loading}
                    onClick={()=>{this.getproduct()}}
                    data-color="#008aff"
                    data-size={l}
                    className="m-btn m-btn-theme m-btn-radius w-100"
                    data-style={EXPAND_RIGHT}
                  >
                    Tampilkan Harga
                    </LaddaButton>
                  </div>
                  :
                  <button className="btn btn-primary w-100" >Cari</button>
              }
          
          </div>
      </div>

    <div className="row d-flex justify-content-center">
      {
          this.state.loading?
          <Loading type="bubbles" color="#008aff" className="text-center" />
          :
          <ReactTable  
          data={this.state.users}  
          columns={columns}  
          className="mt-3 table-striped w-100"
          />
      }
    </div>
    </div>
    </div>
    </div>
    </section>
    </>
   )
 } 
}

export default ListProduk;