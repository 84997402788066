import React, { useContext, useEffect, useState, useRef } from "react";
import { AuthContext } from "../../router/Router";
import { Route, Switch, useLocation, withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { createBrowserHistory } from "history";
import { FaBolt } from "react-icons/fa";
import { FaWater } from "react-icons/fa";
import { FaWifi } from "react-icons/fa";
import { FaHospitalAlt } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { FaGlobe } from "react-icons/fa";
//conponent produk
import Pln from "../products/pln";
import Pulsa from "../products/pulsa";
import Bpjs from "../products/bpjs";
import Telkom from "../products/telkom";
import Pdam from "../products/pdam";
import Data from "../products/data";
import Info from "./info";
import Modal from "react-bootstrap/Modal";
import Moment from "react-moment";

function MenuProduk() {
  const { dispatch } = useContext(AuthContext);
  const { state } = useContext(AuthContext);
  const history = createBrowserHistory();
  const [IsOpen, setIsOpen] = useState(true);
  const [show, setShow] = useState(false);
  const [promo, setPromo] = useState([]);
  const [usePromo, setUsePromo] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });
  // const myDivToFocus = () = React.createRef();
  const getPromo = async () => {
    await setUsePromo(JSON.parse(localStorage.getItem("mydiscount")));
    let url = null;
    if (state.user) {
      url = `${process.env.REACT_APP_URL}/promos/diskon/list`;
    } else {
      url = `${process.env.REACT_APP_URL}/promos/promo/list`;
    }
    // let url = `${process.env.REACT_APP_URL}/promos/promo/list`;
    await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Barier " + state.token,
      },
    })
      .then((response) => {
        response.json().then((result) => {
          console.log("result", result);
          setPromo(result.data);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setActiveDiscount = (val) => {
    localStorage.removeItem("mydiscount");
    localStorage.setItem("mydiscount", JSON.stringify(val));
    setUsePromo(val);
    handleClose();
  };

  const resetUseDisacount = () => {
    localStorage.removeItem("mydiscount");
    setUsePromo(null);
    console.log("promo terhapus");
  };

  useEffect(() => {
    getPromo();
  }, []);

  return (
    <div>
      <section className="section g-bg-gray p-0px-t section-top-up-100 bg-section">
        <div className="container">
          <div className="row">
            <div ref={myRef}></div>

            <div className="col-sm-12 col-lg-12 col-12 m-15px-tb">
              <div className="card border-radius-20">
                <div
                  className="card-header border-radius-20 "
                  style={{ backgroundColor: "#f6f9ff" }}>
                  <h6>Hai sobat,</h6>
                  <h4>Sudah bayar tagihan bulan ini belum?</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-2 col-lg-2 col-sm-2 col-3 mt-2 mb-1">
                      <NavLink
                        className="card p10 pt-3 text-center white-bg box-shadow border-radius-5 theme-hover-bg text-decoration-none border-0 menuproduk"
                        to="/pln"
                        onClick={executeScroll}
                        activeClassName="bg-primary white-color theme-hover-bg">
                        <div className="only-icon-30 d-inline-block w-100">
                          <FaBolt />
                        </div>
                        <b className="m-0px small mt-2">PLN</b>
                      </NavLink>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-3 mt-2 mb-1">
                      <NavLink
                        className="card p10 pt-3 text-center white-bg box-shadow border-radius-5 border-0 theme-hover-bg text-decoration-none menuproduk"
                        to="/bpjs"
                        onClick={executeScroll}
                        activeClassName="bg-primary white-color theme-hover-bg">
                        <div className="only-icon-30 d-inline-block w-100">
                          <FaHospitalAlt />
                        </div>
                        <b className="m-0px small mt-2">BPJS</b>
                      </NavLink>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-3 mt-2 mb-1">
                      <NavLink
                        className="card p10 pt-3 text-center white-bg box-shadow border-radius-5 theme-hover-bg text-decoration-none border-0 menuproduk"
                        to="/telkom"
                        onClick={executeScroll}
                        activeClassName="bg-primary white-color theme-hover-bg">
                        <div className="only-icon-30 d-inline-block w-100">
                          <FaWifi />
                        </div>
                        <b className="m-0px small mt-2">TELKOM</b>
                      </NavLink>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-3 mt-2 mb-1">
                      <NavLink
                        className="card p10 pt-3 text-center white-bg box-shadow border-radius-5 theme-hover-bg text-decoration-none border-0 menuproduk"
                        to="/pdam"
                        onClick={executeScroll}
                        activeClassName="bg-primary white-color theme-hover-bg">
                        <div className="only-icon-30 d-inline-block w-100 ">
                          <FaWater />
                        </div>
                        <b className="m-0px small mt-2">PDAM</b>
                      </NavLink>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-3 mt-2 mb-1">
                      <NavLink
                        className="card p10 pt-3 text-center white-bg box-shadow border-radius-5 theme-hover-bg text-decoration-none border-0 menuproduk"
                        to="/pulsa"
                        onClick={executeScroll}
                        activeClassName="bg-primary white-color theme-hover-bg">
                        <div className="only-icon-30 d-inline-block w-100">
                          <FaMobileAlt />
                        </div>
                        <b className="m-0px small mt-2">PULSA</b>
                      </NavLink>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-3 mt-2 mb-1">
                      <NavLink
                        className="card p10 pt-3 text-center white-bg box-shadow border-radius-5 theme-hover-bg text-decoration-none border-0 menuproduk"
                        to="/data"
                        onClick={executeScroll}
                        activeClassName="bg-primary white-color theme-hover-bg">
                        <div className="only-icon-30 d-inline-block w-100">
                          <FaGlobe />
                        </div>
                        <b className="m-0px small mt-2">DATA</b>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              {promo && promo.length > 0 ? (
                <div
                  className="card mt-2 border-primary"
                  style={{ backgroundColor: "#dbeefd" }}>
                  <div className="">
                    <div className="row my-2 mx-2">
                      <div className="col-md-9 col-9 m-auto font-wight-bold text-primary">
                        {promo ? (
                          usePromo ? (
                            <div>
                              <div>
                                Kode Promo : <b>{usePromo.kode}</b>.&nbsp;
                                Diskon : <b>{usePromo.diskon}%</b>
                              </div>
                            </div>
                          ) : (
                            <div>
                              Kamu punya {promo.length} Kode Promo Dan Diskon
                            </div>
                          )
                        ) : (
                          <div>
                            Promo Menarik dengan meningkatkan transaksi anda{" "}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 col-3 text-right">
                        {promo ? (
                          usePromo ? (
                            <a
                              href="#"
                              className="btn btn-primary btn-sm w-100"
                              onClick={() => resetUseDisacount()}>
                              Batal
                            </a>
                          ) : (
                            <a
                              href="#"
                              onClick={handleShow}
                              className="btn btn-primary btn-sm w-100">
                              Lihat
                            </a>
                          )
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                scrollable={true}
                xaria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header>
                  <Modal.Title>Promo Dan Diskon</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    {promo ? (
                      promo.map(function (value, i) {
                        return (
                          <div className="col-md-12 mt-2" key={i}>
                            <div
                              className="card border-primary"
                              style={{ backgroundColor: "#dbeefd" }}>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-8 col-8 m-auto text-primary">
                                    <h5 className="text-primary">
                                      {value.kode}
                                    </h5>
                                    <p className="small">
                                      <b className="ml-4">{"Keterangan :"}</b>
                                      <br />
                                      <ol>
                                        {value.product_name ? (
                                          <>
                                            <li>
                                              {"Produk : " +
                                                value.product_name.toUpperCase()}
                                            </li>
                                            <li>
                                              Diskon Biaya Admin {value.diskon}{" "}
                                              %
                                            </li>
                                          </>
                                        ) : (
                                          <>
                                            <li>Semua Produk</li>
                                            <li>
                                              Diskon Biaya Admin {value.diskon}{" "}
                                              %
                                            </li>
                                          </>
                                        )}
                                        <li>
                                          Gunakan Sebelum :{" "}
                                          <Moment format="DD MMM YYYY">
                                            {value.due_date}
                                          </Moment>
                                        </li>
                                        <li>
                                          Maksimal Kuota : {value.pemakaian}{" "}
                                          transaksi
                                        </li>
                                        <li>
                                          Hanya dapat digunakan di aplikasi web
                                        </li>
                                      </ol>
                                    </p>
                                  </div>
                                  <div className="col-md-4 col-4 text-center">
                                    <h3 className="m-auto text-danger">
                                      {value.diskon} %
                                    </h3>
                                    <a
                                      className="btn btn-primary mx-auto text-white mt-2"
                                      onClick={() => setActiveDiscount(value)}>
                                      Gunakan Promo
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div></div>
                    )}
                  </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>

              {history.location.pathname == "/" ? (
                <div>
                  <Info />
                </div>
              ) : (
                <div className="card mt-2">
                  <div className="card-body produks">
                    <Route path="/pln" component={Pln} />
                    <Route path="/pulsa" component={Pulsa} />
                    <Route path="/bpjs" component={Bpjs} />
                    <Route path="/telkom" component={Telkom} />
                    <Route path="/pdam" component={Pdam} />
                    <Route path="/data" component={Data} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <div className="d-none d-lg-block  p-0px-t section-top-up-100">
                <img src="./img/bg.png" alt="" className="w-100 img-fluid"/>
            </div> */}
    </div>
  );
}

export default MenuProduk;
