import React, { Component } from "react";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";
import "react-table/react-table.css";
import Select from "react-select";
import LaddaButton, { l, EXPAND_RIGHT } from "react-ladda";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Loader type="ThreeDots" color="#007bff" height="100" width="100" />
      </div>
    )
  );
};
const namaBulan = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
class telkom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inquiry: false,
      struk: true,
      loading: true,
      produk: "",
      idpel: null,
      message: null,
      nominal: null,
      trx_type: "2100",
      product_id: null,
      list_prod: [],
      btnInq: true,
      inqRes: [],
      payRes: [],
      bulanTahun: null,
    };
  }

  componentDidUpdate() {
    // this.scrollToBottom();
  }
  componentDidMount() {
    // this.scrollToBottom();
    this.getproduct();
    this.setState({
      struk: false,
      loading: false,
    });

    console.log(window.location.pathname);
  }
  scrollToBottom() {
    this.el.scrollIntoView({ behavior: "smooth" });
  }
  ucwords(str) {
    const arrOfWords = str.split(" ");
    const arrOfWordsCased = [];
    for (let i = 0; i < arrOfWords.length; i++) {
      const word = arrOfWords[i];
      arrOfWordsCased.push(
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      );
    }
    return arrOfWordsCased.join(" ");
  }
  getproduct() {
    fetch(`${process.env.REACT_APP_URL}/products/43`, {
      method: "GET",
      headers: {
        // 'Authorization':token
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((result) => {
        this.setState({ list_prod: result.data });
        if (result.data[0].code !== null) {
          this.setState({
            produk: result.data[0].name,
            product_id: result.data[0].prod_id,
          });
        }
      });
    });
  }
  setTypeProduct(event) {
    this.setState({
      produk: event.target.value,
      product_id: event.target.id,
    });
  }
  handleChangeIdpel(e) {
    let inIdpel = e.target.value;
    if (inIdpel.length >= 9 && inIdpel.length <= 13) {
      this.setState({ message: "" });
      this.setState({ idpel: inIdpel });
      // this.cekinquiry();
      // console.log(this.state.idpel)
    } else {
      this.setState({ message: "idpel tidak sesuai" });
    }
  }

  currencyFormat(num) {
    return (
      "Rp " +
      parseFloat(num)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  }
  getDate(num) {
    return parseInt(num.toString().substr(4, 2));
  }
  getTahun(num) {
    return parseInt(num.toString().substr(0, 4));
  }

  setNominal(event) {
    alert("keklik");
    console.log(event);
    // this.setState({nominal : e });
  }

  cekinquiry() {
    this.setState({ loading: true });
    let token = process.env.REACT_APP_TOKEN;
    console.log(JSON.stringify(this.state));
    fetch(`${process.env.REACT_APP_URL}/transactions/inquiry`, {
      method: "POST",
      // headers: { 'Content-Type': 'application/json' },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Barier " + token,
        Accept: "application/json",
      },
      body: JSON.stringify(this.state),
    }).then((response) => {
      response.json().then((result) => {
        console.warn("result", result);
        if (result.status == "200") {
          if (this.state.trx_type == "2100" && result.data.rc == "0000") {
            localStorage.setItem(
              "private_inquiry",
              JSON.stringify({
                data: result.data,
                produk: this.state.product_id,
                product_nomination: this.state.product_nomination,
              })
            );
            this.setState({ inquiry: true, inqres: result.data });
            console.log(this.state.inqres);
            // window.location.reload(false);
          } else {
            this.setState({ message: result.message.desc });
            Swal.fire({
              title: "Maaf",
              text: result.message.desc,
              icon: "error",
              confirmButtonText: "oke",
            });
          }
        } else {
          this.setState({ message: result.message.desc });
          Swal.fire({
            title: "Maaf",
            text: result.message.desc,
            icon: "error",
            confirmButtonText: "oke",
          });
        }
      });
      this.setState({ loading: false });
    }).catch((error) => {
      this.setState({
        loading: false,
        message: error.response.data.message.desc == "" ? error.response.data.message.status : error.response.data.message.desc,
      });
      if (error.response) {
        Swal.fire({
          title: "Maaf",
          text: error.response.data.message.desc == "" ? error.response.data.message.status : error.response.data.message.desc,
          icon: "error",
          confirmButtonText: "oke",
        });
      } else {
        if (error.code === "ECONNABORTED")
          Swal.fire({
            title: "Maaf",
            text:
              "Maaf Produk sedang gangguan atau tidak bisa bisa di transaksikan sekarang ",
            icon: "error",
            confirmButtonText: "oke",
          });
      }
    });
  }
  handleChange = (value) => {
    this.setState({ value: value });
    // console.log(this.state.value)
    let produk_id = value.value;
    // console.log(produk_id);
    this.setState({ product_id: produk_id });
  };

  render() {
    const btnInq = this.state.btnInq;
    const struk = this.state.struk;
    const { list_prod } = this.state;
    const produxs = this.state.list_prod.map((d) => ({
      value: d.prod_id,
      label: d.name,
    }));

    return (
      <div>
        {this.state.inquiry ? (
          <>
            <Redirect to="/checkoutv1" />
          </>
        ) : (
          <></>
        )}
        {struk ? (
          <div className="container">
            <div className="text-center mt-5 mb-5">
              <img src="https://upload.wikimedia.org/wikipedia/commons/3/3f/Commons-emblem-success.svg"></img>
              <h5>Transaction Success</h5>
            </div>
            <div className="card mt-2">
              <div className="card-body">
                <table className="table small">
                  <tbody>
                    <tr>
                      <td>IDPEL</td>
                      <td className="text-right">
                        {this.state.payRes.id_pelanggan}
                      </td>
                    </tr>
                    <tr>
                      <td>Nama</td>
                      <td className="text-right">
                        {this.state.payRes.nama_pelanggan}
                      </td>
                    </tr>

                    <tr>
                      <td>Bulan/Tahun</td>
                      <td className="text-right">{this.state.bulanTahun}</td>
                    </tr>
                    <tr>
                      <td>NO REF</td>
                      <td className="text-right">
                        {this.state.payRes.no_reference}
                      </td>
                    </tr>
                    <tr>
                      <td>JUMLAH TAGIHAN</td>
                      <td className="text-right">
                        Rp. {this.state.payRes.jumlah_tagihan}
                      </td>
                    </tr>

                    <tr>
                      <td>ADMIN BANK</td>
                      <td className="text-right">
                        Rp. {this.state.payRes.jumlah_adm}
                      </td>
                    </tr>

                    <tr>
                      <td>TOTAL BAYAR</td>
                      <td className="text-right">
                        Rp. {this.state.payRes.jumlah_bayar}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan="2" className="text-center text-weight-bold">
                        <b>
                          TELKOM menyatakan struk ini sebagai bukti pembayaran
                          yang sah
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <a href="/telkom" className="btn btn-primary w-100">
              Ingin Transaksi Lagi?
            </a>
          </div>
        ) : (
          <div>
            <h5 className="text-dark">
              Bayar Tagihan Telepon Rumah/Internet (Indihome/Speedy)
            </h5>
            <div className="row mt-4">
              <div
                className="col-md-12"
                onChange={this.setTypeProduct.bind(this)}>
                {this.state.list_prod.map(function (value, i) {
                  return (
                    <label className="font-weight-bold d-inline mr-3" key={i}>
                      <input
                        type="radio"
                        value={value.name}
                        id={value.prod_id}
                        name="telkomType"
                        className="mr-2"
                        defaultChecked={i === 0 ? "check" : ""}
                      />
                      {value.name}
                    </label>
                  );
                })}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="form-group">
                  <h6 className="text-black">ID Pelanggan</h6>
                  <form id="input-form">
                    <input
                      type="number"
                      className="form-control"
                      defaultValue=""
                      placeholder={
                        "ID Pelanggan " +
                        this.ucwords(this.state.produk.toLowerCase())
                      }
                      onChange={(e) => {
                        this.handleChangeIdpel(e);
                      }}
                    />
                  </form>
                </div>
              </div>
            </div>
            {btnInq ? (
              <div>
                <div className="col-md-12 mt-3">
                  <div className="row">
                    <div className="col-md-9 text-right">
                      <label></label>
                      <h6></h6>
                    </div>
                    <div className="col-md-3">
                      <LaddaButton
                        loading={this.state.loading}
                        disabled={!this.state.idpel}
                        onClick={() => {
                          this.cekinquiry();
                        }}
                        data-color="#008aff"
                        data-size={l}
                        className="m-btn m-btn-theme m-btn-radius w-100"
                        data-style={EXPAND_RIGHT}>
                        Cek Tagihan
                      </LaddaButton>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="col-md-12">
                  <hr></hr>
                  <div className="row">
                    <div className="col-md-10 col-12 small">
                      <div className="row small">
                        <div className="col-md-3 border-right">
                          <label>Nama Lengkap</label>
                          <br></br>
                          <b className="text-danger">
                            {this.state.inqRes.nama_pelanggan}
                          </b>
                        </div>

                        <div className="col-md-2 border-right">
                          <label>Bulan</label>
                          <br></br>
                          <b className="text-danger">{this.state.bulanTahun}</b>
                        </div>

                        <div className="col-md-2 border-right">
                          <label>Tagihan</label>
                          <br></br>
                          <b className="text-danger">
                            Rp. {this.state.inqRes.jumlah_tagihan}
                          </b>
                        </div>

                        <div className="col-md-2 border-right">
                          <label>Biaya Admin</label>
                          <br></br>
                          <b className="text-danger">
                            Rp. {this.state.inqRes.jumlah_adm}
                          </b>
                        </div>

                        <div className="col-md-2">
                          <label>Total Tagihan</label>
                          <br></br>
                          <b className="text-danger">
                            Rp. {this.state.inqRes.jumlah_bayar}
                          </b>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <LaddaButton
                        // type="submit"
                        className="btn btn-lg btn-danger"
                        style={{ height: "100%" }}
                        onClick={() => this.payment()}>
                        Bayar Sekarang<br></br>Rp.{" "}
                        {this.state.inqRes.jumlah_bayar}
                      </LaddaButton>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              className="card border border-primary bg-primary-300 mt-3"
              style={{ backgroundColor: "#dbeefd" }}>
              <div className="card-body">
                <p className="small">
                  <b className="text-primary">Keterangan</b>
                  <br></br>
                  1. Produk Telkom Indihome/ Telepon tidak tersedia pada jam cut off/maintenance (23.00 - 01.00).<br></br>
                  2. Transaksi pembayaran tagihan Telkom Indihome/ Telepon membutuhkan waktu proses maksimal 1x24 jam.<br></br>
                  3. Untuk metode pembayaran menggunakan virtual account / transfer bank akan dikenakan biaya transaksi sebesar <b>Rp. 4.500</b>.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default telkom;
