import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import ReactLoading from "react-loading";
import LaddaButton, { l, EXPAND_RIGHT } from "react-ladda";
import Swal from "sweetalert2";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";

const Loading = ({ type, color }) => (
  <ReactLoading type={type} color={color} height={"10%"} width={"10%"} />
);

class Produk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      loading: false,
      login: false,
      token: localStorage.getItem("token"),
      value: "62",
      size: 0,
      isLoading: false,
      produkId: [],
      produkCat: [],
      toogleBtn: true,
      product_id: null,
      name: null,
      nama_display: null,
      product_type: null,
      admin_fee: null,
      handling_fee: null,
      gangguan: null,
      kosong: null,
      active: null,
      status: null,
      code: null,
      vcode: null,
      readMode: true,
      filterId: null,
      filterVal: null,
      product_category_id: null,
      valPCI: null,
    };
  }

  showModal = (type, data) => {
    if (type == "Edit") {
      this.setState({
        id: data.id,
        prod_id: data.prod_id,
        name: data.name,
        nama_display: data.nama_display,
        product_type: data.product_type,
        admin_fee: data.admin_fee,
        handling_fee: data.handling_fee,
        gangguan: data.gangguan,
        kosong: data.kosong,
        active: data.active,
        code: data.code,
        vcode: data.vcode,
        description: data.description,
        product_category_id: data.product_category_id,
        nominal: data.nominal,
        nominal_display: data.nominal_display,
        readMode: false,
      });
      if (data.active == true) {
        this.setState({ status: { value: "Active", label: "Active" } });
      } else if (data.gangguan == true) {
        this.setState({ status: { value: "Gangguan", label: "Gangguan" } });
      } else if (data.kosong == true) {
        this.setState({ status: { value: "Kosong", label: "Kosong" } });
      } else {
        this.setState({ status: null });
      }
      // console.warn("data",data)
    } else if (type == "Tambah") {
      this.setState({
        id: "",
        prod_id: "",
        name: "",
        nama_display: "",
        product_type: "",
        admin_fee: "",
        handling_fee: "",
        gangguan: 0,
        kosong: 0,
        active: 0,
        code: "",
        vcode: "",
        description: "",
        product_category_id: "",
        status: null,
        nominal: "",
        nominal_display: "",
        readMode: false,
      });
    } else {
      this.setState({
        id: data.id,
        prod_id: data.prod_id,
        name: data.name,
        nama_display: data.nama_display,
        product_type: data.product_type,
        admin_fee: data.admin_fee,
        handling_fee: data.handling_fee,
        gangguan: data.gangguan,
        kosong: data.kosong,
        active: data.active,
        code: data.code,
        vcode: data.vcode,
        description: data.description,
        product_category_id: data.product_category_id,
        nominal: data.nominal,
        nominal_display: data.nominal_display,
        readMode: true,
      });
      if (data.active == true) {
        this.setState({ status: "Active" });
      } else if (data.gangguan == true) {
        this.setState({ status: "Gangguan" });
      } else if (data.kosong == true) {
        this.setState({ status: "Kosong" });
      } else {
        this.setState({ status: null });
      }
    }
    this.setState({ setIsOpen: true, typeModal: type });
    // console.warn("status : ",this.state.status);
  };

  hideModal = () => {
    this.setState({ setIsOpen: false });
  };

  handleChange = (e) => {
    // console.warn([e.target.name], e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeFilter = (value) => {
    this.setState({ filterVal: value });
    console.log(this.state.filterVal);
    let fil_id = value.value;
    console.log(fil_id);
    this.setState({ filterId: fil_id });
  };

  handleChangeStatus = (value) => {
    this.setState({ status: value });
    if (value.value == "Active") {
      this.setState({ active: 1, kosong: 0, gangguan: 0 });
    } else if (value.value == "Kosong") {
      this.setState({ active: 0, kosong: 1, gangguan: 0 });
    } else if (value.value == "Gangguan") {
      this.setState({ active: 0, kosong: 0, gangguan: 1 });
    } else {
    }
    // console.warn('status warn : ',this.state.status);
    // console.warn('active warn : ',this.state.active);
    // console.warn('gangguan warn : ',this.state.gangguan);
    // console.warn('kosong warn : ',this.state.kosong);
  };

  handleChangeProCat = (value) => {
    this.setState({ product_category_id: value.value, valPCI: value });
    console.warn("product_category_id warn : ", this.state.product_category_id);
  };

  update() {
    // Swal.fire({
    //     title: 'Yakin akan menyimpannya?',
    //     showCancelButton: true,
    //     confirmButtonText: 'SIMPAN',
    // }).then((result) => {
    //     if(result.isConfirmed){
    if (this.state.description.length >= 5) {
      fetch(
        process.env.REACT_APP_URL + "/products/update/" + this.state.prod_id,
        {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Barier " + this.state.token,
            Accept: "application/json",
          },
          body: JSON.stringify({
            prod_id: this.state.prod_id,
            code: this.state.code,
            vcode: this.state.vcode,
            name: this.state.name,
            name_display: this.state.nama_display,
            description: this.state.description,
            product_category_id: this.state.product_category_id,
            nominal: this.state.nominal,
            nominal_display: this.state.nominal_display,
            gangguan: this.state.gangguan,
            kosong: this.state.kosong,
            active: this.state.active,
            product_type: this.state.product_type,
            admin_fee: this.state.admin_fee,
            handling_fee: this.state.handling_fee,
          }),
        }
      ).then((response) => {
        response.json().then((result) => {
          if (result.status == "200") {
            this.getproduct();
            this.getproductId();
            this.setState({ isEdit: false, setIsOpen: false });
            Swal.fire({
              title: "Sukses Disimpan",
              text: result.data.desc,
              icon: "success",
              confirmButtonText: "oke",
            });
          } else {
            this.setState({ message: result.message });
            Swal.fire({
              title: "Upss!",
              text: result.message,
              icon: "error",
              confirmButtonText: "oke",
            });
          }
        });
      });
    } else {
      this.setState({ message: "Description harus diisi minimal 5 Digit" });
      Swal.fire({
        title: "Upss!",
        text: "Description harus diisi minimal 5 Digit",
        icon: "error",
        confirmButtonText: "oke",
      });
    }
    //     }
    // })
  }

  create() {
    // Swal.fire({
    //     title: 'Yakin akan menyimpannya?',
    //     showCancelButton: true,
    //     confirmButtonText: 'SIMPAN',
    // }).then((result) => {
    //     if(result.isConfirmed){
    if (this.state.description.length >= 5) {
      fetch(process.env.REACT_APP_URL + "/products/create", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Barier " + this.state.token,
          Accept: "application/json",
        },
        body: JSON.stringify({
          prod_id: this.state.prod_id,
          code: this.state.code,
          vcode: this.state.vcode,
          name: this.state.name,
          name_display: this.state.nama_display,
          description: this.state.description,
          product_category_id: this.state.product_category_id,
          nominal: this.state.nominal,
          nominal_display: this.state.nominal_display,
          gangguan: this.state.gangguan,
          kosong: this.state.kosong,
          active: this.state.active,
          product_type: this.state.product_type,
          admin_fee: this.state.admin_fee,
          handling_fee: this.state.handling_fee,
        }),
      }).then((response) => {
        response.json().then((result) => {
          if (result.status == "200") {
            this.getproduct();
            this.getproductId();
            this.setState({ isEdit: false, setIsOpen: false });
            Swal.fire({
              title: "Sukses Disimpan",
              text: result.data.desc,
              icon: "success",
              confirmButtonText: "oke",
            });
          } else {
            this.setState({ message: result.message });
            Swal.fire({
              title: "Upss!",
              text: result.message,
              icon: "error",
              confirmButtonText: "oke",
            });
          }
        });
      });
    } else {
      this.setState({ message: "Description harus diisi minimal 5 Digit" });
      Swal.fire({
        title: "Upss!",
        text: "Description harus diisi minimal 5 Digit",
        icon: "error",
        confirmButtonText: "oke",
      });
    }
    //     }
    // })
  }

  confirmDelete(value) {
    Swal.fire({
      title: "Yakin akan Menghapus?",
      text: "Product Id : " + value.prod_id + " | NAME : " + value.name,
      showCancelButton: true,
      confirmButtonText: "DELETE",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(process.env.REACT_APP_URL + "/products/delete/" + value.prod_id, {
          method: "delete",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Barier " + this.state.token,
            Accept: "application/json",
          },
        }).then((response) => {
          response.json().then((result) => {
            if (result.status == "200") {
              this.getproduct();
              this.getproductId();
              this.setState({ isEdit: false, setIsOpen: false });
              Swal.fire({
                title: "Sukses Dihapus",
                text: result.data.desc,
                icon: "success",
                confirmButtonText: "oke",
              });
            } else {
              this.setState({ message: result.message });
              Swal.fire({
                title: "Upss!",
                text: result.message,
                icon: "error",
                confirmButtonText: "oke",
              });
            }
          });
        });
      }
    });
  }

  componentDidMount() {
    this.getproduct();
    this.getproductId();
    this.getproductCat();
  }

  getproduct() {
    this.setState({ loading: true });
    fetch(process.env.REACT_APP_URL + "/products", {
      method: "get",
      headers: {
        Authorization: "Barier " + this.state.token,
      },
    }).then((response) => {
      response.json().then((result) => {
        //   console.warn("result",result.data.length);
        this.setState({
          loading: false,
          users: result.data,
          size: result.data.length,
        });
        //   console.warn(this.state.users);
      });
    });
  }

  getproductId() {
    // this.setState({loading:true})
    fetch(process.env.REACT_APP_URL + "/products", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Barier " + this.state.token,
        Accept: "application/json",
      },
    }).then((response) => {
      response.json().then((result) => {
        //   console.warn("result",result.data.length);
        this.setState({
          // loading:false,
          produkId: result.data,
        });
        //   console.warn(this.state.users);
      });
    });
  }

  getproductCat() {
    this.setState({ loading: true });
    fetch(process.env.REACT_APP_URL + "/product/categories", {
      method: "get",
      headers: {
        Authorization: "Bearer " + this.state.token,
      },
    }).then((response) => {
      response.json().then((result) => {
        //   console.warn("result",result.data.length);
        this.setState({ loading: false, produkCat: result.data });
        //   console.warn(this.state.users);
      });
    });
  }

  getfilter() {
    this.setState({ loading: true });
    fetch(
      process.env.REACT_APP_URL +
        "/products?category_id=" +
        this.state.filterId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Barier " + this.state.token,
          Accept: "application/json",
        },
      }
    ).then((response) => {
      response.json().then((result) => {
        if (result.status == "200") {
          this.setState({
            loading: false,
            users: result.data,
            size: result.data.length,
          });
        } else {
          this.setState({ message: result.message, loading: false });
          Swal.fire({
            title: "Upss!",
            text: result.message,
            icon: "error",
            confirmButtonText: "oke",
          });
        }
      });
    });
  }

  reset() {
    this.getproduct();
    this.getproductId();
    this.setState({ filterVal: null, filterId: null });
  }

  render() {
    const { options, value, options_pulsa } = this.state;
    const columns = [
      {
        Header: "#",
        maxWidth: 50,
        Cell: (row) => {
          return <div>{row.index + 1}</div>;
        },
        style: {
          textAlign: "center",
          textTransform: "uppercase",
          flexDirection: "column",
          justifyContent: "center",
        },
      },
      {
        Header: "ID",
        accessor: "prod_id",
        maxWidth: 80,
        style: {
          textAlign: "left",
          textTransform: "uppercase",
          flexDirection: "column",
          justifyContent: "center",
        },
      },
      {
        Header: "NAMA",
        accessor: "name",
        style: {
          textAlign: "left",
          textTransform: "uppercase",
          flexDirection: "column",
          justifyContent: "center",
        },
      },
      {
        Header: "NAMA DISPLAY",
        accessor: "nama_display",
        style: {
          textAlign: "left",
          textTransform: "uppercase",
          flexDirection: "column",
          justifyContent: "center",
        },
      },
      {
        Header: "TIPE",
        accessor: "product_type",
        maxWidth: 130,
        style: {
          textAlign: "left",
          textTransform: "uppercase",
          flexDirection: "column",
          justifyContent: "center",
        },
      },
      {
        Header: "RP ADMIN",
        accessor: "admin_fee",
        maxWidth: 100,
        style: {
          textAlign: "right",
          flexDirection: "column",
          justifyContent: "center",
        },
        Cell: (props) =>
          new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 0,
          }).format(props.value),
      },
      {
        Header: "RP PENANGANAN",
        accessor: "handling_fee",
        maxWidth: 120,
        style: {
          textAlign: "right",
          flexDirection: "column",
          justifyContent: "center",
        },
        Cell: (props) =>
          new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 0,
          }).format(props.value),
      },
      {
        Header: "STATUS",
        accessor: "status",
        maxWidth: 130,
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
        Cell: (row) => (
          <div>
            {row.original.active == true ? (
              <span
                className="badge badge-success"
                style={{
                  fontSize: "14px",
                  letterSpacing: "1px",
                }}>
                AKTIF
              </span>
            ) : (
              <span></span>
            )}
            {row.original.gangguan == true ? (
              <span
                className="badge badge-warning"
                style={{
                  fontSize: "14px",
                  letterSpacing: "1px",
                }}>
                GANGGGUAN
              </span>
            ) : (
              <span></span>
            )}
            {row.original.kosong == true ? (
              <span
                className="badge badge-danger"
                style={{
                  fontSize: "14px",
                  letterSpacing: "1px",
                }}>
                KOSONG
              </span>
            ) : (
              <span></span>
            )}
          </div>
        ),
      },

      {
        Header: "AKSI",
        accessor: "id",
        maxWidth: 180,
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
        filterable: false,
        Cell: (row) => (
          <div>
            <button
              className="btn btn-flat btn-sm btn-info"
              onClick={() => this.showModal("Detail", row.original)}>
              Detail
            </button>
            &nbsp;
            <button
              className="btn btn-flat btn-sm btn-warning"
              onClick={() => this.showModal("Edit", row.original)}>
              Edit
            </button>
            &nbsp;
            <button
              onClick={() => this.confirmDelete(row.original)}
              className="btn btn-flat btn-sm btn-danger">
              Hapus
            </button>
          </div>
        ),
      },
    ];
    const produxs = this.state.produkCat.map((d) => ({
      value: d.product_category_id,
      label: d.product_category_id + " - " + d.name + " - " + d.description,
    }));
    const filter = this.state.produkCat.map((d) => ({
      value: d.product_category_id,
      label: d.product_category_id + " - " + d.name + " - " + d.description,
    }));
    const statusx = [
      { value: "Active", label: "Active" },
      { value: "Gangguan", label: "Gangguan" },
      { value: "Kosong", label: "Kosong" },
    ];
    return (
      <>
        <div>
          <main className="container-fluid pt-4 mt-5">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb" type="none">
                <li class="breadcrumb-item">
                  <a href="#">Admin</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">Master</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Produk
                </li>
              </ol>
            </nav>

            <div className="card mt-2 rounded-2 border-white shadow">
              <div className="card-header text-dark bg-light">
                <b>Data Produk</b>
              </div>
              <div className="card-body">
                <div className="row mt-3">
                  <div className="col-md-6">
                    <Select
                      options={filter}
                      value={this.state.filterVal}
                      onChange={(value) => this.handleChangeFilter(value)}
                      placeholder={<div>Pilih Kategori Produk</div>}
                    />
                  </div>
                  <div className="col-md-2">
                    <LaddaButton
                      loading={this.state.loading}
                      className="btn btn-primary m-btn-radius w-100"
                      data-color="#008aff"
                      data-size={l}
                      onClick={() => this.getfilter()}>
                      FILTER
                    </LaddaButton>
                  </div>
                  <div className="col-md-2">
                    <LaddaButton
                      loading={this.state.loading}
                      className="btn btn-danger m-btn-radius w-100"
                      data-color="#008aff"
                      data-size={l}
                      onClick={() => this.reset()}>
                      RESET
                    </LaddaButton>
                  </div>
                  <div className="col-md-2">
                    <LaddaButton
                      loading={this.state.loading}
                      className="btn btn-success m-btn-radius w-100"
                      data-color="#008aff"
                      data-size={l}
                      onClick={() => this.showModal("Tambah")}>
                      TAMBAH
                    </LaddaButton>
                  </div>
                </div>
                {this.state.isLoading ? (
                  <Loading type="spinningBubbles" color="#91091e" />
                ) : (
                  <ReactTable
                    data={this.state.users}
                    // pageSize={this.state.dataDenom.length > 10 ? 10 : this.state.dataDenom.length}
                    defaultPageSize={5}
                    columns={columns}
                    className="mt-3 table-striped w-100"
                  />
                )}
              </div>
            </div>
          </main>
        </div>

        <Modal show={this.state.setIsOpen} onHide={() => this.hideModal()}>
          <Modal.Header>
            <Modal.Title>{this.state.typeModal} Produk</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">
                ID Produk <sup className="text-danger">*</sup>
              </label>
              <div className="col-sm-8">
                {this.state.typeModal == "Tambah" ? (
                  <input
                    name="prod_id"
                    value={this.state.prod_id}
                    onChange={this.handleChange}
                    className="form-control"
                    readOnly={this.state.readMode}
                  />
                ) : (
                  <input
                    name="prod_id"
                    value={this.state.prod_id}
                    onChange={this.handleChange}
                    className="form-control"
                    readOnly
                  />
                )}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">
                Kategori <sup className="text-danger">*</sup>
              </label>
              <div className="col-sm-8">
                {this.state.readMode == false ? (
                  <Select
                    options={produxs}
                    value={produxs.find(
                      (obj) => obj.value == this.state.product_category_id
                    )}
                    onChange={(value) => this.handleChangeProCat(value)}
                    placeholder={<div>Pilih Kategori Produk</div>}
                  />
                ) : (
                  <input
                    type="text"
                    name="product_category_id"
                    id="product_category_id"
                    className="form-control"
                    required="required"
                    value={this.state.product_category_id}
                    // onChange={this.handleChange}
                    readOnly={this.state.readMode}
                  />
                )}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">
                Tipe <sup className="text-danger">*</sup>
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  name="product_type"
                  id="product_type"
                  className="form-control"
                  required=""
                  value={this.state.product_type}
                  onChange={this.handleChange}
                  readOnly={this.state.readMode}
                />
              </div>
            </div>
            {/* <div className="form-group row">
              <label className="col-sm-4 col-form-label">
                Kode Produk <sup className="text-danger">*</sup>
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  name="code"
                  id="code"
                  className="form-control"
                  required=""
                  value={this.state.code}
                  onChange={this.handleChange}
                  readOnly={this.state.readMode}
                />
              </div>
            </div> */}
            {/* <div className="form-group row">
              <label className="col-sm-4 col-form-label">
                Vcode <sup className="text-danger">*</sup>
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  name="vcode"
                  id="vcode"
                  className="form-control"
                  required=""
                  value={this.state.vcode}
                  onChange={this.handleChange}
                  readOnly={this.state.readMode}
                />
              </div>
            </div> */}
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">
                Nama Produk <sup className="text-danger">*</sup>
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-control"
                  required=""
                  value={this.state.name}
                  onChange={this.handleChange}
                  readOnly={this.state.readMode}
                />
              </div>
            </div>
            {/* <div className="form-group row">
              <label className="col-sm-4 col-form-label">
                Nama Display <sup className="text-danger">*</sup>
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  name="nama_display"
                  id="nama_display"
                  className="form-control"
                  required=""
                  value={this.state.nama_display}
                  onChange={this.handleChange}
                  readOnly={this.state.readMode}
                />
              </div>
            </div> */}
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">
                Deskripsi <sup className="text-danger">*</sup>
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  name="description"
                  id="description"
                  className="form-control"
                  required=""
                  value={this.state.description}
                  onChange={this.handleChange}
                  readOnly={this.state.readMode}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">
                Rp Admin <sup className="text-danger">*</sup>
              </label>
              <div className="col-sm-8">
                <input
                  type="number"
                  name="admin_fee"
                  id="admin_fee"
                  className="form-control"
                  required="required"
                  value={this.state.admin_fee}
                  onChange={this.handleChange}
                  readOnly={this.state.readMode}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">
                Rp Penanganan <sup className="text-danger">*</sup>
              </label>
              <div className="col-sm-8">
                <input
                  type="number"
                  name="handling_fee"
                  id="handling_fee"
                  className="form-control"
                  required="required"
                  value={this.state.handling_fee}
                  onChange={this.handleChange}
                  readOnly={this.state.readMode}
                />
              </div>
            </div>
            {/* <div className="form-group row">
              <label className="col-sm-4 col-form-label">
                Nominal <sup className="text-danger">*</sup>
              </label>
              <div className="col-sm-8">
                <input
                  type="number"
                  name="nominal"
                  id="nominal"
                  className="form-control"
                  required="required"
                  value={this.state.nominal}
                  onChange={this.handleChange}
                  readOnly={this.state.readMode}
                />
              </div>
            </div> */}
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">
                Nominal Display <sup className="text-danger">*</sup>
              </label>
              <div className="col-sm-8">
                <input
                  type="number"
                  name="nominal_display"
                  id="nominal_display"
                  className="form-control"
                  required="required"
                  value={this.state.nominal_display}
                  onChange={this.handleChange}
                  readOnly={this.state.readMode}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">
                Status <sup className="text-danger">*</sup>
              </label>
              <div className="col-sm-8">
                {this.state.readMode == false ? (
                  <Select
                    options={statusx}
                    value={this.state.status}
                    onChange={(value) => this.handleChangeStatus(value)}
                    placeholder={<div>Pilih Status Produk</div>}
                  />
                ) : (
                  <input
                    type="text"
                    name="status"
                    id="status"
                    className="form-control"
                    required="required"
                    value={this.state.status}
                    // onChange={this.handleChange}
                    readOnly={this.state.readMode}
                  />
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => this.hideModal()}
              className="btn btn-outline-secondary">
              Keluar
            </button>
            {this.state.readMode == false ? (
              this.state.typeModal == "Edit" ? (
                <LaddaButton
                  loading={this.state.loading}
                  onClick={() => {
                    this.update(this.state.typeModal);
                  }}
                  className="btn btn-primary">
                  Simpan
                </LaddaButton>
              ) : (
                <LaddaButton
                  loading={this.state.loading}
                  onClick={() => {
                    this.create(this.state.typeModal);
                  }}
                  className="btn btn-primary">
                  Simpan
                </LaddaButton>
              )
            ) : (
              ""
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default Produk;
