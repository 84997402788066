import React from "react";

import "./style.css";
import { Component } from "react";
class Dashboard extends Component{
  constructor(props){
    super(props)
    this.state ={
      users: [],
      loading: false,
      size: null,
      token:localStorage.getItem('token'),
    }
  }
  componentDidMount(){
    this.getproduct();
  }
  getproduct()
  {
      this.setState({loading:true})
      fetch(process.env.REACT_APP_URL+'/information/mitra',{
          method: "get",
          headers:{
            Authorization: "Barier " + this.state.token,
          }
      }).then((response)=>{
          response.json().then((result)=>{
            //   console.warn("result",result.data.length);
              this.setState({loading:false, users: result.data,})
              console.log(this.state.users);
          })
      })
  }

  render(){
    return (
      <div>
        <>
          <main className="container-fluid pt-4 mt-5">
            <nav aria-label="breadcrumb my-2">
              <ol className="breadcrumb mb-4" type="none">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard
                </li>
              </ol>
            </nav>

            <div
              className="card border-0 shadow rounded-20 card-dashboard-user rounded-md"
              style={{ backgroundColor: "#ffe3c333" }}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-md-7">
                    <div className="row">
                      <div className="col-md-12 py-4">
                        <h3 className="font-weight-semibold">
                          Selamat Datang, {this.state.users.company}{" "}
                        </h3>
                        <span>Transaksi hari ini ramai lancar </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 text-center d-none d-lg-block">
                    <img
                      className="img-dashboard-user mt-0 img-fluid"
                      src="/img/admin/dashboard.png"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="row mt-2">
                  <div className="col-md-12">
                    <div className="card border-white shadow">
                      <div className="card-header bg-light">
                        <b>Saldo</b>
                      </div>
                      <div className="card-body">
                        <table className="table table-borderless">
                          <tr className="text-success">
                            <th>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR',minimumFractionDigits: 0  }).format(this.state.users.balance)}</th>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row mt-2">
                  <div className="col-md-12">
                    <div className="card border-white shadow">
                      <div className="card-header bg-light">
                        <b>Perusahaan</b>
                      </div>
                      <div className="card-body">
                        <table className="table table-borderless">
                          <tr className="text-success">
                            <th>{this.state.users.company}</th>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row mt-2">
                  <div className="col-md-12">
                    <div className="card border-white shadow">
                      <div className="card-header bg-light">
                        <b>ID Partner</b>
                      </div>
                      <div className="card-body">
                        <table className="table table-borderless">
                          <tr className="text-success">
                            <th>{this.state.users.partner_id}</th>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row mt-2">
                  <div className="col-md-12">
                    <div className="card border-white shadow">
                      <div className="card-header bg-light">
                        <b>Nomor Handphone</b>
                      </div>
                      <div className="card-body">
                        <table className="table table-borderless">
                          <tr className="text-success">
                            <th>{this.state.users.phone}</th>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      </div>
    )
  }
}
export default Dashboard;
