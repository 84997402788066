import React from "react";
import { Helmet } from "react-helmet";

function Faq(props) {
  return (
    <div>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Faq - {process.env.REACT_APP_NAME_URL}</title>
          <link rel="canonical" href={process.env.REACT_APP_URL} />
        </Helmet>
      </div>
      <section className="section p-0px-t section-top-up-100 bg-white">
        <div className="container">
          <div className="card shadow">
            <div
              className="card-header text-center"
              style={{ backgroundColor: "#f6f9ff" }}>
              <h5 className="mt-3"> Frequently Asked Questions (FAQ) </h5>
            </div>
            <div className="card-body">
              <div id="accordion">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne">
                        1. Apa itu Cuan?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordion">
                    <div className="card-body">
                      Cuan adalah platform digital payment dengan berbagai jenis
                      layanan/produk untuk mempermudah kebutuhan digital
                      sehari-hari
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo">
                        2. Kenapa menggunakan Cuan?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordion">
                    <div className="card-body">
                      Jenis layanan/produk yang ada di website Cuan cukup
                      beragam, serta akan ada banyak diskon dan promo menarik
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree">
                        3. Bagaimana cara bertransaksi di Cuan?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordion">
                    <div className="card-body">
                      Caranya mudah, cukup kunjungi website Cuan dan pilih jenis
                      layanan/produk yang sesuai dengan kebutuhan Anda, kemudian
                      ketik nomor pelanggan yang sesuai dan Anda akan langsung
                      bisa bertransaksi tanpa harus login/register terlebih
                      dahulu.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="heading4">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse4"
                        aria-expanded="false"
                        aria-controls="collapse4">
                        4. Bagaimana cara bertransaksi di Cuan?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse4"
                    className="collapse"
                    aria-labelledby="heading4"
                    data-parent="#accordion">
                    <div className="card-body">
                      Ya, Anda akan mendapatkan diskon khusus jika melakukan
                      registrasi di website Cuan untuk menjadi member
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="heading5">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse5"
                        aria-expanded="false"
                        aria-controls="collapse5">
                        5. Bagaimana cara mendapatkan diskon?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse5"
                    className="collapse"
                    aria-labelledby="heading5"
                    data-parent="#accordion">
                    <div className="card-body">
                      Untuk mendapatkan promo, Anda bisa langsung cek di website
                      Cuan dan jika ada promo yang sedang berlangsung maka Anda
                      bisa menggunakan voucher diskon tersebut tanpa harus
                      menjadi member terlebih dahulu.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Faq;
