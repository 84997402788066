import React, { Component } from "react";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";

class Footer extends Component {
  render() {
    return (
      <div>
        <footer className="footer bg-light">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-md-4 m-15px-tb">
                  <h2>Bayar Tagihan Online</h2>
                  <p className="justify-content-center small">
                    {process.env.REACT_APP_META_CONTENT}
                    <br />
                    <br />
                    Bayar tagihan online lebih mudah dari tagihan PLN, BPJS,
                    PDAM, TELKOM, pembelian Token listrik, Pulsa/Data dan banyak
                    layanan lainnya.
                  </p>
                </div>
                <div className="col-md-3 col-sm-12 m-15px-tb col-6">
                  <h5 className=" footer-title">Transaksi</h5>
                  <ul className="list-unstyled links-dark footer-link-1">
                    <li>
                      <a href="/pln">PLN</a>
                    </li>
                    <li>
                      <a href="/bpjs">BPJS Kesehatan</a>
                    </li>
                    <li>
                      <a href="/telkom">Telkom</a>
                    </li>
                    <li>
                      <a href="/pdam">Air PDAM</a>
                    </li>
                    <li>
                      <a href="/pulsa">Pulsa & Paket Data</a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3 col-sm-12 m-15px-tb col-6">
                  <h5 className="dark-color footer-title">Halaman</h5>
                  <ul className="list-unstyled links-dark footer-link-1">
                    <li>
                      <a href="/faq">Tentang {process.env.REACT_APP_NAME}</a>
                    </li>
                    <li>
                      <a href="/pln">Transaksi</a>
                    </li>
                    <li>
                      <a href="/chek">Status Pembayaran</a>
                    </li>
                    <li>
                      <a href="/login">Masuk</a>
                    </li>
                    <li>
                      <a href="/register">Daftar</a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-2 col-sm-12 m-15px-tb">
                  <h5 className="dark-color footer-title">Tipe Pembayaran</h5>
                  <img
                    alt=""
                    title=""
                    src="/img/payment.png"
                    className="img-fluid"
                  />

                  <h5 className="footer-title mt-4">Ikuti Kami</h5>
                  <a href="#">
                    <FaTwitter className="text-secondary mr-2" size={32} />
                  </a>
                  <a href="#">
                    <FaFacebook className="text-secondary mr-2" size={32} />
                  </a>
                  <a href="#">
                    <FaInstagram className="text-secondary " size={32} />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom border-style top light">
            <div className="container">
              <div className="row">
                <div className="col-md-6 text-center text-md-right m-5px-tb">
                  <ul className="nav justify-content-center justify-content-md-start font-small footer-link-1">
                    <li className="border-right pr-2">
                      <a href="/faq" className="text-muted">
                        Syarat & Ketentuan
                      </a>
                    </li>
                    <li className="border-right pr-2">
                      <a href="/faq" className="text-muted">
                        Faq's
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://api.whatsapp.com/send?phone=6283870574102"
                        className="text-muted">
                        Cuan Customer Care
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 text-center text-md-right m-5px-tb">
                  <a
                    href="https://www.pelangi.co.id"
                    target="_blank"
                    className="m-0px font-small font-wight-semibold text-muted">
                    © 2021 PT PELANGI INDODATA
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
