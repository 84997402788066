import React from "react";
import { Helmet } from "react-helmet";

function Faq(props) {
  return (
    <div>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>About - {process.env.REACT_APP_NAME_URL}</title>
          <link rel="canonical" href={process.env.REACT_APP_URL} />
        </Helmet>
      </div>
      <section className="section p-0px-t section-top-up-100 bg-white">
        <div className="container">
          <div className="card shadow">
            <div
              className="card-header text-center"
              style={{ backgroundColor: "#f6f9ff" }}>
              <h5 className="mt-3"> About</h5>
            </div>
            <div className="card-body text-center">
              <img src="./img/logo_new_big.png" style={{ width: "20%" }}></img>
              <br />
              <br />
              <br />
              <h4 className="my-3">
                Cuan adalah No. 1 Tempat Bayar tagihan Online se-Indonesia.
              </h4>
              <div className="col-md-8 m-auto">
                Cuan adalah platform digital payment khusus untuk melakukan
                transaksi sehari-hari dengan berbagai jenis layanan/produk yang
                beragam mulai dari layanan pembelian token listrik (PLN
                Prepaid), layanan pembayaran tagihan listrik (PLN Postpaid),
                layanan pembayaran iuran BPJS Kesehatan, layanan pembayaran
                tagihan air PDAM, layanan pembayaran tagihan Telkom (Telepon
                Rumah & Internet), layanan pembelian voucher game online, serta
                pembelian produk pulsa dan paket data.
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Faq;
