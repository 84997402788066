import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";
import { AuthContext } from "../../router/Router";
import { Redirect } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { withWidth } from "@material-ui/core";

function LoginPage(props) {
  const { register, handleSubmit, errors } = useForm();
  const [message, setMessage] = useState();
  const [chaptha, setChaptha] = useState(false);
  const [registers, setRegisters] = useState(false);
  const recaptchaRef = React.createRef();

  const { dispatch } = useContext(AuthContext);
  const { state } = useContext(AuthContext);

  // //chek auth
  if (state.isAuthenticated) {
    if (state.role == 1) {
      return <Redirect to="/cpadm/dashboard" />;
    } else if (state.role == 2) {
      return <Redirect to="/" />;
    } else {
    }
  }

  const onSubmit = (data, e) => {
    if (chaptha) {
      setMessage({
        data: "Login is in progress...",
        type: "alert-warning",
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Barier " + process.env.REACT_APP_TOKEN,
          Accept: "application/json",
        },
      };
      Axios.post(
        `${process.env.REACT_APP_URL}/login`,
        JSON.stringify(data),
        config
      )
        .then((res) => {
          if (res.data.status == 200) {
            setMessage({
              data: res.data.message,
              type: "alert-success",
            });

            dispatch({
              type: "LOGIN",
              payload: res.data.data,
            });
          } else {
            setMessage({
              data: res.data.message,
              type: "alert-danger",
            });
          }
          !res.data.status && e.target.reset();
          window.grecaptcha.reset();
        })
        .catch((error) => {
          setMessage({
            data: error.response.data.message,
            type: "alert-danger",
          });
        });
    } else {
      setMessage({
        data: "Chek Rechaptha",
        type: "alert-danger",
      });
    }
  };

  const onChange = (value) => {
    if (value) {
      setChaptha(true);
    }

    console.log(chaptha);
  };

  return (
    <div>
      <div className="container">
        <div className="row mt-2">
          <div className="col-md-6 m-auto d-none d-lg-block p-5">
            <img src="/img/login.png" className="img-fluid m-auto" alt="" />
          </div>
          <div className="col-md-6 m-auto">
            <div className="card shadow">
              <div className="card-body">
                <div className="text-center p-3">
                  <img
                    className="logo-light img-fluid mb-3"
                    alt=""
                    title=""
                    src="/img/logo_new.png"
                  />
                  <h5>Masuk</h5>
                  <span className="small">Silahkan masuk</span>
                  {message && (
                    <div
                      className={`alert fade show d-flex ${message.type}`}
                      role="alert">
                      {message.data}
                      <span
                        aria-hidden="true"
                        className="ml-auto cursor-pointer"
                        onClick={() => setMessage(null)}>
                        &times;
                      </span>
                    </div>
                  )}
                </div>

                <form
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                  autoComplete="off">
                  <div className="form-group">
                    <label htmlFor="inputForName">Username</label>
                    <span className="text-danger">*</span>
                    <input
                      id="inputForName"
                      name="username"
                      type="text"
                      className="form-control"
                      placeholder="Masukkan username"
                      ref={register({
                        required: {
                          value: true,
                          message: "Username masih kosong",
                        },
                        pattern: {
                          value: /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/,
                          message:
                            "Mohon masukkan username yang valid tanpa spasi",
                        },
                        minLength: {
                          value: 5,
                          message: "Mininum username 5 karakter",
                        },
                        maxLength: {
                          value: 15,
                          message: "Maksimal username 15 karakter",
                        },
                      })}
                    />
                    {errors.username && (
                      <span className="small text-danger text-center">
                        {errors.username.message}
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <input
                      id="inputFor"
                      name="media"
                      type="hidden"
                      value={process.env.REACT_APP_PARAM}
                      className="form-control"
                      aria-describedby="Masukka alamat email"
                      placeholder="Masukka alamat email"
                      ref={register({
                        required: {
                          value: true,
                          message: "param",
                        },
                        maxLength: {
                          value: 100,
                          message: "Maksimal terdiri dari 100",
                        },
                      })}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="inputForPassword">Password</label>
                    <span className="text-danger">*</span>
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      id="inputForPassword"
                      placeholder="Masukkan password"
                      ref={register({
                        required: {
                          value: true,
                          message: "Password masih kosong",
                        },
                        minLength: {
                          value: 8,
                          message: "Minimal password 8 karakter",
                        },
                        maxLength: {
                          value: 62,
                          message: "Maksimal password 62 karakter",
                        },
                      })}
                    />
                    {errors.password && (
                      <span className="small text-danger text-center">
                        {errors.password.message}
                      </span>
                    )}
                  </div>

                  <div className="w-100 px-auto d-flex">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6LfHMCEaAAAAAKjWZtDNjzWaaClaj1G2KHyAD1iA"
                      onChange={onChange}
                      className="py-3 mx-auto"
                    />
                  </div>

                  <button type="submit" className="btn btn-block  btn-primary">
                    Masuk
                  </button>
                  <div className="row col-md-12 justify-content-center mt-2">
                    <span className="text-center">
                      Belum punya akun ? <a href="/register">Daftar Sekarang</a>
                    </span>
                  </div>

                  <div className="text-center mt-3">
                    <label
                      htmlFor="copiright"
                      className="small text-mute m-auto">
                      © PT. Pelangi Indodata 2020
                    </label>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
