import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { Redirect } from "react-router-dom";
import Moment from "react-moment";
import Collapsible from "react-collapsible";

// import ReactMomentCountDown from 'react-moment-countdown';

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      struk: JSON.parse(localStorage.getItem("private_payment")),
      noVirtual: "8870895421436134",
      chekstatus: false,
    };
  }

  componentDidMount() {
    console.log(this.state.struk.data.reprint_id);
    this.updateMe();
  }

  updateMe() {
    setInterval(() => {
      if (this.state.chekstatus == false) this.chekstatus();
    }, 5000);
  }

  chekstatus() {
    let key = process.env.REACT_APP_TOKEN;
    let token = "Bearer " + key;
    fetch(
      process.env.REACT_APP_URL +
        "/merchant/status/" +
        this.state.struk.data.transaction_id,
      {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }
    ).then((response) => {
      response.json().then((result) => {
        if (result.status == 400) {
          this.setState({ chekstatus: false });
        } else {
          localStorage.setItem(
            "private_struk",
            JSON.stringify({
              struk: this.state.struk,
            })
          );
          this.setState({ chekstatus: true });
        }
      });
    });
  }

  render() {
    let date = this.state.struk.data.expiry_date;
    return (
      <div>
        {this.state.chekstatus ? (
          <>
            <Redirect
              to={{
                pathname: "/invoice/" + this.state.struk.data.transaction_id,
                state: {
                  harga: this.state.struk.data.harga,
                  amount: this.state.struk.data.amount,
                  adminBank: this.state.struk.data.adminBank,
                },
              }}
            />
          </>
        ) : (
          <></>
        )}
        <section className="g-bg-gray p-0px-t section-top-up-100">
          <div className="container" style={{ maxWidth: "600px" }}>
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <div className="card border-radius-2 text-center m-auto align-items-center">
                  <img
                    src="https://cdn.dribbble.com/users/415089/screenshots/14359077/media/12e60e1d4f5556b56310908bc9e6c2ea.jpg"
                    style={{ width: "200px", margin: "auto" }}
                  />
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td className="font-weight-bold">
                          Selesaikan Pembayaran Sebelum
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold text-success">
                          <Moment format="DD MMM YYYY - HH:mm">{date}</Moment>
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">
                          Sisa Waktu Pembayaran
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold text-danger">
                          <Moment
                            format="HH:mm:ss"
                            date={date}
                            durationFromNow
                            interval={30}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="alert col-md-11 alert-warning small">
                    Untuk menghindari pembatalan otomatis oleh sistem kami,
                    silahkan lakukan pembayaran sebelum sisa waktu pembayaran
                    diatas habis
                  </div>
                  <a
                    href={this.state.struk.data.invoice_url}
                    target="_blank"
                    onClick={() => this.updateMe()}
                    className="btn btn-primary btn-lg border col-md-11 font-weight-bold text-white shadow"
                  >
                    Bayar Invoice Sekarang
                  </a>
                  <div className="alert col-md-11 alert-info small mt-3">
                    Halaman ini akan otomatis dimuat ulang setelah Anda
                    melakukan pembayaran, mohon untuk tidak menutup halaman ini
                    sampai proses pembayaran selesai.
                  </div>
                </div>
                <div className="card mt-2">
                  <div className="card-header">Panduan Pembayaran</div>
                  <div className="card-body">
                    <Collapsible
                      trigger="ATM"
                      className="card card-header text-dark bg-white mt-3 border-secondary "
                      openedClassName="card card-header text-dark bg-white mt-2 border-secondary "
                      triggerStyle={{ cursor: "pointer" }}
                    >
                      <p className="small text-dark text-left mt-2">
                        1. Masukkan kartu ATM dan PIN
                        <br />
                        2. Pilih menu "Bayar/Beli"
                        <br />
                        3. Pilih menu "Lainnya", hingga menemukan menu
                        "Multipayment"
                        <br />
                        4. Masukkan Kode Biller, lalu pilih Benar
                        <br />
                        5. Masukkan "Nomor Virtual Account" Tokopedia, lalu
                        pilih tombol Benar
                        <br />
                        6. Masukkan Angka "1" untuk memilih tagihan, lalu pilih
                        tombol Ya
                        <br />
                        7. Akan muncul konfirmasi pembayaran, lalu pilih tombol
                        Ya
                        <br />
                        8. Simpan struk sebagai bukti pembayaran Anda
                        <br />
                      </p>
                    </Collapsible>
                    <Collapsible
                      trigger="Internet Banking / Mandiri Online"
                      className="card card-header text-dark bg-white mt-3 mb-5 border-secondary "
                      openedClassName="card card-header text-dark bg-white mt-2 border-secondary  mb-5"
                      triggerStyle={{ cursor: "pointer" }}
                    >
                      <p className="small text-dark text-left mt-2">
                        1.Login Mandiri Online dengan memasukkan Username dan
                        Password
                        <br />
                        2.Pilih menu "Pembayaran"
                        <br />
                        3.Pilih menu "Multipayment"
                        <br />
                        4.Masukkan "Nomor Virtual Account" dan "Nominal" yang
                        akan dibayarkan, lalu pilih Lanjut
                        <br />
                        5.Setelah muncul tagihan, pilih Konfirmasi
                        <br />
                        6.Masukkan PIN / Challenge Code Token
                        <br />
                        7.Transaksi selesai, simpan bukti bayar Anda
                        <br />
                        Jangan gunakan fitur "Simpan Daftar Transfer" untuk
                        pembayaran melalui Internet Banking karena dapat
                        mengganggu proses pembayaran berikutnya.
                        <br />
                        1.Untuk menghapus daftar transfer tersimpan ikuti
                        langkah berikut:
                        <br />
                        2.Login Mandiri Online
                        <br />
                        3.Pilih ke menu Pembayaran
                        <br />
                        4.Pilih menu Daftar Pembayaran
                        <br />
                        5.Pilih pada pembayaran yang tersimpan, lalu pilih menu
                        untuk hapus
                        <br />
                      </p>
                    </Collapsible>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default HomePage;
