import React,{Component} from 'react';
import ReactTable from "react-table"; 
import 'react-table/react-table.css'
import ReactLoading from 'react-loading';
import LaddaButton, { l, EXPAND_RIGHT } from 'react-ladda';
import Swal from 'sweetalert2'
import Select from 'react-select';
import Modal from "react-bootstrap/Modal";

const Loading = ({ type, color }) => (
    <ReactLoading type={type} color={color} height={'10%'} width={'10%'} />
);

class ProdukPri extends Component{
  constructor(props){
    super(props)
    this.state = {
      users: [],
      loading:false,
      login:false,
      token:localStorage.getItem('token'),
      store:null,
      value: '62',
      size:0,
      listquotes: [],
      quotes: null,
      quote_id_edit: null,
      isEdit: false,
      produkId: [],
      produkCat: [],
      prodId: null,
      prodCat: null,
      valId: null,
      ValCat: null,
      isLoading: false,
      toogleBtn: true,
      product_id: null,
      name: null,
      price: null,
      description: null,
      readMode: true,
      id: null,
      filter: [],
    };
  }

  showModal = (type, data) => {
    if (type == "Edit") {
      this.setState({
        id: data.id,
        product_id: data.product_id,
        name: data.name,
        price: data.price,
        description: data.description,
        prodId: data.product_id,
        readMode: false,
      });
      console.log(this.state.prodId);
      if (data.active == 1) {
        this.setState({ toogleBtn: true });
      } else {
        this.setState({ toogleBtn: true });
      }
    }else if(type == "Tambah"){
      this.setState({
        id: null,
        product_id:null,
        name: null,
        price: null,
        description: null,
        prodId: null,
        readMode: false,
      });
    }else {
      this.setState({
        id: data.id,
        product_id: data.product_id,
        name: data.name,
        price: data.price,
        description: data.description,
        prodId: data.product_id,
        readMode: true,
      });
      console.log(this.state.prod_id);
      if (data.active == 1) {
        this.setState({ toogleBtn: true });
      } else {
        this.setState({ toogleBtn: true });
      }
    }
    this.setState({ setIsOpen: true, typeModal: type });
    // console.warn("status : ",this.state.status);
  };

  hideModal = () => {
    this.setState({ setIsOpen: false });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeStatus= (value) => {
    this.setState({prodId: value});
    console.warn('prodId warn : ',this.state.prodId)
    this.setState({product_id: value.value});
    console.warn('product_id warn : ',this.state.product_id)
  };

  handleChangeFilter = (value) => {
    this.setState({ValCat: value});
    console.log('',this.state.ValCat)
    let produk_cat = value.value;
    console.log(produk_cat);
    this.setState({prodCat: produk_cat})
    // this.getFilterId(produk_cat);
  };

  update() {
      // Swal.fire({
      //     title: 'Yakin akan menyimpannya?',
      //     showCancelButton: true,
      //     confirmButtonText: 'SIMPAN',
      // }).then((result) => {
      //     if(result.isConfirmed){
        if(this.state.description.length >=5){
            fetch(process.env.REACT_APP_URL + '/product/prices/update/' + this.state.id, {
                  method: "put",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Barier " + this.state.token,
                    Accept: "application/json",
                  },
                  body: JSON.stringify({
                    product_id : this.state.product_id,
                    price: this.state.price,
                    description: this.state.description,
                  })
                })
                .then((response) =>{
                    response.json().then((result)=>{
                        if(result.status=="200"){
                            this.getproduct();
                            this.setState({isEdit:false,setIsOpen: false});
                            Swal.fire({
                                title: 'Sukses Disimpan',
                                text: result.data.desc,
                                icon: 'success',
                                confirmButtonText: 'oke'
                            });
                        }
                        else{
                          this.setState({ message: result.message });
                          Swal.fire({
                            title: 'Upss!',
                            text: result.message,
                            icon: 'error',
                            confirmButtonText: 'oke'
                          });
                        }
                    })
                });
              }else{
                this.setState({ message: "Description harus diisi minimal 5 Digit" });
                Swal.fire({
                  title: 'Upss!',
                  text: "Description harus diisi minimal 5 Digit",
                  icon: 'error',
                  confirmButtonText: 'oke'
                });
              }
      //     }
      // })
  }

  create() {
    // Swal.fire({
    //     title: 'Yakin akan menyimpannya?',
    //     showCancelButton: true,
    //     confirmButtonText: 'SIMPAN',
    // }).then((result) => {
    //     if(result.isConfirmed){
      if(this.state.description.length >=5){
          fetch(process.env.REACT_APP_URL + '/product/prices/create', {
                method: "post",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Barier " + this.state.token,
                  Accept: "application/json",
                },
                body: JSON.stringify({
                  product_id: this.state.product_id,
                  price: this.state.price,
                  description: this.state.description,
                })
              })
              .then((response) =>{
                  response.json().then((result)=>{
                      if(result.status=="200"){
                          this.getproduct();
                          this.getproductId();
                          this.setState({isEdit:false,setIsOpen: false});
                          Swal.fire({
                              title: 'Sukses Disimpan',
                              text: result.data.desc,
                              icon: 'success',
                              confirmButtonText: 'oke'
                          });
                      }
                      else{
                        this.setState({ message: result.message });
                        Swal.fire({
                          title: 'Upss!',
                          text: result.message,
                          icon: 'error',
                          confirmButtonText: 'oke'
                        });
                      }
                  })
              });
            }else{
              this.setState({ message: "Description harus diisi minimal 5 Digit" });
              Swal.fire({
                title: 'Upss!',
                text: "Description harus diisi minimal 5 Digit",
                icon: 'error',
                confirmButtonText: 'oke'
              });
            }
    //     }
    // })
  }
  
  confirmDelete(value){
    Swal.fire({
      title: 'Yakin akan Menghapus?',
      text: 'ID Produk : '+value.product_id+' | Deskripsi : '+value.description,
      showCancelButton: true,
      confirmButtonText: 'HAPUS',
      cancelButtonText: 'BATAL',
    }).then((result) => {
        if(result.isConfirmed){
          fetch(process.env.REACT_APP_URL + '/product/prices/delete/' + value.id, {
            method: "delete",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Barier " + this.state.token,
              Accept: "application/json",
            },
          })
          .then((response) =>{
              response.json().then((result)=>{
                  if(result.status=="200"){
                      this.getproduct();
                      this.getproductId();
                      this.setState({isEdit:false,setIsOpen: false});
                      Swal.fire({
                          title: 'Sukses Dihapus',
                          text: result.data.desc,
                          icon: 'success',
                          confirmButtonText: 'oke'
                      });
                  }
                  else{
                    this.setState({ message: result.message });
                    Swal.fire({
                      title: 'Upss!',
                      text: result.message,
                      icon: 'error',
                      confirmButtonText: 'oke'
                    });
                  }
              })
          });
        }
      })
  }

  componentDidMount(){
    this.getproduct();
    this.getproductId();
  }

  getproduct()
  {
      this.setState({loading:true})
      fetch(process.env.REACT_APP_URL+'/product/prices',{
          method: "get",
          headers:{
            Authorization: "Barier " + this.state.token,
          }
      }).then((response)=>{
          response.json().then((result)=>{
              this.setState({loading:false, users: result.data, size:result.data.length, filter: result.data})
              console.warn(this.state.users);
          })
      })
  }

  getproductId()
  {
    this.setState({loading:true})
      fetch(process.env.REACT_APP_URL+'/products',{
          method: "get",
          headers:{
            Authorization: "Barier " + this.state.token,
          }
      }).then((response)=>{
          response.json().then((result)=>{
              this.setState({loading:false, produkId: result.data})
              console.warn(this.state.produkId);
          })
      })
  }
  
  getFilterId()
  {
    this.setState({loading:true})
      fetch(process.env.REACT_APP_URL+'/product/prices/'+ this.state.prodCat,{
          method: "get",
          headers:{
            Authorization: "Barier " + this.state.token,
          }
      }).then((response)=>{
          response.json().then((result)=>{
              this.setState({loading:false, users: result.data})
          })
      })
  }

  reset(){
    this.getproduct();
    this.getproductId();
    this.setState({ valId: null, ValCat: null, prodId: null, prodCat:null, produkId: []});
  }

 render(){

    const { options, value ,options_pulsa } = this.state;
    const columns = [
      {
        Header: "#",
        maxWidth: 50,
        Cell: (row) => {
          return <div>{row.index + 1}</div>;
        },
        style: {
          textAlign: "center",
          textTransform: "uppercase",
          flexDirection: "column",
          justifyContent: "center",
        },
      },
      {
        Header: "PRODUK",
        accessor: "name",
        maxWidth: 180,
        style: {
          textAlign: "left",
          textTransform: "uppercase",
          flexDirection: "column",
          justifyContent: "center",
        },
      },
      {
        Header: "DESKRIPSI",
        accessor: "description",
        style: {
          textAlign: "left",
          textTransform: "uppercase",
          flexDirection: "column",
          justifyContent: "center",
        },
      },
      {
        Header: "HARGA",
        accessor: "price",
        maxWidth: 100,
        style: {
          textAlign: "right",
          flexDirection: "column",
          justifyContent: "center",
        },
        Cell: props => new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR',minimumFractionDigits: 0  }).format(props.value)
      },
      {
        Header: "AKSI",
        accessor: "id",
        maxWidth: 180,
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
        filterable: false,
        Cell: (row) => (
          <div>
            <button
              className="btn btn-flat btn-sm btn-info"
              onClick={() => this.showModal("Detail", row.original)}
            >
              Detail
            </button>
            &nbsp;
            <button
              className="btn btn-flat btn-sm btn-warning"
              onClick={() => this.showModal("Edit", row.original)}
            >
              Edit
            </button>
            &nbsp;
            <button
              onClick={() => this.confirmDelete(row.original)}
              className="btn btn-flat btn-sm btn-danger"
            >
              Hapus
            </button>
          </div>
        ),
      },
    ];
    const produxs = this.state.produkId.map(d=>({
      "value" : d.prod_id,
      "label" : d.prod_id+" - "+d.name+" - "+d.description
    }));
    const produxsId = this.state.filter.map(d=>({
      "value" : d.id,
      "label" : d.id+" - "+d.name+" - "+d.description
    }));

    const produxsID = produxs.find(obj => obj.value == this.state.prodId); 
   return (
    <>
    <div>
        <main className="container-fluid pt-4 mt-5">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb" type="none">
              <li class="breadcrumb-item">
                <a href="#">Admin</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">Master</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Harga Produk
              </li>
            </ol>
          </nav>

          <div className="card mt-2 rounded-2 border-white shadow">
            <div className="card-header text-dark bg-light">
              <b>Data Harga Produk</b>
            </div>
            <div className="card-body">
              <div className="row mt-3">
                <div className="col-md-6">
                  <Select 
                      options = {produxsId}
                      value={this.state.ValCat} 
                      onChange={value => this.handleChangeFilter(value)} 
                      placeholder={<div>Pilih Kategori Produk</div>}
                      />
                </div>
                <div className="col-md-2">
                  <LaddaButton
                    loading={this.state.loading}
                    className="btn btn-primary m-btn-radius w-100"
                    data-color="#008aff"
                    data-size={l}
                    onClick={() => this.getFilterId()}
                  >FILTER</LaddaButton>
                </div>
                <div className="col-md-2">
                  <LaddaButton
                    loading={this.state.loading}
                    className="btn btn-danger m-btn-radius w-100"
                    data-color="#008aff"
                    data-size={l}
                    onClick={() => this.reset()}
                  >RESET</LaddaButton>
                </div>
                <div className="col-md-2">
                  <LaddaButton
                    loading={this.state.loading}
                    className="btn btn-success m-btn-radius w-100"
                    data-color="#008aff"
                    data-size={l}
                    onClick={() => this.showModal("Tambah", )}
                  >TAMBAH</LaddaButton>
                </div>
              </div>
              {this.state.isLoading ? (
                <Loading type="spinningBubbles" color="#91091e" />
              ) : (
                <ReactTable
                  data={this.state.users}
                  // pageSize={this.state.dataDenom.length > 10 ? 10 : this.state.dataDenom.length}
                  defaultPageSize= {5}
                  columns={columns}
                  className="mt-3 table-striped w-100"
                />
              )}
            </div>
          </div>
        </main>
      </div>

      <Modal show={this.state.setIsOpen} onHide={() => this.hideModal()}>
          <Modal.Header>
            <Modal.Title>{this.state.typeModal} Harga Produk</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">
                ID Produk <sup className="text-danger">*</sup>
              </label>
              <div className="col-sm-8">
              {this.state.typeModal == "Tambah" || this.state.typeModal == "Edit" ? (
                <Select 
                  name="id"
                  options = {produxs}
                  value={produxs.find(obj => obj.value == this.state.prodId)} 
                  onChange={value => this.handleChangeStatus(value)} 
                  placeholder={<div>Pilih Produk</div>}
                  isOptionDisabled={true}
                />
              ):(
                <input
                  name="id"
                  value={this.state.id}
                  className="form-control"
                  readOnly
                />
              )}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">
                Deskripsi <sup className="text-danger">*</sup>
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  name="description"
                  id="description"
                  className="form-control"
                  required=""
                  value={this.state.description}
                  onChange={this.handleChange}
                  readOnly={this.state.readMode}
                />
              </div>
            </div>
            <div className="form-group row">
            <label className="col-sm-4 col-form-label">
                Harga <sup className="text-danger">*</sup>
              </label>
              <div className="col-sm-8">
                <input
                  type="number"
                  name="price"
                  id="price"
                  className="form-control"
                  required="required"
                  value={this.state.price}
                  onChange={this.handleChange}
                  readOnly={this.state.readMode}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => this.hideModal()}
              className="btn btn-outline-secondary"
            >
              Keluar
            </button>
            {this.state.readMode == false ? (
              this.state.typeModal == "Edit" ? (
              <LaddaButton
                loading={this.state.loading}
                onClick={() => {
                  this.update(this.state.typeModal);
                }}
                className="btn btn-primary"
              >
                Simpan
              </LaddaButton>
              ):(
                <LaddaButton
                loading={this.state.loading}
                onClick={() => {
                  this.create(this.state.typeModal);
                }}
                className="btn btn-primary"
              >
                Simpan
              </LaddaButton>
              )
            ) : (
              ""
            )}
          </Modal.Footer>
        </Modal>
    </>
   )
 } 
}

export default ProdukPri;