import React,{Component} from 'react';
import Produk from '../common/MenuProduk';
import {Route, Switch, useHistory, useLocation} from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import Typewriter from 'typewriter-effect';

const WithImage = (props) => (
    <div>
        <section className="effect-section parallax">
            <div className="">
            <Swiper
            className=""
            spaceBetween={50}
            slidesPerView={1}
            // navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            >
            <SwiperSlide style={{backgroundImage:'url(https://images.unsplash.com/photo-1462206092226-f46025ffe607?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&h=450&q=80)'}}>
            <div className="row align-items-center p-100px-tb justify-content-center">
            <div className="col-lg-8 m-15px-tb text-center mb-3">
                <h6 className="m-10px-b white-color text-black"></h6>
                <h3 className="display-4 m-10px-b white-color text-black">
                <Typewriter
                    options={{
                        strings: ['Pembayaran mudah', 'Pembayaran aman', 'Dimanapun', 'Kapanpun'],
                        autoStart: true,
                        loop: true,
                    }}
                    />
                    <br/>
                </h3>
            </div>
            </div>
            </SwiperSlide>

            <SwiperSlide style={{backgroundImage:'url(https://images.unsplash.com/photo-1546700143-27bda887ce11?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&h=400&q=80)'}}>
            <div className="row align-items-center p-100px-tb justify-content-center ">
            <div className="col-lg-8 m-15px-tb text-center mb-3">
                <h6 className="m-10px-b white-color">- Kami Mengucapkan</h6>
                <h1 className="display-4 m-10px-b white-color"><span className="font-w-300">Selamat Natal</span><br />Dan Tahun Baru 2021</h1>
            </div>
            </div>
            </SwiperSlide>
            
            </Swiper>
            </div>
        </section>

    </div>
  );


  const Header = (props) => (
    <div>
        <section className="section effect-section bg-primary">
        <div className="mask dark-bg opacity-1"></div>
        <div className="container position-relative">
            <div className="row align-items-center justify-content-center">
                <div className="col-lg-8 text-center">
                    <h6 className="white-color-light font-w-500"></h6>
                    <h1 className="display-4 white-color m-0px"></h1>
                </div>
            </div>
        </div>
    </section>
    </div>
  );


function Hero() {
        return ( 
            <div>
                <WithImage/>
            </div>
        )
}
       
export default Hero;