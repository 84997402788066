import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { AuthContext } from "../../../router/Router";
import axios from "axios";
import { Redirect } from 'react-router-dom';
import ReactLoading from "react-loading";
import Swal from "sweetalert2";
import { FaTrash, FaEdit, FaSearch, FaPlus } from "react-icons/fa";
import Moment from "react-moment";
import Modal from "react-bootstrap/Modal";
import LaddaButton from "react-ladda";
import Select from "react-select";

function DiskonProduk(props) {
  // const { state } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [disProdukState, setDisProdukState] = useState({
    id: 0,
    kode: "",
    user_id: "",
    diskon: "",
    product_id: "",
    description: "",
    due_date: "",
    type: "",
    pemakaian: "",
  });
  const [isAuth, setAuth] = useState(0);
  const [isOpen, setIsOpen] = useState({ display: false, type: "" });
  const [isUpdate, setUpdate] = useState(0);
  const [isSearchInput, setSearchInput] = useState("");
  const [toogleBtn, setToogleBtn] = useState(false);
  const [txtForm, setTxtForm] = useState("Tambah");
  const [displayAlert, setDisplayAlert] = useState({
    display: "none",
    type: "",
    message: "",
  });
  const [isReload, setReload] = useState(false);
  const [isDataDisProduk, setIsDataDisProduk] = useState([]);
  const [isDataProdId, setIsDataProdId] = useState([]);
  const [isDataUserId, setIsDataUserId] = useState([]);
  const [isProdId, setProdId] = useState({ value: "", label: "" });
  const [isType, setType] = useState({ value: "", label: "" });
  const [isUserId, setUserId] = useState({ value: "", label: "" });
  //   const [query, setQuery] = useState("");
  const Loading = ({ type, color }) => (
    <ReactLoading
      type={type}
      color={color}
      className="text-center"
      height={"4%"}
      width={"4%"}
      className="d-flex justify-content-center"
    />
  );
  const columns = [
    {
      Header: "#",
      maxWidth: 50,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
      style: {
        textAlign: "center",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "USERNAME",
      accessor: "username",
      style: {
        textAlign: "left",
        textTransform: "capitalize",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "KODE",
      accessor: "kode",
      width:150,
      style: {
        textAlign: "left",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "PRODUK",
      accessor: "product_name",
      width: 200,
      style: {
        textAlign: "left",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "DISKON (%)",
      accessor: "diskon",
      style: {
        textAlign: "left",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "DESKRIPSI",
      accessor: "description",
      width: 230,
      style: {
        textAlign: "left",
        textTransform: "capitalize",
        flexDirection: "column",
        justifyContent: "center",
        whiteSpace: "unset",
      },
    },
    {
      Header: "DUE DATE",
      accessor: "due_date",
      width: 120,
      style: {
        textAlign: "left",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (row) => (
        <div>
          <Moment format="DD MMM YYYY">
            {row.original.due_date.slice(0,10)}
          </Moment>
        </div>
      ),
    },
    {
      Header: "TIPE",
      accessor: "type",
      style: {
        textAlign: "center",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "MAX QUOTA",
      accessor: "pemakaian",
      style: {
        textAlign: "left",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "USED QUOTA",
      accessor: "sisa_pemakaian",
      style: {
        textAlign: "left",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "AKSI",
      accessor: "id",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
      filterable: false,
      Cell: (row) => (
        <div>
          <button
            className="btn btn-warning btn-flat btn-sm mr-1"
            onClick={() => showModal("Edit", row.original)}
            title="Edit"
          >
            <FaEdit className="text-white text-center" />
          </button>
          
          <button
            onClick={() => confirmDelete(row.original.id)}
            className="btn btn-danger btn-flat btn-sm"
            title="Hapus"
          >
            <FaTrash className="text-white text-center" />
          </button>
        </div>
      ),
    },
  ];
  const token = localStorage.getItem("token");

  useEffect(() => {
    setIsLoading(true);
    fetchDisProduk();
    fetchProdId();
    fetchUsers();
    setIsLoading(false);
  }, [isSearchInput]);

  const fetchDisProduk = async () => {
    let searchInput = await isSearchInput;
    await axios
      .get(`${process.env.REACT_APP_URL}/product/discounts/list`, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.warn("response diskon", res.data.data);
        if (searchInput == "") {
          setIsDataDisProduk([...res.data.data]);
        } else {
          let filteredData = res.data.data.filter((value) => {
              if(value.username == null){
                value.username="";
              }
            return (
              value.username
                .toString()
                .toLowerCase()
                .includes(searchInput.toString().toLowerCase()) ||
              value.product_name
                .toString()
                .toLowerCase()
                .includes(searchInput.toString().toLowerCase()) ||
              value.kode
                .toString()
                .toLowerCase()
                .includes(searchInput.toString().toLowerCase()) ||
              value.diskon
                .toString()
                .toLowerCase()
                .includes(searchInput.toString().toLowerCase()) ||
              value.description
                .toString()
                .toLowerCase()
                .includes(searchInput.toString().toLowerCase()) ||
              value.due_date
                .toString()
                .toLowerCase()
                .includes(searchInput.toString().toLowerCase()) ||
              value.type
                .toString()
                .toLowerCase()
                .includes(searchInput.toString().toLowerCase()) ||
              value.pemakaian
                .toString()
                .toLowerCase()
                .includes(searchInput.toString().toLowerCase())
            );
          });
          setIsDataDisProduk([...filteredData]);
        }
        setReload(true);
      }).catch((err) => {
        // console.log(err.response.data)
        Swal.fire({
          title: "Uppss..",
          text: err.response.data.message,
          icon: "error",
          confirmButtonText: "Oke",
        });
        setAuth(err.response.data.status)
      });
  };
  const fetchProdId = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL}/products`, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setIsDataProdId([...res.data.data]);
        console.warn("response", res.data.data);
      });
  };
  const fetchUsers = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL}/users?role_id=2`, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setIsDataUserId([...res.data.data]);
        console.warn("response users", res.data.data);
      });
  };

  const saveDiskon = async (typemodal) => {
    if (
      disProdukState.kode == "" ||
      disProdukState.diskon == "" ||
      disProdukState.product_id == "" ||
      disProdukState.due_date == "" ||
      disProdukState.type == "" ||
      disProdukState.pemakaian == "" ||
      disProdukState.description == ""
    ) {
      Swal.fire({
        title: "Uppss..",
        text: "Lengkapi dahulu input yang kosong atau belum dipilih",
        icon: "error",
        confirmButtonText: "Tutup",
      });
    } else {
      if (disProdukState.description.length <= 5) {
        Swal.fire({
          title: "Uppss..",
          text: "Description minimal 5 karakter",
          icon: "error",
          confirmButtonText: "Tutup",
        });
      } else {
        if (typemodal == "Tambah") {
          console.warn("state", disProdukState);
          await createDisProduk();
        } else {
          await updateDisProduk();
        }
        setTxtForm("Tambah");
        await resetForm();
        await setIsOpen({ display: false, type: "Tambah" });
        await fetchUsers();
      }
    }
  };

  const confirmDelete = (iddisproduk) => {
    Swal.fire({
      title: "Hapus Data ?",
      text: "",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Hapus",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.value) {
        deleteDisProduk(iddisproduk);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //cencle
      }
    });
  };
  const createDisProduk = async () => {
    setIsLoading(true);
    // try {
      await axios
        .post(
          `${process.env.REACT_APP_URL}/product/discounts/create`,
          {
            kode: disProdukState.kode,
            user_id: disProdukState.user_id,
            diskon: disProdukState.diskon,
            product_id: disProdukState.product_id,
            description: disProdukState.description,
            due_date: disProdukState.due_date,
            type: disProdukState.type,
            pemakaian: disProdukState.pemakaian,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((json) => {
          console.warn("resp create diskon", json);
          if (json.data.status == 200) {
            fetchDisProduk();
            showAlert(
              "block",
              "success",
              "Data diskon produk berhasil ditambah"
            );
          } else if (json.data.status == 500) {
            showAlert(
              "block",
              "danger",
              "Data diskon produk gagal ditambah. " + json.data.message
            );
          } else {
            var errors = " ";
            for (var i = 0; i < json.data.message.length; i++) {
              errors += json.data.message[i].msg + ", ";
            }
            showAlert(
              "block",
              "danger",
              "Data diskon produk gagal ditambah. " + errors
            );
            console.warn("error", json.data.message);
          }
        }).catch((error) => {
            showAlert(
              "block",
              "danger",
              "Diskon produk gagal ditambah. " + error.response.data.message
            );
        })
    setIsLoading(false);
  };
  const updateDisProduk = async () => {
    setIsLoading(true);
    await axios
      .put(
        `${process.env.REACT_APP_URL}/product/discounts/update/` +
          disProdukState.id,
        {
          kode: disProdukState.kode,
          user_id: disProdukState.user_id,
          diskon: disProdukState.diskon,
          product_id: disProdukState.product_id,
          description: disProdukState.description,
          due_date: disProdukState.due_date,
          type: disProdukState.type,
          pemakaian: disProdukState.pemakaian,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((json) => {
        if (json.data.status == 200) {
          fetchDisProduk();
          showAlert("block", "success", "Data diskon produk berhasil diedit");
        } else {
          showAlert("block", "success", "Data diskon produk gagal diedit");
        }
      }).catch(function (error) {
        showAlert("block","danger","Data diskon produk gagal diedit. " + error.message);
      });
    setIsLoading(false);
  };
  const deleteDisProduk = async (iddisproduk) => {
    setIsLoading(true);
    await axios
      .delete(
        `${process.env.REACT_APP_URL}/product/discounts/delete/` + iddisproduk,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((json) => {
        if (json.data.status == 200) {
          fetchDisProduk();
          showAlert("block", "success", "Data diskon produk berhasil dihapus");
        } else {
          showAlert("block", "danger", "Data diskon produk gagal dihapus");
        }
      }).catch(function (error) {
        showAlert("block","danger","Data diskon produk gagal dihapus. " + error.message);
      });

    setIsLoading(false);
  };
  const showAlert = (display, type, message) => {
    setDisplayAlert({
      display: display,
      type: type,
      message: message,
    });

    const timeId = setTimeout(() => {
      setDisplayAlert({ display: "none" });
    }, 5000);
    return () => {
      clearTimeout(timeId);
    };
  };
  const showModal = async (type, data) => {
      
    if (type == "Edit") {
      await setTxtForm("Edit");
      await setDisProdukState({
        id: data.id,
        product_id: data.product_id,
        kode: data.kode,
        user_id: data.user_id,
        diskon: data.diskon,
        description: data.description,
        due_date: data.due_date.slice(0, 10),
        type: data.type,
        pemakaian: data.pemakaian,
      });
      
    } else {
      await setTxtForm("Tambah");
      await resetForm();
    }
    await setIsOpen({ display: true, type: type });
    await console.warn("state diskon", data);
  };
  const resetForm = () => {
    setDisProdukState({
      id: 0,
      kode: "",
      user_id: "",
      diskon: "",
      product_id: "",
      description: "",
      due_date: "",
      type: "",
      pemakaian: "",
    });
    // setProdId({ value: "", label: "" });
  };
  const styleText = () => {
    if (txtForm == "Edit") {
      return "text-danger";
    }
  };
  const handleChange = (e) => {
    setDisProdukState({ ...disProdukState, [e.target.name]: e.target.value });
  };
  const handleChangeProdId = (data) => {
    disProdukState.product_id = data.value;
    setProdId({ label: data.label, value: data.value });
  };
  const handleChangeUserId = (data) => {
    disProdukState.user_id = data.value;
    setUserId({ label: data.label, value: data.value });
  };
  const handleChangeType = (data) => {
    disProdukState.type = data.value;
    setType({ label: data.label, value: data.value });
  };
  const optProdID = isDataProdId.map((d) => ({
    value: d.prod_id,
    label: d.prod_id + " - " + d.description.toUpperCase(),
  }));
  const optUserID = isDataUserId.map((d) => ({
    value: d.id,
    label: d.id + " - " + d.username,
  }));
  const optType = [
    { value: "promo", label: "Promo" },
    { value: "diskon", label: "Diskon" },
  ];
  if(isAuth === 401){
    return <Redirect to="/login" />;
  }
  return (
    <div>
      <>
        <main className="container-fluid pt-4 mt-5">
          <nav aria-label="breadcrumb my-2">
            <ol className="breadcrumb mb-4" type="none">
              <li className="breadcrumb-item">
                <a href="#">Admin</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Master</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Diskon Produk
              </li>
            </ol>
          </nav>
          <div className="row">
            <div className="col-md-12">
              <div className="card mt-2 rounded-2 border-white shadow">
                <div className="card-header bg-light">
                  <b>Data Diskon</b>
                  <button
                    className="btn btn-primary btn-flat btn-sm float-right"
                    onClick={() => showModal("Tambah")}
                  >
                    <FaPlus /> Data Diskon
                  </button>
                </div>
                <div className="card-body">
                  <div
                    className={"alert alert-" + displayAlert.type}
                    style={{ display: displayAlert.display }}
                    role="alert"
                  >
                    <strong>{displayAlert.message}</strong>
                  </div>

                  {!isReload || isLoading ? (
                    <Loading type="spinningBubbles" color="#91091e" />
                  ) : (
                    <>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text bg-light"
                            id="inputGroup-sizing-default"
                          >
                            <FaSearch />
                          </span>
                        </div>
                        <input
                          type="text"
                          name="keyword"
                          value={isSearchInput}
                          onChange={(e) => setSearchInput(e.target.value)}
                          className="form-control"
                          aria-describedby="inputGroup-sizing-default"
                          placeholder="masukkan filter..."
                        />
                      </div>
                      <ReactTable
                        data={isDataDisProduk}
                        // pageSize={this.state.dataDenom.length > 10 ? 10 : this.state.dataDenom.length}
                        defaultPageSize={isDataDisProduk.length >= 10 ? 10 : 5}
                        columns={columns}
                        className="mt-3 table-striped w-100"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
      <Modal
        show={isOpen.display}
        onHide={() => setIsOpen({ display: false })}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title className={styleText() + " h6"}>
            {isOpen.type} Diskon Produk
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  Code<sup className="text-danger">*</sup>
                </label>
                <input
                  type="text"
                  name="kode"
                  className="form-control border-secondary"
                  value={disProdukState.kode}
                  onChange={(e) => handleChange(e)}
                  placeholder=""
                  readOnly={txtForm=="Edit" ? "readonly" : "" }
                />
              </div>
              <div className="form-group">
                <label>
                  Type<sup className="text-danger">*</sup>
                </label>
                <Select
                  name="type"
                  //   isDisabled={txtForm == "Edit" ? true : false}
                  options={optType}
                  value={optType.filter(
                    (obj) => obj.value === disProdukState.type
                  )}
                  onChange={(value) => handleChangeType(value)}
                  placeholder={<div>- Select Type -</div>}
                />
              </div>
              <div className="form-group">
                <label>
                  Discount (%)<sup className="text-danger">*</sup>
                </label>
                <input
                  type="number"
                  name="diskon"
                  className="form-control border-secondary"
                  value={disProdukState.diskon}
                  onChange={(e) => handleChange(e)}
                  placeholder=""
                />
              </div>
              <div className="form-group">
                <label>
                  Description<sup className="text-danger">*</sup>
                </label>
                <input
                  type="text"
                  name="description"
                  className="form-control border-secondary"
                  value={disProdukState.description}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  Due Date<sup className="text-danger">*</sup>
                </label>
                <input
                  type="date"
                  name="due_date"
                  className="form-control border-secondary"
                  value={disProdukState.due_date}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="form-group">
                <label>
                  Max Quota<sup className="text-danger">*</sup>
                </label>
                <input
                  type="number"
                  name="pemakaian"
                  className="form-control border-secondary"
                  value={disProdukState.pemakaian}
                  onChange={(e) => handleChange(e)}
                />
              </div>

              <div className="form-group">
                <label>
                  Product<sup className="text-danger">*</sup>
                </label>
                <Select
                  name="product_id"
                  //   isDisabled={txtForm == "Edit" ? true : false}
                  options={optProdID}
                  value={optProdID.filter(
                    (obj) => obj.value === disProdukState.product_id
                  )}
                  onChange={(value) => handleChangeProdId(value)}
                  placeholder={<div>Pilih Produk</div>}
                />
              </div>
              <div
                className="form-group mt-4"
                style={{
                  display: disProdukState.type == "diskon" ? "block" : "none",
                }}
              >
                <label>
                  Username<sup className="text-danger">*</sup>
                </label>
                <Select
                  name="user_id"
                  //   isDisabled={txtForm == "Edit" ? true : false}
                  options={optUserID}
                  value={optUserID.filter(
                    (obj) => obj.value === disProdukState.user_id
                  )}
                  onChange={(value) => handleChangeUserId(value)}
                  placeholder={<div>Pilih User</div>}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setIsOpen({ display: false })}
            className="btn btn-outline-secondary"
          >
            Batal
          </button>
          <LaddaButton
            loading={isLoading}
            onClick={() => saveDiskon(isOpen.type)}
            className="btn btn-primary"
          >
            Simpan
          </LaddaButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DiskonProduk;
