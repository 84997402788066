import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import ReactLoading from "react-loading";
import { useParams } from 'react-router-dom';

function UpdateStatus(props) {
    const [loading,setLoading] = useState(false);
    const [data,setData] = useState([]);
    let identifier = useParams();

    const getData = async () => {
        setLoading(true);
        try {
            let response = await Axios.put(`${process.env.REACT_APP_URL}/users/updatestatus/${identifier.Id}`, {
                headers: {
                  Authorization: 'Bearer ' + process.env.REACT_APP_TOKEN
                }
               })
               console.log(response.data);
               setData(response.data);
               setLoading(false);
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(()=> {
      getData();
    },[identifier,setData])

    
    return (
        <div>
            <div className="container" style={{ maxWidth: "600px" }}>
              <div className="row">
                <div className="col-sm-12 col-lg-12 col-12 m-15px-tb ">
                  <div className="card border-radius-2 text-center m-auto py-3 shadow">


                    {
                        loading?
                        <div>
                            <ReactLoading type={"spin"} color={"#007bff"} height={"10%"} width={"10%"} className="mx-auto my-5"/>
                                <h6>Proses Verifikasi</h6>
                            <label htmlFor="loading">Tunggu sebentar untuk informasi akun anda</label>
                        </div>
                        :
                        <div>
                            {
                                data.status==200?
                                <div>
                                    <img src="https://www.flaticon.com/svg/static/icons/svg/992/992481.svg" alt="success_image" width="10%" className="img-fluid my-5"/>
                                        <h6>Berhasil Verifikasi akun </h6>
                                    <label htmlFor="loading">Selamat Memulai berbelanja di cuan payment</label>
                                    <br/>
                                    <a href="/login" className="btn btn border-primary text-primary my-5">Login acount</a>
                                </div>
                                :
                                <div>
                                    <img src="https://www.flaticon.com/svg/static/icons/svg/595/595067.svg" alt="success_image" width="10%" className="img-fluid my-5"/>
                                        <h6>Upss</h6>
                                    <label htmlFor="loading">Proses Verifikasi nampaknya terkendala</label>
                                </div>
                            }
                        </div>
                    }
                  </div>
                </div>
              </div>
            </div>
        </div>
    );
}

export default UpdateStatus;