import React, { useState, useEffect, useContext } from "react";
import ReactTable from "react-table";
import { AuthContext } from '../../../router/Router';
import ReactLoading from "react-loading";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import Moment from "react-moment";
import LaddaButton, { l, EXPAND_RIGHT } from "react-ladda";
import Select from "react-select";

function Mutasi_User(props){
    useEffect(() => {
        setIsLoading(true);
        isiTabel();
        setIsLoading(false);
        console.log("aa");
    },[]);
    const token = localStorage.getItem("token");
    const user_id = localStorage.getItem("user_id");
    const [isLoading, setIsLoading] = useState(false);
    const [isReload, setReload] = useState(false);
    const [isDataSaldo, setIsDataSaldo] = useState([]);
    const [dataTabel, setDataTabel] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filIdpel, setFilIdpel] = useState('');
    const [filStatus, setFilStatus] = useState('');
    const [filType, setFilType] = useState('');
    const options = [
        { value: 200, label: 'SUKSES' },
        { value: 400, label: 'SALDO TIDAK CUKUP' },
        { value: 404, label: 'STOK KOSONG' },
        { value: 500, label: 'OTORISASI GAGAL' },
        { value: 505, label: 'ERROR' },
        { value: 900, label: 'TRANSAKSI GANDA' },
        { value: 901, label: 'GAGAL' },
        { value: 902, label: 'SEDANG DIPROSES' },
        { value: 903, label: 'PRODUK GANGGUAN' },
        { value: 904, label: 'PESAN DIABAIKAN' },
        { value: 905, label: 'DIBATALKAN' },
        { value: 906, label: 'TUJUAN SALAH' },
        { value: 907, label: 'FORMAT SALAH' },
        { value: 908, label: 'PRODUK TIDAK TERSEDIA' },
        { value: 909, label: 'SYSTEM ERROR' },
        { value: 910, label: 'RAISER' },
        { value: 911, label: 'MENUNGGU JAWABAN' },
      ];
    const options2 = [
        { value: 'D', label: 'Deposit' },
        { value: 'K', label: 'Kredit' },
      ];
    const Loading = ({ type, color }) => (
        <ReactLoading
          type={type}
          color={color}
          className="text-center"
          height={"3%"}
          width={"3%"}
          className="d-flex justify-content-center"
        />
      );
    async function isiTabel() {
        fetch(process.env.REACT_APP_URL+'/mutations/report/user',{
          // fetch(process.env.REACT_APP_URL+'/mutations/report/user?start_date=2021-04-14&end_date=2021-04-23',{
          method: "GET",
          headers:{
            'Authorization': "Barier " + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        }).then((response)=>{
            response.json().then((result)=>{
                if(result.status=="200"){
                  setDataTabel(result.data);
                }else{
                  Swal.fire({
                    title: 'Error!',
                    text: result.message,
                    icon: 'error',
                    confirmButtonText: 'oke'
                  });
                }
            })
        })
        console.log("isi data : "+dataTabel);
      };
      async function Filter() {
        // fetch(process.env.REACT_APP_URL+'/mutations/report/user?start_date='+startDate+'?end_date='+endDate+'?user_id='+user_id+'?idpel='+filIdpel,{
        fetch(process.env.REACT_APP_URL+'/mutations/report/user?idpel='+filIdpel+'&start_date='+startDate+'&end_date='+endDate+'&status='+filStatus+'&type='+filType,{
          method: "GET",
          headers:{
            'Authorization': "Barier " + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        }).then((response)=>{
            response.json().then((result)=>{
                if(result.status=="200"){
                  setDataTabel(result.data);
                }else{
                  Swal.fire({
                    title: 'Error!',
                    text: result.message,
                    icon: 'error',
                    confirmButtonText: 'oke'
                  });
                }
            })
        })
        
      };
      const textInput = React.useRef();
      const textInput1 = React.useRef();
      const textInput2 = React.useRef();
      const textInput3 = React.useRef();
      const textInput4 = React.useRef();
      const clearInput = () => (textInput.current.value = "");
      function Reset(){
        textInput.current.value = "";
        textInput1.current.value = "";
        textInput2.current.value = "";
        textInput3.current.value = "";
        textInput4.current.value = "";
        document.querySelectorAll('idpel');
        setIsLoading(true);
        setFilIdpel('');
        setStartDate('');
        setEndDate('');
        setFilStatus('');
        setFilType('');
        isiTabel();
        setIsLoading(false);
      };
      async function Update(id){
        console.log(id);
        Swal.fire({
          title: 'Anda yakin akan melakukan transaki ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
        }).then((result)=>{
          if(result.isConfirmed){
            fetch(process.env.REACT_APP_URL+'/transactions/check/advice/'+id,{
              method: "POST",
              headers:{
                'Authorization': "Barier " + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
              },
            }).then((response)=>{
                response.json().then((result)=>{
                    if(result.status=="200"){
                      // setDataTabel(result.data);
                      Swal.fire({
                        title: 'SUCCESS!',
                        text: result.message,
                        icon: 'success',
                        confirmButtonText: 'oke'
                      });
                    }else{
                      Swal.fire({
                        title: 'Error!',
                        text: result.message,
                        icon: 'error',
                        confirmButtonText: 'oke'
                      });
                    }
                })
            })
            isiTabel();
          }else{

          }
        })
        
      }
    const columns = [
        {
          Header: "#",
          id: "no",
          maxWidth: 50,
          Cell: (row) => {
            return <div>{row.index + 1}</div>;
          },
          style: {
            textAlign: "center",
            textTransform: "uppercase",
            flexDirection: "column",
            justifyContent: "center",
          },
        },
        {
          Header: "TRX. ID",
          accessor: "trx_id",
          maxWidth: 150,
          style: {
            textAlign: "left",
            textTransform: "uppercase",
            flexDirection: "column",
            justifyContent: "center",
          },
          
        },
        {
          Header: "IDPEL",
          accessor: "idpel",
          minWidth: 150,
          style: {
            textAlign: "left",
            textTransform: "uppercase",
            flexDirection: "column",
            justifyContent: "center",
          },
          
        },
        {
          Header: "PRODUK",
          accessor: "product_name",
          maxWidth: 150,
          style: {
            textAlign: "left",
            textTransform: "capitalize",
            flexDirection: "column",
            justifyContent: "center",
          },
          
        },
        {
          Header: "STATUS",
          accessor: "status",
          maxWidth: 80,
          style: {
            textAlign: "left",
            textTransform: "capitalize",
            flexDirection: "column",
            justifyContent: "center",
          },
          Cell: (row) => (
            <div>
              {row.original.status == 200 ? (
                <span className="badge badge-success"
                >
                  SUKSES
                </span>
              ) : row.original.status == 400 ? (
                  <span className="badge badge-warning"
                >
                  SALDO TIDAK CUKUP
                </span>
              ) : row.original.status == 404 ? (
                <span className="badge badge-warning"
              >
                STOK KOSONG
              </span>
              ) : row.original.status == 500 ? (
                <span className="badge badge-warning"
              >
                OTORISASI GAGAL
              </span>
              ) : row.original.status == 505 ? (
                <span className="badge badge-danger"
              >
                ERROR
              </span>
              ) : row.original.status == 900 ? (
                <span className="badge badge-warning"
              >
                TRANSAKSI GANDA
              </span>
              ) : row.original.status == 901 ? (
                <span className="badge badge-danger"
              >
                GAGAL
              </span>
              ) : row.original.status == 902 ? (
                <span className="badge badge-warning"
              >
                SEDANG DIPROSES
              </span>
              ) : row.original.status == 903 ? (
                <span className="badge badge-warning"
              >
                PRODUK GANGGUAN
              </span>
              ) : row.original.status == 904 ? (
                <span className="badge badge-warning"
              >
                PESAN DIABAIKAN
              </span>
              ) : row.original.status == 905 ? (
                <span className="badge badge-danger"
              >
                DIBATALKAN
              </span>
              ) : row.original.status == 906 ? (
                <span className="badge badge-warning"
              >
                TUJUAN SALAH
              </span>
              ) : row.original.status == 907 ? (
                <span className="badge badge-warning"
              >
                FORMAT SALAH
              </span>
              ) : row.original.status == 908 ? (
                <span className="badge badge-warning"
              >
                PRODUK TIDAK TERSEDIA
              </span>
              ) : row.original.status == 909 ? (
                <span className="badge badge-danger"
              >
                SYSTEM ERROR
              </span>
              ) : row.original.status == 910 ? (
                <span className="badge badge-warning"
              >
                RAISER
              </span>
              ) : row.original.status == 911 ? (
                <span className="badge badge-warning"
              >
                MENUNGGU JAWABAN
              </span>
              ) : (
                  <span className="badge badge-warning"
                >
                  PENDING
                </span>
              )}
            </div>
          ),
        },
        {
          Header: "TOTAL",
          accessor: "amount",
          minWidth: 100,
          style: {
            textAlign: "left",
            // textTransform: "uppercase",
            flexDirection: "column",
            justifyContent: "center",
          },
          Cell: props => new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR',minimumFractionDigits: 0  }).format(props.value)
        },
        {
          Header: "SALDO",
          accessor: "balance_after",
          minWidth: 150,
          style: {
            textAlign: "left",
            // textTransform: "uppercase",
            flexDirection: "column",
            justifyContent: "center",
          },
          Cell: props => new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR',minimumFractionDigits: 0  }).format(props.value)
        },
        {
          Header: "TIPE",
          accessor: "type",
          maxWidth: 85,
          style: {
            textAlign: "left",
            textTransform: "capitalize",
            flexDirection: "column",
            justifyContent: "center",
          },
          Cell: (row) => (
            <div>
              {row.original.type == "D" ? (
                <span
                  // className="badge badge-success"
                  // style={{
                  //   fontSize: "14px",
                  //   letterSpacing: "1px",
                  // }}
                >
                  Deposit
                </span>
              ) : row.original.type == "K" ? (
                  <span
                  // className="badge badge-danger"
                  // style={{
                  //   fontSize: "14px",
                  //   letterSpacing: "1px",
                  // }}
                >
                  Kredit
                </span>
              ) : (
                  <span
                  // className="badge badge-info"
                  // style={{
                  //   fontSize: "14px",
                  //   letterSpacing: "1px",
                  // }}
                >
                  Pending
                </span>
              )}
            </div>
          ),
        },
        {
          Header: "DESKRIPSI",
          accessor: "description",
          minWidth: 150,
          style: {
            textAlign: "center",
            textTransform: "capitalize",
            flexDirection: "column",
            justifyContent: "center",
          },
          // Cell: ( row ) => <div className="text-wrap">{row}</div>
        },
        {
          Header: "TRX. DATE",
          accessor: "created_at",
          minWidth: 120,
          style: {
            textAlign: "center",
            flexDirection: "column",
            justifyContent: "center",
          },
          Cell: (row) => (
            <div>
              <Moment format="DD MMM YYYY H:m:s">{row.original.created_at}</Moment>
              {/* <Moment format="DD MMM YYYY - HH:mm:ss">{row.original.created_at}</Moment> */}
            </div>
          ),
        },
      ];
    return (
        <div>
            <main className="container-fluid pt-4 mt-5">
                <nav aria-label="breadcrumb my-2">
                <ol className="breadcrumb mb-4" type="none">
                    <li className="breadcrumb-item">
                    <a href="#">Mitra</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Laporan Mutasi
                    </li>
                </ol>
                </nav>

                <div className="row">
                    <div className="col-md-12">
                        <div className="card mt-2 rounded-2 border-white shadow">
                            <div className="card-header bg-light">
                                <b>Laporan Mutasi</b>
                            </div>
                            <div className="card-body">
                                <div
                                // className={"alert alert-" + displayAlert.type}
                                // style={{ display: displayAlert.display }}
                                role="alert"
                                >
                                {/* <strong>{displayAlert.message}</strong> */}
                                </div>
                                { isLoading ? (
                                <Loading type="spinningBubbles" color="#91091e" />
                                ) : (
                                <>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="mb-1 col-md-2">
                                                <span>Idpel</span>
                                                <input 
                                                    className="form-control form-control-sm"
                                                    type="text"
                                                    name="idpel"
                                                    placeholder="Enter IDPEL"
                                                    onChange={(e) => setFilIdpel(e.target.value)}
                                                    value={filIdpel}
                                                    ref={textInput}
                                                />
                                            </div>
                                            <div className="mb-1 col-md-2">
                                                <span>Tgl. Awal</span>
                                                <input 
                                                    className="form-control form-control-sm"
                                                    type="date"
                                                    name="idpel"
                                                    placeholder="Start Date"
                                                    onChange={(e) => setStartDate(e.target.value)}
                                                    value={startDate}
                                                    ref={textInput1}
                                                />
                                            </div>
                                            <div className="mb-1 col-md-2">
                                                <span>Tgl. Akhir</span>
                                                <input 
                                                    className="form-control form-control-sm"
                                                    type="date"
                                                    name="idpel"
                                                    placeholder="End Date"
                                                    onChange={(e) => setEndDate(e.target.value)}
                                                    value={endDate}
                                                    ref={textInput2}
                                                />
                                            </div>
                                            <div className="mb-1 col-md-2">
                                                <span>Status</span>
                                                <Select
                                                    placeholder="Sukses|Gagal|Pending"
                                                    options={options}
                                                    onChange={(e) => setFilStatus(e.value)}
                                                    // value={filStatus}
                                                    ref={textInput3}
                                                />
                                            </div>
                                            <div className="mb-1 col-md-2">
                                                <span>Tipe</span>
                                                <Select
                                                    placeholder="Deposit|Kredit"
                                                    options={options2}
                                                    onChange={(e) => setFilType(e.value)}
                                                    // value={filType}
                                                    ref={textInput4}
                                                />
                                            </div>
                                            <div className="mb-1 col-md-1 d-flex align-items-end">
                                                <LaddaButton
                                                    loading={isLoading}
                                                    className="btn btn-primary m-btn-radius w-100"
                                                    data-color="#008aff"
                                                    data-size='s'
                                                    onClick={() => Filter()}
                                                >FILTER</LaddaButton>
                                            </div>
                                            <div className="mb-1 col-md-1 d-flex align-items-end">
                                                <LaddaButton
                                                loading={isLoading}
                                                className="btn btn-danger m-btn-radius w-100"
                                                data-color="#008aff"
                                                data-size='s'
                                                onClick={Reset}
                                            >RESET</LaddaButton>
                                            </div>
                                        </div>
                                    </div>
                                    <ReactTable
                                    // defaultSorted={[{ id: "tanggal", desc: true }]}
                                    data={dataTabel}
                                    defaultPageSize= {5}
                                    columns={columns}
                                    className="-striped -highlight -responsive"
                                    scrollX={true}
                                    // style={{
                                    //   height: "400px"
                                    // }}
                                    />
                                    {console.log("data"+dataTabel)}
                                </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
};
export default Mutasi_User;