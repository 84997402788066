import React, { useState, useEffect, useContext } from "react";
import ReactTable from "react-table";
import { AuthContext } from '../../../router/Router';
import ReactLoading from "react-loading";
import { Redirect } from 'react-router-dom';
import {FaSyncAlt,FaPlus,FaSearch,FaFileInvoice} from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import Moment from "react-moment";
import Select from "react-select";
import LaddaButton, { l, EXPAND_RIGHT } from "react-ladda";
import axios from "axios";

function Topup(props) {
  const { state } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [laddaReload, setLaddaReload] = useState(false);
  const [isOpen, setIsOpen] = useState({ display: false, type: "" });
  const [toogleBtn, setToogleBtn] = useState(true);
  const [displayInput, setDisplayInput] = useState("none");
  const [isStatusRole, setStatusRole] = useState({ value: "", label: "" });
  const [displayAlert, setDisplayAlert] = useState({
    display: "none",
    type: "",
    message: "",
  });
  const [saldoState, setSaldoState] = useState({
    id: 0,
    nominal: "",
    status: "",
    tanggal: "",
    metode: "",
    biaya_admin: 5000,
  });
  const [isEmail, setEmail] = useState("");
  const [isSearchInput, setSearchInput] = useState("");
  const [isReload, setReload] = useState(false);
  const [isDataSaldo, setIsDataSaldo] = useState([]);
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const topupInit = [
    {
      nominal_display: "Rp. 50.000",
      nominal: 50000,
    },
    {
      nominal_display: "Rp. 100.000",
      nominal: 100000,
    },
    {
      nominal_display: "Rp. 150.000",
      nominal: 150000,
    },
    {
      nominal_display: "Rp. 200.000",
      nominal: 200000,
    },
    {
      nominal_display: "Rp. 250.000",
      nominal: 250000,
    },
    {
      nominal_display: "Rp. 300.000",
      nominal: 300000,
    },
    {
      nominal_display: "Rp. 500.000",
      nominal: 500000,
    },
    {
      nominal_display: "Jumlah Lain",
      nominal: null,
    },
  ];
  const [isAuth, setAuth] = useState(0);
  const Loading = ({ type, color }) => (
    <ReactLoading
      type={type}
      color={color}
      className="text-center"
      height={"3%"}
      width={"3%"}
      className="d-flex justify-content-center"
    />
  );
  useEffect(() => {
    setIsLoading(true);
    fetchSaldo();
    setIsLoading(false);
  }, [isSearchInput]);
  const fetchSaldo = async () => {
    await setIsLoading(true);
    let searchInput = await isSearchInput;
    await axios
      .get(`${process.env.REACT_APP_URL}/topup/saldo/user/history`, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.warn("res topup", res.data.data);
        if (res.data.status == 200) {
          if (searchInput == "") {
            setIsDataSaldo([...res.data.data]);
          } else {
            let filteredData = res.data.data.filter((value) => {
              if (value.payment_method == null) {
                value.payment_method = "";
              }
              if (value.bank == null) {
                value.bank = "";
              }
              return (
                value.nominal_topup
                  .toString()
                  .toLowerCase()
                  .includes(searchInput.toString().toLowerCase()) ||
                value.date
                  .toString()
                  .toLowerCase()
                  .includes(searchInput.toString().toLowerCase()) ||
                value.payment_method
                  .toString()
                  .toLowerCase()
                  .includes(searchInput.toString().toLowerCase()) ||
                value.bank
                  .toString()
                  .toLowerCase()
                  .includes(searchInput.toString().toLowerCase()) ||
                value.status
                  .toString()
                  .toLowerCase()
                  .includes(searchInput.toString().toLowerCase())
              );
            });
            setIsDataSaldo([...filteredData]);
          }
        } else {
          Swal.fire({
            title: "Gagal Memuat Saldo",
            text: "Silahkan coba sesaat lagi",
            icon: "info",
            confirmButtonText: "oke",
          });
        }
        setReload(true);
      }).catch((err) => {
        console.log(err.response.data)
        Swal.fire({
          title: "Uppss..",
          text: err.response.data.message,
          icon: "error",
          confirmButtonText: "Oke",
        });
        setAuth(err.response.data.status)
      });
    await setIsLoading(false);
  };
  const columns = [
    {
      Header: "#",
      id: "no",
      maxWidth: 50,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
      style: {
        textAlign: "center",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "NOMINAL",
      accessor: "nominal_topup",
      style: {
        textAlign: "left",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (props) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(props.value - saldoState.biaya_admin),
    },
    {
      Header: "TANGGAL TOPUP",
      id: "tanggal",
      accessor: "date",
      style: {
        textAlign: "center",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (row) => (
        <div>
          <Moment format="DD MMM YYYY - HH:mm:ss">{row.original.date}</Moment>
        </div>
      ),
    },
    {
      Header: "TIPE BAYAR",
      accessor: "payment_method",
      style: {
        textAlign: "left",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (row) => (
        <div>
          {row.original.payment_method != null &&
          row.original.payment_method != "" ? (
            row.original.payment_method.toString().replace("_", " ") +
            " - " +
            row.original.bank
          ) : (
            <>-</>
          )}
        </div>
      ),
    },
    {
      Header: "BIAYA ADMIN",
      maxWidth: "100",
      Cell: () => {
        return <div>Rp. {rupiahFormat(saldoState.biaya_admin)}</div>;
      },
      style: {
        textAlign: "center",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "STATUS BAYAR",
      maxWidth: "150",
      accessor: "status",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (row) => statusPaid(row.original.status),
    },
    {
      Header: "STATUS APPROVE",
      maxWidth:"180",
      accessor: "approve_status",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (row) => statusApprove(row.original.approve_status),
    },
    {
      Header: "",
      id: "struk",
      accessor: "invoice_url",
      maxWidth: "150",
      style: {
        textAlign: "center",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (row) => cekBayar(row.original),
    },
  ];

  const statusPaid = (status) => {
    if (status == "PAID") {
      return (
        <span
          className="badge badge-success"
          style={{
            fontSize: "12px",
            letterSpacing: "1px",
          }}
        >
          {status}
        </span>
      );
    } else if (status == "EXPIRED") {
      return (
        <span
          className="badge badge-danger"
          style={{
            fontSize: "12px",
            letterSpacing: "1px",
          }}
        >
          {status}
        </span>
      );
    } else {
      return (
        <span
          className="badge badge-warning"
          style={{
            fontSize: "12px",
            letterSpacing: "1px",
          }}
        >
          {status}
        </span>
      );
    }
  };
  const statusApprove = (approve) => {
    if (approve == 1) {
      return (
        <span
          className="badge badge-success"
          style={{
            fontSize: "12px",
            letterSpacing: "1px",
          }}
        >
          APPROVE
        </span>
      );
    } else if (approve == 0) {
      return (
        <span
          className="badge badge-danger"
          style={{
            fontSize: "12px",
            letterSpacing: "1px",
          }}
        >
          REJECTED
        </span>
      );
    } else {
      return (
        <span
          className="badge badge-warning"
          style={{
            fontSize: "12px",
            letterSpacing: "1px",
          }}
        >
          WAITING
        </span>
      );
    }
  };
  const cekBayar = (data) => {
    if (data.payment_method != null && data.payment_method != "") {
      return (
        <div>
          {/* {data.payment_method.toString().replace("_", " ") + " - " + data.bank} */}
          <button
            className="btn btn-outline-info btn-sm w-100"
            onClick={() => window.open(data.invoice_url, "_blank")}
          >
            <FaFileInvoice /> Cetak Struk
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <LaddaButton
            loading={isLoading}
            onClick={() => window.open(data.invoice_url, "_blank")}
            data-color="#008aff"
            data-size={l}
            data-style={EXPAND_RIGHT}
            className="btn btn-primary btn-sm w-100 border text-white shadow"
          >
            Bayar Sekarang
          </LaddaButton>
        </div>
      );
    }
  };
  const showModal = (type) => {
    resetForm();
    setToogleBtn(true);

    setIsOpen({ display: true, type: type });
  };
  const closeModal = () => {
    setIsOpen({ display: false });
    setLaddaReload(false);
  }
  const handleChange = (e) => {
    setSaldoState({
      ...saldoState,
      [e.target.name]: parseInt(e.target.value) + saldoState.biaya_admin,
    });
  };
  const setNominal = (value) => {
    if (value.nominal != null) {
      setSaldoState({
        ...saldoState,
        ["nominal"]: parseInt(value.nominal) + saldoState.biaya_admin,
      });
      // console.warn("nominal", saldoState.nominal);
      setDisplayInput("none");
    } else {
      setSaldoState({ ...saldoState, ["nominal"]: "" });
      setDisplayInput("block");
    }
  };
  const saveSaldo = async () => {
    await setLaddaReload(true);
    let error = false;
    let messageError = "";
    if (Number.parseInt(saldoState.nominal, 10) <= 10000) {
      error = true;
      messageError = "Nominal topup harus lebih dari 10.000";
    }
    if (saldoState.nominal == "") {
      error = true;
      messageError = "Nominal topup belum dipilih";
    }
    if (error) {
      Swal.fire({
        title: "Upss..",
        text: messageError,
        icon: "error",
        confirmButtonText: "Tutup",
      });
      await setLaddaReload(false);
    } else {
      await createSaldo();
      await setIsOpen({ display: false, type: "" });
      await fetchSaldo();
    }
  };
  const createSaldo = async () => {
    await fetchDetailUser()
    await axios
      .post(
        `${process.env.REACT_APP_URL}/topup/saldo/user/create`,
        {
          user_id: state.user_id,
          nominal_topup: saldoState.nominal,
          email: isEmail,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((json) => {
        console.warn("res create saldo", json);
        if (json.data.status == 200) {
          window.open(json.data.data.invoice_url, "_blank");
          // Swal.fire({
          //   title: "Berhasil",
          //   text: "Request saldo sudah dibuat. Menunggu verifikasi dari admin",
          //   icon: "success",
          //   confirmButtonText: "Tutup",
          // });
          setLaddaReload(false);
          fetchSaldo();
        } else if(json.data.status == 402){
        Swal.fire({
            title: "Gagal",
            text: json.data.message,
            icon: "info",
            confirmButtonText: "Tutup",
          });
          setLaddaReload(false);
        }else{
          var errors = " ";
          for (var i = 0; i < json.data.message.length; i++) {
            errors += json.data.message[i].msg + ", ";
          }
          showAlert("block", "danger", "Request topup saldo gagal. " + errors);
          console.warn("error", json.data.message);
          setLaddaReload(false);
        }
      }).catch((err) => {
        // console.log(err.response.data.message);
        Swal.fire({
          title: "Gagal",
          text: err.response.data.message,
          icon: "info",
          confirmButtonText: "Tutup",
        });
        setLaddaReload(false);
      });
  };

  const showAlert = (display, type, message) => {
    setDisplayAlert({
      display: display,
      type: type,
      message: message,
    });

    const timeId = setTimeout(() => {
      setDisplayAlert({ display: "none" });
    }, 5000);
    return () => {
      clearTimeout(timeId);
    };
  };
  const resetForm = () => {
    setSaldoState({
      id: 0,
      nominal: "",
      status: "",
      tanggal: "",
      metode: "",
      biaya_admin: 5000,
    });
    // setProdId({ value: "", label: "" });
    setToogleBtn(true);
  };
  const rupiahFormat = (num) => {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  };
  const fetchDetailUser = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL}/users/`+user_id, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.status == 200) {
          // console.warn("res user", res.data.data[0]);
          setEmail(res.data.data[0].email);
          // console.warn("email user", res.data.data[0].email);
        } else {
          Swal.fire({
            title: "Gagal Mengambil Detail User",
            text: "Silahkan coba sesaat lagi",
            icon: "info",
            confirmButtonText: "oke",
          });
        }
      });
  };
  if(isAuth === 401){
    return <Redirect to="/login" />;
  }
  return (
    <div>
      <main className="container-fluid pt-4 mt-5">
        <nav aria-label="breadcrumb my-2">
          <ol className="breadcrumb mb-4" type="none">
            <li className="breadcrumb-item">
              <a href="#">Mitra</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Topup Saldo
            </li>
          </ol>
        </nav>

        <div className="row">
          <div className="col-md-12">
            <div className="card mt-2 rounded-2 border-white shadow">
              <div className="card-header bg-light">
                <b>Riwayat Topup Saldo</b>
                <button
                  className="btn btn-outline-success btn-flat float-right ml-3"
                  onClick={() => fetchSaldo()}
                >
                  <FaSyncAlt /> Perbarui
                </button>
                <button
                  className="btn btn-primary btn-flat float-right"
                  onClick={() => showModal("Tambah")}
                >
                  <FaPlus /> Topup Saldo
                </button>
              </div>
              <div className="card-body">
                <div
                  className={"alert alert-" + displayAlert.type}
                  style={{ display: displayAlert.display }}
                  role="alert"
                >
                  <strong>{displayAlert.message}</strong>
                </div>
                {!isReload || isLoading ? (
                  <Loading type="spinningBubbles" color="#91091e" />
                ) : (
                  <>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text bg-light"
                          id="inputGroup-sizing-default"
                        >
                          <FaSearch />
                        </span>
                      </div>
                      <input
                        type="text"
                        name="keyword"
                        value={isSearchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        className="form-control"
                        aria-describedby="inputGroup-sizing-default"
                        placeholder="masukkan filter berdasarkan nominal topup, tanggal, metode pembayaran atau status bayar"
                      />
                    </div>
                    <ReactTable
                      defaultSorted={[{ id: "tanggal", desc: true }]}
                      data={isDataSaldo}
                      defaultPageSize={isDataSaldo.length >= 10 ? 10 : 5}
                      columns={columns}
                      className="mt-3 table-striped w-100"
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>

      <Modal
        show={isOpen.display}
        onHide={() => setIsOpen({ display: false })}
        size="lg"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>Topup Saldo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>
                  Nominal Topup <sup className="text-danger">*</sup>
                </label>
                <div className="row mb-2">
                  {topupInit.map((value, index) => {
                    return (
                      <div
                        className="col-sm-4 col-md-4 col-lg-3 mt-2 value.nominal"
                        key={index}
                        onClick={(e) => {
                          setNominal(value);
                        }}
                      >
                        <div
                          className="border rounded"
                          role="group"
                          style={{ backgroundColor: "#F9F9F9" }}
                        >
                          <button
                            type="button"
                            className="btn text-center w-100"
                          >
                            {" "}
                            <b
                              style={{ fontSize: "14px", display: "block" }}
                              className="pt-2 pb-2"
                            >
                              {value.nominal_display}
                            </b>
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <input
                  type="number"
                  name="nominal"
                  className="form-control mt-3 border border-secondary"
                  value={
                    saldoState.nominal === null ||
                    saldoState.nominal === "" ||
                    saldoState.nominal < 0
                      ? 0
                      : saldoState.nominal - saldoState.biaya_admin
                  }
                  onChange={(e) => handleChange(e)}
                  placeholder="Nominal topup"
                  style={{ display: displayInput }}
                />
                <small
                  className="text-danger"
                  style={{ display: displayInput }}
                >
                  Nominal topup harus lebih dari 10.000
                </small>
              </div>
              <hr />
              <div className="col-md-4 float-right">
                <span className="font-weight-bold">Biaya Admin</span>
                <span className="float-right">
                  Rp.
                  {rupiahFormat(saldoState.biaya_admin)}
                </span>
              </div>
              <br />
              <div className="col-md-4 float-right">
                <span className="font-weight-bold text-danger">Total</span>
                <span className="font-weight-bold float-right text-danger">
                  Rp.
                  {saldoState.nominal === null ||
                  saldoState.nominal === "" ||
                  saldoState.nominal === 0
                    ? 0
                    : rupiahFormat(parseInt(saldoState.nominal))}
                </span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => closeModal()}
            className="btn btn-outline-secondary"
          >
            Batal
          </button>
          <LaddaButton
            loading={laddaReload}
            onClick={() => saveSaldo()}
            data-color="#008aff"
            data-size={l}
            data-style={EXPAND_RIGHT}
            className="btn btn-primary btn-md border font-weight-bold text-white shadow"
          >
            Pilih Pembayaran
          </LaddaButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Topup;
