import React from 'react';
import NumberFormat from 'react-number-format';


export const PDAMInq = (props) => (
<>
<tr className="table-borderless">
    <td>ID PELANGGAN</td>
    <td className="text-right">{props.struk.idpel}</td>
</tr>
<tr>
    <td>NAMA</td>
    <td className="text-right">{props.struk.name}</td>
</tr>

<tr>
    <td>PERIODE</td>
    <td className="text-right">{props.struk.blth.substr(4,2)}/{props.struk.blth.substr(0,4)} - {props.struk.blth.substr(10,2)}/{props.struk.blth.substr(6,4)}</td>
</tr>

<tr>
    <td>RP TAG</td>
    <td className="text-right"><NumberFormat value={parseInt(props.struk.rp_tag)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr>

<tr>
    <td>ADMIN BANK</td>
    <td className="text-right"><NumberFormat value={parseInt(props.struk.admin_charge)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr>
</>
);