import React,{Component, useState } from 'react';
import ReactTable from "react-table"; 
import 'react-table/react-table.css'
import ReactLoading from 'react-loading';
import LaddaButton, { l, EXPAND_RIGHT } from 'react-ladda';
import Swal from 'sweetalert2'
import Select from 'react-select';
import Modal from "react-bootstrap/Modal";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

const Loading = ({ type, color }) => (
    <ReactLoading type={type} color={color} height={'10%'} width={'10%'} />
);

class ManTopupSaldo extends Component{
  constructor(props){
    super(props)
    this.state = {
      users: [],
      loading:false,
      login:false,
      token:localStorage.getItem('token'),
      store:null,
      value: '62',
      size:0,
      listquotes: [],
      quotes: null,
      quote_id_edit: null,
      isEdit: false,
      produkId: [],
      produkCat: [],
      prodId: null,
      prodCat: null,
      valId: null,
      ValCat: null,
      isLoading: false,
      toogleBtn: false,
      product_category_id: null,
      name: null,
      code: null,
      tipe_prod: null,
      description: null,
      id: null,
      gangguan: null,
      kosong: null,
      active: null,
      status: null,
      readMode: true,
      filterId: '',
      filterVal: null,
      sampel: [
        { prod_id: 1, username: "Siji", nominal: 2000000, status: "pending" },
        { prod_id: 2, username: "Two", nominal: 1500000, status: "paid" },
        { prod_id: 3, username: "San", nominal: 60000000, status: "expirate" },
        { prod_id: 4, username: "Empat", nominal: 50000, status: "paid" },
      ],
      sdate: '',
      edate: '',
    };
  }

  showModal = (type, data) => {
    if (type == "Approve") {
      this.setState({
        id: data.id,
        // product_category_id:data.product_category_id,
        // name: data.name,
        // code: data.code,
        // description: data.description,
        // active: data.active,
        // readMode: false,
      });
      console.log("data : "+data.id)
      
    } else if(type == "Create"){
      this.setState({
        id: null,
        product_category_id:null,
        name: "",
        code: "",
        description: "",
        active: "",
        readMode: false,
      });
    }else{
      this.setState({
        id: data.id,
        product_category_id:data.product_category_id,
        name: data.name,
        code: data.code,
        description: data.description,
        active: data.active,
        readMode: true,
      });
      if (data.active == 1) {
        this.setState({ toogleBtn: true });
      } else {
        this.setState({ toogleBtn: false });
      }
    }
    // this.setState({ setIsOpen: true, typeModal: type });
    // console.warn("status : ",this.state.status);
    this.update();
  };

  hideModal = () => {
    this.setState({ setIsOpen: false });
  };

  handleChange = (e) => {
    // console.warn([e.target.name], e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  
  setToogleBtn(checked){
    if(checked == true){
      this.setState({active: 1});
    }else{
      this.setState({active: 0});
    }
    // console.log("active : "+this.state.active);
  }

  handleChangeFilter = (value) => {
    this.setState({filterVal: value});
    console.log(this.state.filterVal)
    let fil_id = value.value;
    console.log(fil_id);
    this.setState({filterId: fil_id});
  };
  

  update(id) {
      // Swal.fire({
      //     title: 'Yakin akan menyimpannya?',
      //     showCancelButton: true,
      //     confirmButtonText: 'SIMPAN',
      // }).then((result) => {
      //     if(result.isConfirmed){
        // if(this.state.description.length >=5){
          // console.log("id : "+id)
            fetch(process.env.REACT_APP_URL + '/topup/saldo/admin/updates/' +id, {
                  method: "put",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Barier " + this.state.token,
                    Accept: "application/json",
                  },
                  body: JSON.stringify({
                    approve_status:true
                  })
                })
                .then((response) =>{
                    response.json().then((result)=>{
                        if(result.status=="200"){
                            this.getproduct();
                            this.setState({isEdit:false,setIsOpen: false});
                            Swal.fire({
                                title: 'Sukses Disimpan',
                                text: result.data.desc,
                                icon: 'success',
                                confirmButtonText: 'oke'
                            });
                        }
                        else{
                          this.setState({ message: result.message });
                          Swal.fire({
                            title: 'Upss!',
                            text: result.message,
                            icon: 'error',
                            confirmButtonText: 'oke'
                          });
                        }
                    })
                });
              // }else{
              //   this.setState({ message: "Description harus diisi minimal 5 Digit" });
              //   Swal.fire({
              //     title: 'Upss!',
              //     text: "Description harus diisi minimal 5 Digit",
              //     icon: 'error',
              //     confirmButtonText: 'oke'
              //   });
              // }
      //     }
      // })
  }

  create(id) {
    // Swal.fire({
    //     title: 'Yakin akan menyimpannya?',
    //     showCancelButton: true,
    //     confirmButtonText: 'SIMPAN',
    // }).then((result) => {
    //     if(result.isConfirmed){
      // if(this.state.description.length >=5){
          fetch(process.env.REACT_APP_URL + '/topup/saldo/admin/updates/' +id, {
                method: "put",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Barier " + this.state.token,
                  Accept: "application/json",
                },
                body: JSON.stringify({
                  approve_status:false
                })
              })
              .then((response) =>{
                  response.json().then((result)=>{
                      if(result.status=="200"){
                          this.getproduct();
                          this.getproductId();
                          this.setState({isEdit:false,setIsOpen: false});
                          Swal.fire({
                              title: 'Sukses Disimpan',
                              text: result.data.desc,
                              icon: 'success',
                              confirmButtonText: 'oke'
                          });
                      }
                      else{
                        this.setState({ message: result.message });
                        Swal.fire({
                          title: 'Upss!',
                          text: result.message,
                          icon: 'error',
                          confirmButtonText: 'oke'
                        });
                      }
                  })
              });
            // }else{
            //   this.setState({ message: "Description harus diisi minimal 5 Digit" });
            //   Swal.fire({
            //     title: 'Upss!',
            //     text: "Description harus diisi minimal 5 Digit",
            //     icon: 'error',
            //     confirmButtonText: 'oke'
            //   });
            // }
    //     }
    // })
  }

  confirmDelete(value){
    Swal.fire({
      title: 'Yakin akan Menghapus?',
      text: 'Product Id : '+value.product_category_id+' | NAME : '+value.name,
      showCancelButton: true,
      confirmButtonText: 'DELETE',
    }).then((result) => {
        if(result.isConfirmed){
          fetch(process.env.REACT_APP_URL + '/product/categories/delete/' + value.product_category_id, {
            method: "delete",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Barier " + this.state.token,
              Accept: "application/json",
            },
          })
          .then((response) =>{
              response.json().then((result)=>{
                  if(result.status=="200"){
                      this.getproduct();
                      this.getproductId();
                      this.setState({isEdit:false,setIsOpen: false});
                      Swal.fire({
                          title: 'Sukses Dihapus',
                          text: result.data.desc,
                          icon: 'success',
                          confirmButtonText: 'oke'
                      });
                  }
                  else{
                    this.setState({ message: result.message });
                    Swal.fire({
                      title: 'Upss!',
                      text: result.message,
                      icon: 'error',
                      confirmButtonText: 'oke'
                    });
                  }
              })
          });
        }
      })
  }

  componentDidMount(){
    // this.getproduct();
    // this.getproductId();
  }

  getproduct()
  {
      this.setState({loading:true})
      fetch(process.env.REACT_APP_URL+'/topup/saldo/admin/list',{
          method: "get",
          headers:{
            Authorization: "Barier " + this.state.token,
          }
      }).then((response)=>{
          response.json().then((result)=>{
            //   console.warn("result",result.data.length);
              this.setState({loading:false, users: result.data,size:result.data.length})
              console.warn(this.state.users);
          })
      })
  }
  
  getproductId()
  {
      fetch(process.env.REACT_APP_URL+'/product/categories',{
          method: "GET",
          headers:{
            "Content-Type": "application/json",
            Authorization: "Barier " + this.state.token,
            Accept: "application/json",
          }
      }).then((response)=>{
          response.json().then((result)=>{
              this.setState({
                produkId: result.data
              })
          })
      })
  }

  getfilter()
  {
      this.setState({loading:true})
      console.log("sdate  : "+this.state.sdate)
      console.log("edate  : "+this.state.edate)
      console.log("status  : "+this.state.filterId)
      if(this.state.sdate != '' && this.state.edate == ''){
        this.setState({ message: "End Date harus diisi, jika Start Date diisi", loading:false });
        Swal.fire({
          title: 'Upss!',
          text: "End Date harus diisi, jika Start Date diisi",
          icon: 'error',
          confirmButtonText: 'oke'
        });
      }else if(this.state.sdate == '' && this.state.edate != ''){
        this.setState({ message: "Start Date harus diisi, jika End Date diisi", loading:false });
        Swal.fire({
          title: 'Upss!',
          text: "Start Date harus diisi, jika End Date diisi",
          icon: 'error',
          confirmButtonText: 'oke'
        });
      }else{
        fetch(process.env.REACT_APP_URL+'/topup/saldo/admin/list?start_date='+this.state.sdate+'&end_date='+this.state.edate+'&status='+this.state.filterId,{
            method: "GET",
            headers:{
              "Content-Type": "application/json",
              Authorization: "Barier " + this.state.token,
              Accept: "application/json",
            }
        }).then((response)=>{
            response.json().then((result)=>{
                if(result.status=="200"){
                  this.setState({
                    loading:false, users: result.data,size:result.data.length
                  })
                }else{
                  this.setState({ message: result.message, loading:false });
                  Swal.fire({
                    title: 'Upss!',
                    text: result.message,
                    icon: 'error',
                    confirmButtonText: 'oke'
                  });
                }
            })
        })
      }
  }
  
  reset(){
    this.getproduct();
    this.getproductId();
    this.setState({ filterVal: null, filterId: '', sdate: '', edate: ''});
  }

 render(){
    const columns = [
      {
        Header: "#",
        maxWidth: 50,
        Cell: (row) => {
          return <div>{row.index + 1}</div>;
        },
        style: {
          textAlign: "center",
          textTransform: "uppercase",
          flexDirection: "column",
          justifyContent: "center",
        },
      },
      {
        Header: "USERNAME",
        accessor: "name",
        // maxWidth: 180,
        style: {
          textAlign: "left",
          textTransform: "uppercase",
          flexDirection: "column",
          justifyContent: "center",
        },
      },
      {
        Header: "NOMINAL TOPUP",
        accessor: "nominal_topup",
        // maxWidth: 100,
        style: {
          textAlign: "right",
          flexDirection: "column",
          justifyContent: "center",
        },
        Cell: props => new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR',minimumFractionDigits: 0  }).format(props.value)
      },
      {
        Header: "PAYMENT STATUS",
        accessor: "status",
        // maxWidth: 130,
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
        Cell: (row) => (
          <div>
            {row.original.status == "PAID" ? (
              <span
                className="badge badge-success"
                style={{
                  fontSize: "14px",
                  letterSpacing: "1px",
                }}
              >
                PAID
              </span>
            ) : row.original.status == "EXPIRED" ? (
                <span
                className="badge badge-danger"
                style={{
                  fontSize: "14px",
                  letterSpacing: "1px",
                }}
              >
                EXPIRATE
              </span>
            ) : (
                <span
                className="badge badge-info"
                style={{
                  fontSize: "14px",
                  letterSpacing: "1px",
                }}
              >
                PENDING
              </span>
            )}
          </div>
        ),
      },

      {
        Header: "APPROVAL STATUS",
        accessor: "id",
        maxWidth: 180,
        style: {
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        },
        filterable: false,
        Cell: (row) => (
          <div>
          {row.original.status == "PAID" && row.original.approve_status == null ? (
            <button
              className="btn btn-flat btn-sm btn-primary"
              onClick={() => {
                  this.update(row.original.id);
                }}
            >
              AWAITING APPROVAL
            </button>
            ) : row.original.status == "PAID" && row.original.approve_status == 1 ? (
                <button
              className="btn btn-flat btn-sm btn-success"
              // onClick={() => {
              //     this.create(row.original.id);
              //   }}
              disabled
            >
              APPROVED
            </button>
            ) : row.original.status == "EXPIRED" && row.original.approve_status == null ? (
                <button
              className="btn btn-flat btn-sm btn-warning"
              onClick={() => {
                  this.create(row.original.id);
                }}
            >
              REJECT
            </button>
            ) : row.original.status == "EXPIRED" && row.original.approve_status == 0 ? (
                <button
              className="btn btn-flat btn-sm btn-danger"
              // onClick={() => {
              //     this.create(row.original.id);
              //   }}
              disabled
            >
              REJECTED
            </button>
            ) : (
                <button
              className="btn btn-flat btn-sm btn-info"
            //   onClick={() => this.showModal("Approve", row.original)}
            disabled
            >
              WAITING
            </button>
            )}
          </div>
        ),
      },
    ];

    const filter = this.state.produkId.map(d=>({
      "value" : d.product_category_id,
      "label" : d.product_category_id+" - "+d.name
    }));
    const statusx = [
        {"value": "PAID" , "label" : "PAID"},
        {"value": "PENDING" , "label" : "PENDING"},
        {"value": "EXPIRED" , "label" : "EXPIRED"},
      ];
   return (
    <>
    <div>
        <main className="container-fluid pt-4 mt-5">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb" type="none">
              <li class="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Setting
              </li>
            </ol>
          </nav>

          <div className="card mt-2 rounded-2 border-white shadow mx-auto ">
            <div className="card-header text-dark bg-light">
              <b>Detail Profile</b>
            </div>
            <div className="card-body">
              <div className="row mt-3">
                <div className="col-md-3">
                  USERNAME
                </div>
                <div className="col-md-6">
                  <input 
                    type="text"
                    value={this.state.edate}
                    className="form-control form-control-sm"
                    onChange={(event) => this.setState({edate: event.target.value})}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-3">
                  FULLNAME
                </div>
                <div className="col-md-6">
                  <input 
                    type="text"
                    value={this.state.edate}
                    className="form-control form-control-sm"
                    onChange={(event) => this.setState({edate: event.target.value})}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-3">
                  E-MAIL
                </div>
                <div className="col-md-6">
                  <input 
                    type="text"
                    value={this.state.edate}
                    className="form-control form-control-sm"
                    onChange={(event) => this.setState({edate: event.target.value})}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-3">
                  PHONE
                </div>
                <div className="col-md-6">
                  <input 
                    type="text"
                    value={this.state.edate}
                    className="form-control form-control-sm"
                    onChange={(event) => this.setState({edate: event.target.value})}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-3">
                  
                </div>
                <div className="col-md-1">
                  <input 
                    type="checkbox"
                    value={this.state.edate}
                    className="form-control form-control-sm"
                    onChange={(event) => this.setState({edate: event.target.value})}
                  />
                </div>
                <div className="col-md-2">
                  Update Password
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-3">
                  OLD PASSWORD
                </div>
                <div className="col-md-6">
                  <input 
                    type="text"
                    value={this.state.edate}
                    className="form-control form-control-sm"
                    onChange={(event) => this.setState({edate: event.target.value})}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-3">
                  NEW PASSWORD
                </div>
                <div className="col-md-6">
                  <input 
                    type="text"
                    value={this.state.edate}
                    className="form-control form-control-sm"
                    onChange={(event) => this.setState({edate: event.target.value})}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-3">
                  <LaddaButton
                    loading={this.state.loading}
                    className="btn btn-primary m-btn-radius w-100"
                    data-color="#008aff"
                    data-size={l}
                    onClick={() => this.getfilter()}
                  >SUBMIT</LaddaButton>
                </div>
                <div className="col-md-3">
                  <LaddaButton
                    loading={this.state.loading}
                    className="btn btn-danger m-btn-radius w-100"
                    data-color="#008aff"
                    data-size={l}
                    onClick={() => this.reset()}
                  >BACK</LaddaButton>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <Modal show={this.state.setIsOpen} onHide={() => this.hideModal()}>
          <Modal.Header>
            <Modal.Title>{this.state.typeModal} Product Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">
                Product ID <sup className="text-danger">*</sup>
              </label>
              <div className="col-sm-8">
              {this.state.typeModal == "Create" ? (
                <input
                  name="product_category_id"
                  value={this.state.product_category_id}
                  onChange={this.handleChange}
                  className="form-control"
                  readOnly={this.state.readMode}
                />
              ):(
                <input
                  name="product_category_id"
                  value={this.state.product_category_id}
                  onChange={this.handleChange}
                  className="form-control"
                  readOnly
                />
              )}   
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">
                CODE <sup className="text-danger">*</sup>
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  name="code"
                  id="code"
                  className="form-control"
                  required=""
                  value={this.state.code}
                  onChange={this.handleChange}
                  readOnly={this.state.readMode}
                />
              </div>
            </div>
            <div className="form-group row">
            <label className="col-sm-4 col-form-label">
                NAME <sup className="text-danger">*</sup>
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-control"
                  required=""
                  value={this.state.name}
                  onChange={this.handleChange}
                  readOnly={this.state.readMode}
                />
              </div>
            </div>
            <div className="form-group row">
            <label className="col-sm-4 col-form-label">
                DESCRIPTION <sup className="text-danger">*</sup>
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  name="description"
                  id="description"
                  className="form-control"
                  required="required"
                  value={this.state.description}
                  onChange={this.handleChange}
                  readOnly={this.state.readMode}
                />
              </div>
            </div>
            <div className="form-group row">
            <label className="col-sm-4 col-form-label">
                ACTIVE <sup className="text-danger">*</sup>
              </label>
              <div className="col-sm-8">
              <BootstrapSwitchButton
                disabled={this.state.readMode}
                checked={this.state.toogleBtn}
                onlabel="Aktif"
                offlabel="NonAktif"
                onstyle="success"
                offstyle="danger"
                width={105}
                // onChange={(checked: boolean) => {
                  // this.setState({active: checked});
                //   this.setToogleBtn(checked);
                // }}
              />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => this.hideModal()}
              className="btn btn-outline-secondary"
            >
              Close
            </button>
            {this.state.readMode == false ? (
              this.state.typeModal == "Edit" ? (
              <LaddaButton
                loading={this.state.loading}
                onClick={() => {
                  this.update(this.state.typeModal);
                }}
                className="btn btn-primary"
              >
                Simpan
              </LaddaButton>
              ):(
                <LaddaButton
                loading={this.state.loading}
                onClick={() => {
                  this.create(this.state.typeModal);
                }}
                className="btn btn-primary"
              >
                Simpan
              </LaddaButton>
              )
            ) : (
              ""
            )}
          </Modal.Footer>
        </Modal>
    </>
   )
 } 
}

export default ManTopupSaldo;