import React, { useReducer, createContext, useEffect } from "react";
import Router from "./router/Router";
import { hot } from "react-hot-loader";
import Axios from "axios";
import Manintenance from "./components/front/Manintenance";

function App() {
  return (
    <div>
      {process.env.REACT_APP_MAINTENANCE == "on" ? (
        <Manintenance />
      ) : (
        <Router />
      )}
    </div>
  );
}
export default App;
