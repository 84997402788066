import React from 'react';
import NumberFormat from 'react-number-format';


export const PlnPostpaidInq = (props) => (
<>
<tr className="table-borderless">
    <td>ID PELANGGAN</td>
    <td className="text-right">{props.struk.subscriber_id}</td>
</tr>
<tr>
    <td>NAMA</td>
    <td className="text-right">{props.struk.subscriber_name}</td>
</tr>

<tr>
    <td>TARIF/DAYA</td>
    <td className="text-right">{props.struk.subscriber_segmentation}/{props.struk.power}</td>
</tr>

<tr>
    <td>BL/TH</td>
    <td className="text-right">{props.struk.blth_summary}</td>
</tr>

<tr>
    <td>RP TAG</td>
    <td className="text-right"><NumberFormat value={props.struk.amount} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr>

<tr>
    <td>ADMIN BANK</td>
    <td className="text-right"><NumberFormat value={props.struk.admin_charge} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr>

{/* <tr>
    <td>TOTAL TAGIHAN</td>
    <td className="text-right"><NumberFormat value={parseInt(props.struk.amount)+parseInt(props.struk.admin_charge)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr> */}
</>
);