import React, { Component,useRef, useEffect } from 'react';
import "./pln_token.css";

class pln_token extends Component{
 render(){
   var token = [
            {nominal:'20.000', harga:'21.500'},
            {nominal:'50.000', harga:'51.500'},
            {nominal:'100.000', harga:'101.500'},
            {nominal:'150.000', harga:'151.500'},
            {nominal:'200.000', harga:'201.500'},
            {nominal:'300.000', harga:'301.500'},
            {nominal:'400.000', harga:'401.500'},
            {nominal:'500.000', harga:'501.500'},
            {nominal:'1.000.000', harga:'1.001.500'}
           ];


   return (
    <div>
    <hr></hr>
    <h6 className="text-black">Pilih Nominal Token</h6>


    <div className="row">
      <div className="tab-style-5">
            <div className="row justify-content-center m-35px-b">
                <div className="col-lg-12">
                    <ul className="nav nav-fill nav-tabs row m-2">
                        {token.map((value, index) => {
                            return(
                            <li className="nav-item"  key={index} style={{width:'150px',flex:'0 0 auto'}}  onClick={(e) => {this.setNominal(value.nominal)}}>
                              <a href="#" data-toggle="tab" className="nav-link" style={{width:'150px'}}>
                                <span>{value.nominal}</span><br></br>
                                <span className="text-lg">Rp. {value.harga}</span>
                              </a>
                            </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
  </div>
  </div>
   )
 } 
}

export default pln_token;