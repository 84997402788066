import React, { useState, useEffect, useContext } from "react";
import ReactTable from "react-table";
import { AuthContext } from '../../../router/Router';
import ReactLoading from "react-loading";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Swal from "sweetalert2";
import { Redirect } from 'react-router-dom';
import { FaTrash, FaEdit, FaSearch } from "react-icons/fa";
import axios from "axios";
import Select from "react-select";

function Denom(props) {
  const { state } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isReload, setReload] = useState(false);
  const [denomState, setDenomState] = useState({
    id: 0,
    product_id:0,
    denom: "",
    description: "",
    active: 1,
  });
  const [isUpdate, setUpdate] = useState(0);
  const [isSearchInput, setSearchInput] = useState("");
  const [toogleBtn, setToogleBtn] = useState(true);
  const [txtForm, setTxtForm] = useState("Tambah");
  const [displayAlert, setDisplayAlert] = useState({
    display: "none",
    type: "",
    message: "",
  });
  const [isAuth, setAuth] = useState(0);
  const [isDataDenom, setIsDataDenom] = useState([]);
  const [isDataProdId, setIsDataProdId] = useState([]);
  const [isProdId, setProdId] = useState({ value: "", label: "" });
  const Loading = ({ type, color }) => (
    <ReactLoading
      type={type}
      color={color}
      className="text-center"
      height={"4%"}
      width={"4%"}
      className="d-flex justify-content-center"
    />
  );
  const columns = [
    {
      Header: "#",
      maxWidth: 50,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
      style: {
        textAlign: "center",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "PRODUK",
      accessor: "name",
      style: {
        textAlign: "left",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "DENOM",
      accessor: "denom",
      style: {
        textAlign: "left",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (props) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(props.value),
    },

    {
      Header: "DESKRIPSI",
      accessor: "description",
      style: {
        textAlign: "left",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "STATUS",
      accessor: "active",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (row) => (
        <div>
          {row.original.active == 1 ? (
            <span
              className="badge badge-success"
              style={{
                fontSize: "12px",
                letterSpacing: "1px",
              }}
            >
              Aktif
            </span>
          ) : (
            <span
              className="badge badge-danger"
              style={{
                fontSize: "12px",
                letterSpacing: "1px",
              }}
            >
              Nonaktif
            </span>
          )}
        </div>
      ),
    },
    {
      Header: "AKSI",
      accessor: "id",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
      filterable: false,
      Cell: (row) => (
        <div>
          <button
            className="btn btn-warning btn-flat btn-sm"
            onClick={() => setValueForm("Edit", row.original)}
            title="Edit"
          >
            <FaEdit className="text-white text-center" />
          </button>
          &nbsp;
          <button
            onClick={() => confirmDelete(row.original.id)}
            className="btn btn-danger btn-flat btn-sm"
            title="Hapus"
          >
            <FaTrash className="text-white text-center" />
          </button>
        </div>
      ),
    },
  ];
  const token = localStorage.getItem("token");
  useEffect( () => {
   
    setIsLoading(true);
    fetchDenom();
    fetchProdId();
    setIsLoading(false);
  }, [isSearchInput]);

  const filterDataDenom = async () => {
    await fetchDenom()
    let searchInput = await isSearchInput;
    let data = await isDataDenom;

    let filteredData = await data.filter((value) => {
      return (
        value.name.toString().toLowerCase().includes(searchInput.toString().toLowerCase()) ||
        value.denom.toString().toLowerCase().includes(searchInput.toString().toLowerCase()) ||
        value.description.toString().toLowerCase().includes(searchInput.toString().toLowerCase())
      );
    });
    // console.warn("filtered data",filteredData);
    console.warn("search input",searchInput);
    await setIsDataDenom([...filteredData]);
  };
  const fetchDenom = async () => {
    let searchInput = await isSearchInput;
    await axios
      .get(`${process.env.REACT_APP_URL}/prepaid/denom/list`, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if(searchInput == ""){
          setIsDataDenom([...res.data.data]);
        }else{
          let filteredData = res.data.data.filter((value) => {
          return (
              value.name.toString().toLowerCase().includes(searchInput.toString().toLowerCase()) ||
              value.denom.toString().toLowerCase().includes(searchInput.toString().toLowerCase()) ||
              value.description.toString().toLowerCase().includes(searchInput.toString().toLowerCase())
            );
          });
          setIsDataDenom([...filteredData]);
        }
       
       console.warn("res denom", res.data.data);
       setReload(true);
      }).catch((err) => {
        console.log(err.response.data)
        Swal.fire({
          title: "Uppss..",
          text: err.response.data.message,
          icon: "error",
          confirmButtonText: "Oke",
        });
        setAuth(err.response.data.status)
      })
  };
  const fetchProdId = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL}/products/42`, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setIsDataProdId([...res.data.data]);
        console.warn("res prod id", res.data.data);
      });
  };
  const confirmDelete = (iddenom) => {
    Swal.fire({
      title: "Hapus Data ?",
      text: "",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Hapus",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.value) {
        deleteDenom(iddenom);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //cencle
      }
    });
  };

  const setValueForm = (type, data) => {
    if (type == "Edit") {
      console.warn("row origin", data);
      setTxtForm("Edit");
      setDenomState({
        id: data.id,
        product_id:data.product_id,
        denom: data.denom,
        description: data.description,
        active: data.active,
      });
      if (data.active == 1) {
        setToogleBtn(true);
      } else {
        setToogleBtn(false);
      }
      
      
    } else {
      setTxtForm("Tambah");
      resetForm();
      setToogleBtn(true);
    }
  };
  const saveDenom = async (typemodal) => {
    if (denomState.denom == "" || denomState.description == "") {
      Swal.fire({
        title: "Uppss..",
        text: "Lengkapi dahulu input yang kosong atau belum dipilih",
        icon: "error",
        confirmButtonText: "Tutup",
      });
    } else {
      if (typemodal == "Tambah") {
        await createDenom();
      } else {
        await updateDenom();
      }
      setTxtForm("Tambah");
      await resetForm();
      await fetchDenom();
    }
  };
  const handleChange = (e) => {
    setDenomState({ ...denomState, [e.target.name]: e.target.value });
    // console.warn("denomState", denomState);
  };
  const handleChangeSearch = async (e) => {
    setSearchInput(e.target.value);

    if (e.target.value == "") {
      fetchDenom();
    } else {
      filterDataDenom();
    }
  };
  const createDenom = async () => {
    setIsLoading(true);
    console.warn("denomState",denomState);
    await axios
      .post(
        `${process.env.REACT_APP_URL}/prepaid/denom/create`,
        {
          product_id: denomState.product_id,
          denom: denomState.denom,
          description: denomState.description,
          active: denomState.active,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((json) => {
        if (json.data.status == 200) {
         showAlert("block", "success", "Data denom berhasil ditambah");
        } else {
          showAlert("block", "danger", "Data denom gagal ditambah");
        }
        // this.setState({ loading: false, setIsOpen: false });
      });
    // setUpdate(isDataDenom.length);
    setIsLoading(false);
  };
  const updateDenom = async () => {
    setIsLoading(true);
    await axios
      .put(
        `${process.env.REACT_APP_URL}/prepaid/denom/update/` + denomState.id,
        {
          product_id: denomState.product_id,
          denom: denomState.denom,
          description: denomState.description,
          active: denomState.active,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((json) => {
        if (json.data.status == 200) {
         showAlert("block", "success", "Data denom berhasil diedit");
         
        } else {
         showAlert("block", "success", "Data denom gagal diedit");
        }
      });
    setIsLoading(false);
  };
  const deleteDenom = async (iddenom) => {
    setIsLoading(true);
    await axios
      .delete(
        `${process.env.REACT_APP_URL}/prepaid/denom/delete/` + iddenom,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((json) => {
        if (json.data.status == 200) {
          fetchDenom();
          // setIsDataDenom({
          //   isDataDenom: isDataDenom.filter(
          //     (isDataDenom) => isDataDenom.id !== iddenom
          //   ),
          // });
           showAlert("block", "success", "Data denom berhasil dihapus");
        } else {
           showAlert("block", "danger", "Data denom berhasil dihapus");
        }
      });
      resetForm();
    setIsLoading(false);
  };
  const showAlert = (display, type, message) => {
    setDisplayAlert({
      display: display,
      type: type,
      message: message,
    });

    const timeId = setTimeout(() => {
      setDisplayAlert({ display: "none" });
    }, 3000);
    return () => {
      clearTimeout(timeId);
    };
  };
  const optProdID = isDataProdId.map((d) => ({
    value: d.prod_id,
    label: d.prod_id + " - " + d.description.toUpperCase(),
  }));
  const handleChangeProdId = (data) => {
    denomState.product_id = data.value;
    setProdId({ label: data.label, value: data.value });
  };
  const resetForm = () => {
    setDenomState({
      id: 0,
      product_id: 0,
      denom: "",
      description: "",
      active: 1,
    });
    setProdId({ value: "", label: "" });
    setToogleBtn(true);
  }
  const styleText = () => {
    if (txtForm == "Edit") {
      return "text-danger";
    }
  };
  if(isAuth === 401){
    return <Redirect to="/login" />;
  }
  return (
    <div>
      <>
        <main className="container-fluid pt-4 mt-5">
          <nav aria-label="breadcrumb my-2">
            <ol className="breadcrumb mb-4" type="none">
              <li className="breadcrumb-item">
                <a href="#">Admin</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Setting</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Denom
              </li>
            </ol>
          </nav>
          <div className="row">
            <div className="col-md-8">
              <div className="card mt-2 rounded-2 border-white shadow">
                <div className="card-header bg-light">
                  <b>Data Denom</b>
                </div>
                <div className="card-body">
                  <div
                    className={"alert alert-" + displayAlert.type}
                    style={{ display: displayAlert.display }}
                    role="alert"
                  >
                    <strong>{displayAlert.message}</strong>
                  </div>

                  {!isReload || isLoading ? (
                    <Loading type="spinningBubbles" color="#91091e" />
                  ) : (
                    <>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text bg-light"
                            id="inputGroup-sizing-default"
                          >
                            <FaSearch />
                          </span>
                        </div>
                        <input
                          type="text"
                          name="keyword"
                          value={isSearchInput}
                          onChange={(e) => setSearchInput(e.target.value)}
                          className="form-control"
                          aria-describedby="inputGroup-sizing-default"
                          placeholder="masukkan filter berdasarkan produk, denom atau deskripsi"
                        />
                      </div>

                      <ReactTable
                        data={isDataDenom}
                        // pageSize={this.state.dataDenom.length > 10 ? 10 : this.state.dataDenom.length}
                        defaultPageSize={isDataDenom.length >= 10 ? 10 : 5}
                        columns={columns}
                        className="mt-3 table-striped w-100"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mt-2 rounded-2 border-white shadow">
                <div className={"card-header bg-light " + styleText()}>
                  <b>{txtForm} Denom</b>
                </div>
                <div className="card-body">
                  <form id="formDenom" method="post">
                    <div className="form-group">
                      <label>
                        Produk<sup className="text-danger">*</sup>
                      </label>
                      <Select
                        name="product_id"
                        options={optProdID}
                        value={optProdID.filter(
                          (obj) => obj.value === denomState.product_id
                        )}
                        onChange={(value) => handleChangeProdId(value)}
                        placeholder={<div>Pilih Produk</div>}
                      />
                    </div>
                    <div className="form-group">
                      <label>Nominal Denom</label>
                      <input
                        type="number"
                        name="denom"
                        className="form-control"
                        value={denomState.denom}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label>Deskripsi</label>
                      <input
                        type="text"
                        name="description"
                        className="form-control"
                        value={denomState.description}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label>Status</label>
                      <br />
                      <BootstrapSwitchButton
                        checked={toogleBtn}
                        onlabel="Aktif"
                        offlabel="NonAktif"
                        onstyle="success"
                        offstyle="danger"
                        width={105}
                        onChange={(checked: boolean) => {
                          setToogleBtn(checked);
                          {
                            toogleBtn
                              ? (denomState.active = 0)
                              : (denomState.active = 1);
                          }
                        }}
                      />
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-flat mr-2"
                    onClick={() => setValueForm("Batal", null)}
                  >
                    Batal
                  </button>

                  <button
                    type="button"
                    form="formDenom"
                    onClick={() => saveDenom(txtForm)}
                    className="btn btn-primary btn-flat"
                  >
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    </div>
  );
}

export default Denom;
