import React from "react";
import NumberFormat from "react-number-format";
export const PulsaData = (props) => {
  if (props.struk.code == "0000") {
    return (
      <>
        {/* <tr className="table-borderless">
          <td colSpan="3" className="text-muted">
            DETAIL PEMBAYARAN {props.product_type}
          </td>
        </tr> */}
        <tr>
          <td>PRODUK</td>
          <td className="text-right"><div style={{ wordWrap: "break-word" }}>{props.struk.desc}</div></td>
        </tr>
        <tr>
          <td>NOMOR HP</td>
          <td className="text-right">{props.struk.msisdn}</td>
        </tr>
        <tr>
          <td>SN / NO REF</td>
          <td className="text-right"><div style={{ wordWrap: "break-word" }}>{props.struk.sn}</div></td>
        </tr>
        <tr>
          <td>HARGA</td>
          <td className="text-right"><NumberFormat value={parseFloat(props.struk.price)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>
        <tr>
          <td>ADM BANK</td>
          <td className="text-right"><NumberFormat value={parseFloat(props.transaction.admin_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>
        <tr>
          <td>BIAYA TRANSAKSI</td>
          <td className="text-right"><NumberFormat value={parseFloat(props.transaction.handling_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>
        <tr>
          <td className="font-weight-bold h6">SUBTOTAL</td>
          {props.discount.code ? (
            <>
              <td className="font-weight-bold h6 text-right"><NumberFormat value={parseFloat(props.transaction.amount) + parseFloat(props.discount.admin_fee_before_discount) - parseFloat(props.discount.admin_fee_after_discount)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
            </>
          ) : (<>
            <td className="font-weight-bold h6 text-right"><NumberFormat value={parseFloat(props.transaction.amount)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
          </>)}
        </tr>
        <tr>
          {props.discount.code ? (
            <>
              <td className="font-weight-bold">PROMO BIAYA ADMIN <br /><span className="text-success">( {props.discount.code}  {props.discount.discount}% ) </span></td>
              <td className="font-weight-bold h6 text-right align-middle"><NumberFormat value={parseFloat(props.discount.admin_fee_before_discount) - parseFloat(props.discount.admin_fee_after_discount)} displayType={'text'} thousandSeparator={true} prefix={'- Rp. '} renderText={value => <div className="text-success">{value}</div>} /></td>
            </>

          ) : (<>
            <td className="font-weight-bold">PROMO BIAYA ADMIN</td>
            <td className="font-weight-bold h6 text-right align-middle"><NumberFormat value={"0"} displayType={'text'} thousandSeparator={true} prefix={'- Rp. '} renderText={value => <div className="text-success">{value}</div>} /></td>
          </>)}
        </tr>
        <tr>
          <td className="font-weight-bold h6">TOTAL</td>
          <td className="font-weight-bold h6 text-right"><NumberFormat value={parseFloat(props.transaction.amount)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>
      </>
    );
  } else {
    return (
      <>
        <tr className="table-borderless">
          <td colSpan="2" className="text-muted">
            {props.struk.desc}
          </td>
        </tr>
      </>
    );
  }
};
