import React, { useState, useEffect, useContext } from "react";
import ReactTable from "react-table";
import { AuthContext } from '../../../router/Router';
import ReactLoading from "react-loading";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import Moment from "react-moment";
import LaddaButton, { l, EXPAND_RIGHT } from "react-ladda";
import Select from "react-select";

function ReportTransactionUser(props){
    useEffect(() => {
        setIsLoading(true);
        isiTabel();
        setIsLoading(false);
        console.log("aa");
    },[]);
    const token = localStorage.getItem("token");
    const user_id = localStorage.getItem("user_id");
    const [isLoading, setIsLoading] = useState(false);
    const [isReload, setReload] = useState(false);
    const [isDataSaldo, setIsDataSaldo] = useState([]);
    const [dataTabel, setDataTabel] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filIdpel, setFilIdpel] = useState('');
    const [filStatus, setFilStatus] = useState('');
    const [filType, setFilType] = useState('');
    const options = [
      { value: 200, label: 'SUKSES' },
      { value: 400, label: 'SALDO TIDAK CUKUP' },
      { value: 404, label: 'STOK KOSONG' },
      { value: 500, label: 'OTORISASI GAGAL' },
      { value: 505, label: 'ERROR' },
      { value: 900, label: 'TRANSAKSI GANDA' },
      { value: 901, label: 'GAGAL' },
      { value: 902, label: 'SEDANG DIPROSES' },
      { value: 903, label: 'PRODUK GANGGUAN' },
      { value: 904, label: 'PESAN DIABAIKAN' },
      { value: 905, label: 'DIBATALKAN' },
      { value: 906, label: 'TUJUAN SALAH' },
      { value: 907, label: 'FORMAT SALAH' },
      { value: 908, label: 'PRODUK TIDAK TERSEDIA' },
      { value: 909, label: 'SYSTEM ERROR' },
      { value: 910, label: 'RAISER' },
      { value: 911, label: 'MENUNGGU JAWABAN' },
    ];
    const options2 = [
        { value: 2100, label: 'Inquiry' },
        { value: 2200, label: 'Payment' },
      ];
    const Loading = ({ type, color }) => (
        <ReactLoading
          type={type}
          color={color}
          className="text-center"
          height={"3%"}
          width={"3%"}
          className="d-flex justify-content-center"
        />
      );
    async function isiTabel() {
        fetch(process.env.REACT_APP_URL+'/transactions/report/users',{
        // fetch(process.env.REACT_APP_URL+'/transactions/report/users?start_date=2021-02-01&end_date=2021-04-23',{
          method: "GET",
          headers:{
            'Authorization': "Barier " + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        }).then((response)=>{
            response.json().then((result)=>{
                if(result.status=="200"){
                  setDataTabel(result.data);
                }else{
                  Swal.fire({
                    title: 'Error!',
                    text: result.message,
                    icon: 'error',
                    confirmButtonText: 'oke'
                  });
                }
            })
        })
        console.log("isi data : "+dataTabel);
      };
      async function Filter() {
        fetch(process.env.REACT_APP_URL+'/transactions/check/advice/list?idpel='+filIdpel+'&start_date='+startDate+'&end_date='+endDate+'&status='+filStatus+'&type='+filType,{
        // fetch(process.env.REACT_APP_URL+'/transactions/refound/user/list?start_date='+startDate+'&end_date='+endDate,{
          method: "GET",
          headers:{
            'Authorization': "Barier " + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        }).then((response)=>{
            response.json().then((result)=>{
                if(result.status=="200"){
                  setDataTabel(result.data);
                }else{
                  Swal.fire({
                    title: 'Error!',
                    text: result.message,
                    icon: 'error',
                    confirmButtonText: 'oke'
                  });
                }
            })
        })
        
      };
      const textInput = React.useRef();
      const textInput1 = React.useRef();
      const textInput2 = React.useRef();
      const clearInput = () => (textInput.current.value = "");
      function Reset(){
        textInput.current.value = "";
        textInput1.current.value = "";
        textInput2.current.value = "";
        document.querySelectorAll('idpel');
        setIsLoading(true);
        setFilIdpel('');
        setStartDate('');
        setEndDate('');
        setFilStatus('');
        setFilType('');
        isiTabel();
        setIsLoading(false);
      };
      async function Update(id){
        console.log(id);
        Swal.fire({
          title: 'Anda yakin akan melakukan transaki ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
        }).then((result)=>{
          if(result.isConfirmed){
            fetch(process.env.REACT_APP_URL+'/transactions/check/advice/'+id,{
              method: "POST",
              headers:{
                'Authorization': "Barier " + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
              },
            }).then((response)=>{
                response.json().then((result)=>{
                    if(result.status=="200"){
                      // setDataTabel(result.data);
                      Swal.fire({
                        title: 'SUCCESS!',
                        text: result.message,
                        icon: 'success',
                        confirmButtonText: 'oke'
                      });
                    }else{
                      Swal.fire({
                        title: 'Error!',
                        text: result.message,
                        icon: 'error',
                        confirmButtonText: 'oke'
                      });
                    }
                })
            })
            isiTabel();
          }else{

          }
        })
        
      }
    const columns = [
        {
          Header: "#",
          id: "no",
          maxWidth: 50,
          Cell: (row) => {
            return <div>{row.index + 1}</div>;
          },
          style: {
            textAlign: "center",
            textTransform: "uppercase",
            flexDirection: "column",
            justifyContent: "center",
          },
        },
        {
          Header: "TIPE",
          accessor: "transaction_type",
          minWidth: 120,
          style: {
            textAlign: "center",
            textTransform: "capitalize",
            flexDirection: "column",
            justifyContent: "center",
          },
          Cell: (row) => (
            <div>
              {row.original.transaction_type == 2100 ? (
                <span className="badge badge-primary"
                >
                  INQUIRY
                </span>
              ) : row.original.transaction_type == 2200 ? (
                  <span className="badge badge-success"
                >
                  PAYMENT
                </span>
              ) : (
                  <span className="badge badge-danger"
                >
                  PENDING
                </span>
              )}
            </div>
          ),
        },
        {
          Header: "TRX. ID",
          accessor: "trx_id",
          style: {
            textAlign: "left",
            textTransform: "uppercase",
            flexDirection: "column",
            justifyContent: "center",
          },
          
        },
        {
          Header: "IDPEL",
          accessor: "idpel",
          minWidth: 120,
          style: {
            textAlign: "left",
            textTransform: "uppercase",
            flexDirection: "column",
            justifyContent: "center",
          },
          
        },
        {
          Header: "PRODUK",
          accessor: "product_name",
          minWidth: 120,
          style: {
            textAlign: "left",
            textTransform: "capitalize",
            flexDirection: "column",
            justifyContent: "center",
          },
          
        },
        {
          Header: "TOTAL",
          accessor: "tagihan_amount",
          style: {
            textAlign: "left",
            // textTransform: "uppercase",
            flexDirection: "column",
            justifyContent: "center",
          },
          Cell: props => new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR',minimumFractionDigits: 0  }).format(props.value)
        },
        {
          Header: "ADMIN",
          accessor: "default_admin",
          style: {
            textAlign: "left",
            // textTransform: "uppercase",
            flexDirection: "column",
            justifyContent: "center",
          },
          Cell: props => new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR',minimumFractionDigits: 0  }).format(props.value)
        },
        {
          Header: "ADD. FEE",
          accessor: "additional_admin",
          minWidth: 120,
          style: {
            textAlign: "left",
            // textTransform: "uppercase",
            flexDirection: "column",
            justifyContent: "center",
          },
          Cell: props => new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR',minimumFractionDigits: 0  }).format(props.value)
        },
        {
          Header: "DESKRIPSI",
          accessor: "tagihan_description",
          minWidth: 120,
          style: {
            textAlign: "left",
            textTransform: "capitalize",
            flexDirection: "column",
            justifyContent: "center",
          },
          
        },
        {
          Header: "TRX. DATE",
          accessor: "created_at",
          minWidth: 120,
          style: {
            textAlign: "center",
            flexDirection: "column",
            justifyContent: "center",
          },
          Cell: (row) => (
            <div>
              <Moment format="DD MMM YYYY H:m:s">{row.original.transaction_date}</Moment>
            </div>
          ),
        },
      ];
    return (
        <div>
            <main className="container-fluid pt-4 mt-5">
                <nav aria-label="breadcrumb my-2">
                <ol className="breadcrumb mb-4" type="none">
                    <li className="breadcrumb-item">
                    <a href="#">Mitra</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Laporan Transaksi
                    </li>
                </ol>
                </nav>

                <div className="row">
                    <div className="col-md-12">
                        <div className="card mt-2 rounded-2 border-white shadow">
                            <div className="card-header bg-light">
                                <b>Laporan Transaksi</b>
                            </div>
                            <div className="card-body">
                                <div
                                // className={"alert alert-" + displayAlert.type}
                                // style={{ display: displayAlert.display }}
                                role="alert"
                                >
                                {/* <strong>{displayAlert.message}</strong> */}
                                </div>
                                { isLoading ? (
                                <Loading type="spinningBubbles" color="#91091e" />
                                ) : (
                                <>
                                    <div className="form-group">
                                        <div className="row">
                                          <div className="mb-1 col-md-2">
                                              <span>Tipe</span>
                                              <Select
                                                  placeholder="Inquiry|Payment"
                                                  options={options2}
                                                  onChange={(e) => setFilType(e.value)}
                                              />
                                          </div>
                                          <div className="mb-1 col-md-2">
                                              <span>Idpel</span>
                                              <input 
                                                  className="form-control form-control-sm"
                                                  type="text"
                                                  name="idpel"
                                                  placeholder="Masukkan idpel"
                                                  onChange={(e) => setFilIdpel(e.target.value)}
                                                  value={filIdpel}
                                                  ref={textInput}
                                              />
                                          </div>
                                          <div className="mb-1 col-md-2">
                                              <span>Tgl. Awal</span>
                                              <input 
                                                  className="form-control form-control-sm"
                                                  type="date"
                                                  name="idpel"
                                                  placeholder="Start Date"
                                                  onChange={(e) => setStartDate(e.target.value)}
                                                  value={startDate}
                                                  ref={textInput1}
                                              />
                                          </div>
                                          <div className="mb-1 col-md-2">
                                              <span>Tgl. Akhir</span>
                                              <input 
                                                  className="form-control form-control-sm"
                                                  type="date"
                                                  name="idpel"
                                                  placeholder="End Date"
                                                  onChange={(e) => setEndDate(e.target.value)}
                                                  value={endDate}
                                                  ref={textInput2}
                                              />
                                          </div>
                                          <div className="mb-1 col-md-2">
                                              <span>Status</span>
                                              <Select
                                                  placeholder="Sukses|Gagal|Pending"
                                                  options={options}
                                                  onChange={(e) => setFilStatus(e.value)}
                                              />
                                          </div>
                                          
                                          <div className="mb-1 col-md-1 d-flex align-items-end">
                                              <LaddaButton
                                                  loading={isLoading}
                                                  className="btn btn-primary m-btn-radius w-100"
                                                  data-color="#008aff"
                                                  data-size='s'
                                                  onClick={() => Filter()}
                                              >FILTER</LaddaButton>
                                          </div>
                                          <div className="mb-1 col-md-1 d-flex align-items-end">
                                              <LaddaButton
                                              loading={isLoading}
                                              className="btn btn-danger m-btn-radius w-100"
                                              data-color="#008aff"
                                              data-size='s'
                                              onClick={Reset}
                                          >RESET</LaddaButton>
                                          </div>
                                        </div>
                                    </div>
                                    <ReactTable
                                    // defaultSorted={[{ id: "tanggal", desc: true }]}
                                    data={dataTabel}
                                    defaultPageSize= {5}
                                    columns={columns}
                                    className="mt-3 table-striped w-100"
                                    />
                                    {console.log("data"+dataTabel)}
                                </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
};
export default ReportTransactionUser;