import React, { useState, useEffect, useContext } from "react";
import ReactTable from "react-table";
import { Redirect } from 'react-router-dom';
import ReactLoading from "react-loading";
import { FaSyncAlt } from "react-icons/fa";
import Swal from "sweetalert2";
import axios from "axios";
import Moment from "react-moment";
import Select from "react-select";
import LaddaButton, { l, EXPAND_RIGHT } from "react-ladda";


function ReportMutation(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isReload, setReload] = useState(false);
  const [reloadFilter, setReloadFilter] = useState(false);
  const [reloadReset, setReloadReset] = useState(false);
  const [isAuth, setAuth] = useState(0);
  const [paramDate, setParamDate] = useState({ start_date: "", end_date: "", idpel: "", status: "", type: "" });
  const [displayAlert, setDisplayAlert] = useState({
    display: "none",
    type: "",
    message: "",
  });
  const [isDataMutation, setIsDataMutation] = useState([]);
  const token = localStorage.getItem("token");
  const [isDataStatus, setIsDataStatus] = useState([]);
  const [isStatus, setStatus] = useState({ value: "", label: "" });
  const [isType, setType] = useState({ value: "", label: "" });

  const Loading = ({ type, color }) => (
    <ReactLoading
      type={type}
      color={color}
      className="text-center"
      height={"4%"}
      width={"4%"}
      className="d-flex justify-content-center"
    />
  );
  const columns = [
    {
      Header: "TRX. ID",
      accessor: "trx_id",
      style: {
        textAlign: "left",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "IDPEL",
      accessor: "idpel",
      style: {
        textAlign: "left",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "PRODUK",
      accessor: "product_name",
      style: {
        textAlign: "left",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "STATUS",
      accessor: "status",
      style: {
        textAlign: "center",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (data) =>
        <>
          {httResp(data.value)}
        </>
    },
    {
      Header: "TOTAL",
      accessor: "amount",
      style: {
        textAlign: "right",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (props) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(props.value),
    },
    {
      Header: "SALDO",
      accessor: "balance_after",
      style: {
        textAlign: "right",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (props) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(props.value),
    },
    {
      Header: "TIPE",
      accessor: "transaction_date",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (data) =>
        <>
          {data.status == "K" ? (
            <>KREDIT</>
          ) : (
            <>DEPOSIT</>
          )}
        </>
    },
    {
      Header: "DESKRIPSI",
      accessor: "description",
      style: {
        textAlign: "left",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "TGL. TRX",
      accessor: "created_at",
      style: {
        textAlign: "left",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (row) => (
        <div>
          <Moment format="DD MMM YYYY - HH:mm:ss">
            {row.original.request_refund_date}
          </Moment>
        </div>
      ),
    },
  ];
  const httResp = (code) => {
    if (code == "200") {
      return <span className="badge badge-success">SUKSES</span>
    } else if (code == "400") {
      return <span className="badge badge-warning">SALDO TIDAK CUKUP</span>
    } else if (code == "404") {
      return <span className="badge badge-warning">STOK KOSONG</span>
    } else if (code == "500") {
      return <span className="badge badge-warning">OTORISASI GAGAL</span>
    } else if (code == "505") {
      return <span className="badge badge-danger">ERROR</span>
    } else if (code == "900") {
      return <span className="badge badge-warning">TRANSAKSI GANDA</span>
    } else if (code == "901") {
      return <span className="badge badge-danger">GAGAL</span>
    } else if (code == "902") {
      return <span className="badge badge-warning">SEDANG DIPROSES</span>
    } else if (code == "903") {
      return <span className="badge badge-warning">PRODUK GANGGUAN</span>
    } else if (code == "904") {
      return <span className="badge badge-warning">PESAN DIABAIKAN</span>
    } else if (code == "905") {
      return <span className="badge badge-warning">DIBATALKAN</span>
    } else if (code == "906") {
      return <span className="badge badge-warning">TUJUAN SALAH</span>
    } else if (code == "907") {
      return <span className="badge badge-warning">FORMAT SALAH</span>
    } else if (code == "908") {
      return <span className="badge badge-warning">PRODUK TIDAK TERSEDIA</span>
    } else if (code == "909") {
      return <span className="badge badge-danger">SISTEM ERROR</span>
    } else if (code == "910") {
      return <span className="badge badge-warning">RAISER</span>
    } else if (code == "911") {
      return <span className="badge badge-warning">MENUNGGU JAWABAN</span>
    } else {
      return <span className="badge badge-danger">UNKNOWN ERROR</span>
    }
  }
  useEffect(() => {
    setIsLoading(true);
    let url = "/mutations/report/admin";
    fetchMutation(url);
    fetchStatus();
    setIsLoading(false);
  }, []);
  const fetchMutation = async (url) => {
    // let url = "";
    // if (paramDate.start_date == "" && paramDate.end_date == "" && paramDate.status == "" && paramDate.type == "") {
    //   url = await "/mutations/report/admin";
    // } else {
    //   url = await "/mutations/report/admin?idpel=" + paramDate.idpel + "&date_start=" + paramDate.start_date + "&date_end=" + paramDate.end_date + "&status=" + paramDate.status + "&type=" + paramDate.type;
    // }

    await axios
      .get(`${process.env.REACT_APP_URL}` + url, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setIsDataMutation([...res.data.data]);
        setReload(true);
      }).catch((err) => {
        console.log(err.response.data)
        Swal.fire({
          title: "Uppss..",
          text: err.response.data.message,
          icon: "error",
          confirmButtonText: "Oke",
        });
        setAuth(err.response.data.status)
      })
  };
  const fetchStatus = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL}/information/status`, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setIsDataStatus([...res.data.data]);
        console.warn("res status", res.data.data);
      });
  };
  const filterMutation = async () => {
    let url = "";
    if (paramDate.start_date == "" && paramDate.end_date == "" && paramDate.status == "" && paramDate.type == "" && paramDate.idpel == "") {
      url = await "/mutations/report/admin";
    } else {
      url = await "/mutations/report/admin?idpel=" + paramDate.idpel + "&start_date=" + paramDate.start_date + "&end_date=" + paramDate.end_date + "&status=" + paramDate.status + "&type=" + paramDate.type;
    }
    await setReloadFilter(true)
    await fetchMutation(url)
    await setReloadFilter(false)
  }
  const resetFilter = async () => {
    await setReloadReset(true)
    await setParamDate({start_date:"", end_date:"", idpel:"", status:"",type:""})
    let url = await "/mutations/report/admin";
    await fetchMutation(url);
    await setReloadReset(false)
  }
  const handleChangeStatus = (data) => {
    paramDate.status = data.value;
    setStatus({ label: data.label, value: data.value });
  };
  const handleChangeType = (data) => {
    paramDate.type = data.value;
    setType({ label: data.label, value: data.value });
  };
  const optStatus = isDataStatus.map((d) => ({
    value: d.code,
    label: d.code + " - " + d.name.toUpperCase(),
  }));
  const optType = [
    { value: "D", label: "Deposit" },
    { value: "K", label: "Kredit" },
  ];
  if (isAuth === 401) {
    return <Redirect to="/login" />;
  }
  return (
    <div>
      <>
        <main className="container-fluid pt-4 mt-5">
          <nav aria-label="breadcrumb my-2">
            <ol className="breadcrumb mb-4" type="none">
              <li className="breadcrumb-item">
                <a href="#">Admin</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Laporan</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Laporan Mutasi
              </li>
            </ol>
          </nav>
          <div className="row">
            <div className="col-md-12">
              <div className="card mt-2 rounded-2 border-white shadow">
                <div className="card-header bg-light">
                  <b>Laporan Mutasi</b>
                  <span className="float-right">
                    <LaddaButton
                      loading={reloadReset}
                      onClick={() => resetFilter()}
                      // data-color="#008aff"
                      data-size={l}
                      data-style={EXPAND_RIGHT}
                      className="btn btn-outline-danger"
                    >
                      <FaSyncAlt /> RESET
                  </LaddaButton>
                  </span>
                </div>
                <div className="card-body">
                  <div
                    className={"alert alert-" + displayAlert.type}
                    style={{ display: displayAlert.display }}
                    role="alert"
                  >
                    <strong>{displayAlert.message}</strong>
                  </div>

                  {!isReload || isLoading ? (
                    <Loading type="spinningBubbles" color="#91091e" />
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-md-2">
                          <div className="form-group">
                            <label className="">Idpel</label>
                            <input
                              type="text"
                              className="form-control"
                              name="idpel"
                              value={paramDate.idpel}
                              onChange={(e) =>
                                setParamDate({ ...paramDate, [e.target.name]: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label className="">Tgl. Awal</label>
                            <input
                              type="date"
                              className="form-control"
                              name="start_date"
                              value={paramDate.start_date}
                              onChange={(e) =>
                                setParamDate({ ...paramDate, [e.target.name]: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label className="">Tgl. Akhir</label>
                            <input
                              type="date"
                              className="form-control"
                              name="end_date"
                              value={paramDate.end_date}
                              onChange={(e) =>
                                setParamDate({ ...paramDate, [e.target.name]: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              Status
                            </label>
                            <div className="form-control" style={{ border: "1px solid #ffffff", paddingTop: "0px" }}>
                              <Select
                                name="status"
                                // className="form-control"
                                //   isDisabled={txtForm == "Edit" ? true : false}
                                options={optStatus}
                                value={optStatus.filter(
                                  (obj) => obj.value === paramDate.status
                                )}
                                onChange={(value) => handleChangeStatus(value)}
                                placeholder={<div>- Pilih Status -</div>}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label>
                              Tipe
                            </label>
                            <div className="form-control" style={{ border: "1px solid #ffffff", paddingTop: "0px" }}>
                              <Select
                                name="type"
                                // className="form-control"
                                //   isDisabled={txtForm == "Edit" ? true : false}
                                options={optType}
                                value={optType.filter(
                                  (obj) => obj.value === paramDate.type
                                )}
                                onChange={(value) => handleChangeType(value)}
                                placeholder={<div>- Pilih Tipe -</div>}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-1">
                          <LaddaButton
                            loading={reloadFilter}
                            onClick={() => filterMutation()}
                            data-color="#008aff"
                            data-size={l}
                            data-style={EXPAND_RIGHT}
                            className="btn btn-primary"
                            style={{ marginTop: "35px" }}
                          >
                            FILTER
                          </LaddaButton>
                        </div>
                      </div>
                      <ReactTable
                        data={isDataMutation}
                        // pageSize={this.state.dataDenom.length > 10 ? 10 : this.state.dataDenom.length}
                        defaultPageSize={isDataMutation.length >= 10 ? 10 : 5}
                        columns={columns}
                        className="mt-3 table-striped w-100"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    </div>
  );
}

export default ReportMutation;
