import React, { Component } from "react";
import PropTypes from "prop-types";

class Manintenance extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {}

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  shouldComponentUpdate(nextProps, nextState) {}

  componentWillUpdate(nextProps, nextState) {}

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {}

  render() {
    return (
      <div className="py-4 text-center" style={{ height: "500px" }}>
        <div className="card col-md-5 m-auto">
          <div className="card-body">Site under maintenance !</div>
        </div>
      </div>
    );
  }
}

Manintenance.propTypes = {};

export default Manintenance;
