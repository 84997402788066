import React, { useReducer, createContext, useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import Navbar from "../components/common/Navbar";
import NavBack from "../components/common/NavBack";
import Step from "../components/common/Step";

import Hero from "../components/common/Hero";
import HeroClean from "../components/common/HeroClean";
import Footer from "../components/common/Footer";
import Produk from "../components/common/MenuProduk";
import MenuBatch from "../components/common/MenuBatch";
import UpdateStatus from "../components/front/UpdateStatus";
import LoginPage from "../components/front/LoginPage";
import RegisterPage from "../components/front/Register";
import ListProduk from "../components/front/ListProduk";
import Chektransaksi from "../components/front/Chektransaksi";
import Checkoutv1 from "../components/front/Checkoutv1";
import Payment from "../components/front/Payment";
import AboutPage from "../components/front/AboutPage";
import Invoice from "../components/front/Invoice.js";
import Faq from "../components/front/Faq.js";
import NotFoundPage from "../components/eror/eror404";

//admin
import MenuAdmin from "../components/admin/menu/MenuAdmin";
import Dashboard from "../components/admin/page/Dashboard";
import AdminTrasaction from "../components/admin/page/Transaction";
import AdminRefound from "../components/admin/page/Refound";
import AdminTopup from "../components/admin/page/Topup";
import AdminUsers from "../components/admin/page/Users";
import AdminDenom from "../components/admin/page/Denom";
import AdminReport from "../components/admin/page/Report";
import AdminRole from "../components/admin/page/Role";
import AdminDiskonProduk from "../components/admin/page/DiskonProduk";
import AdminProduk from "../components/admin/page/Produk";
import AdminProdukCat from "../components/admin/page/ProdukCat";
import AdminProdukPri from "../components/admin/page/ProdukPri";
import ManTopupSaldo from "../components/admin/page/ManTopupSaldo";

//mitra
import MenuMitra from "../components/mitra/menu/MenuMitra";
import DashboardMitra from "../components/mitra/page/Dashboard";
import MitraTopup from "../components/mitra/page/Topup";
import Profile from "../components/mitra/page/Profile";
import Refund from "../components/mitra/page/ManRefund";
import Advice from "../components/mitra/page/Advice";
import MutasiUser from "../components/mitra/page/Mutasi_User";
import TransactionUser from "../components/mitra/page/ReportTransactionUser";

//component batch payment
import BatchPage from "../components/batch/index";
// eslint-disable-next-line

import * as Middleware from "../middelware/App";
import ReportMutation from "../components/admin/page/ReportMutation";
import ReportTransaction from "../components/admin/page/ReportTransaction";
import { Helmet } from "react-helmet";
//Context
export const AuthContext = createContext();

//Inisiasi state
const initialState = {
  isAuthenticated: false,
  user_id: 0,
  user: null,
  token: null,
  tokenExpires: 0,
  role: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("user_id", JSON.stringify(action.payload.user_id));
      localStorage.setItem(
        "user",
        JSON.stringify(action.payload.username).replace(/"/g, "")
      );
      localStorage.setItem("role", JSON.stringify(action.payload.user_roles));
      var leng = action.payload.token.length;
      localStorage.setItem(
        "token",
        JSON.stringify(action.payload.token).slice(1, leng + 1)
      );
      localStorage.setItem(
        "fullname",
        JSON.stringify(action.payload.fullname).replace(/"/g, "")
      );
      localStorage.setItem(
        "phone",
        JSON.stringify(action.payload.phone).replace(/"/g, "")
      );
      localStorage.setItem(
        "email",
        JSON.stringify(action.payload.email).replace(/"/g, "")
      );
      return {
        ...state,
        isAuthenticated: true,
        user_id: action.payload.user_id,
        balance: action.payload.balance,
        email: action.payload.email,
        user: action.payload.username.replace(/"/g, ""),
        token: action.payload.token,
        // tokenExpires: action.payload.expires,
        role: action.payload.user_roles,
        fullname: action.payload.fullname.replace(/"/g, ""),
        phone: action.payload.phone.replace(/"/g, ""),
        email: action.payload.email.replace(/"/g, ""),
      };

    case "REDATA":
      return {
        ...state,
        isAuthenticated: true,
        balance: action.payload.balance,
        user_id: localStorage.getItem("user_id"),
        user: localStorage.getItem("user"),
        email: action.payload.email,
        token: action.payload.token,
        role: localStorage.getItem("role"),
        fullname: localStorage.getItem("fullname"),
        phone: localStorage.getItem("phone"),
        email: localStorage.getItem("email"),
      };
    case "LOGOUT":
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    default:
      return state;
  }
};

function GenerateHead(data) {
  return <div>data</div>;
}

function Router(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      <Switch>
        <Route exact path="/">
          <Middleware.Costomer>
            <Navbar>
              <Hero />
              <Produk />
              <Step />
            </Navbar>
            <Footer />
          </Middleware.Costomer>
        </Route>

        <Route exact path="/chek">
          <div className="application">
            <Helmet>
              <title>Chek Tagihan - Bayar Tagihan Online</title>
              <meta property="og:image" content="/favicon.ico" />
              <meta
                data-hid="description"
                name="description"
                content={process.env.REACT_APP_META_CONTENT}
              />
              <link rel="icon" type="image/x-icon" href="/favicon.ico" />
            </Helmet>
          </div>
          <Navbar>
            <HeroClean />
            <Chektransaksi />
          </Navbar>
          <Footer />
        </Route>

        <Route exact path="/faq">
          <Navbar>
            <HeroClean />
            <Faq />
          </Navbar>
          <Footer />
        </Route>

        <Route exact path="/about">
          <Navbar>
            <HeroClean />
            <AboutPage />
          </Navbar>
          <Footer />
        </Route>

        <Route exact path="/login">
          <div className="application">
            <Helmet>
              <title>Login - Cuan Pelangi</title>
              <meta property="og:image" content="/favicon.ico" />
              <meta
                data-hid="description"
                name="description"
                content={process.env.REACT_APP_META_CONTENT}
              />
              <link rel="icon" type="image/x-icon" href="/favicon.ico" />
            </Helmet>
          </div>
          <NavBack>
            <LoginPage />
          </NavBack>
          <Footer />
        </Route>

        <Route exact path="/verifikasi/:Id">
          <NavBack>
            <UpdateStatus />
          </NavBack>
        </Route>

        <Route exact path="/register">
          <div className="application">
            <Helmet>
              <title>Register - Cuan Pelangi</title>
              <meta property="og:image" content="/favicon.ico" />
              <meta
                data-hid="description"
                name="description"
                content={process.env.REACT_APP_META_CONTENT}
              />
              <link rel="icon" type="image/x-icon" href="/favicon.ico" />
            </Helmet>
          </div>
          <NavBack>
            <RegisterPage />
          </NavBack>
          <Footer />
        </Route>

        <Route exact path="/checkoutV1">
          <Middleware.Costomer>
            <Navbar>
              <HeroClean />
              <Checkoutv1 />
            </Navbar>
          </Middleware.Costomer>
        </Route>

        <Route exact path="/payment">
          <Middleware.Costomer>
            <Navbar>
              <HeroClean />
              <Payment />
            </Navbar>
          </Middleware.Costomer>
        </Route>

        <Route exact path="/invoice/:Id">
          <Middleware.Costomer>
            <Navbar>
              <HeroClean />
              <Invoice />
            </Navbar>
          </Middleware.Costomer>
        </Route>

        {/* ROUTE PRODUK CUAN PELANGI */}

        <Route exact path="/pln">
          <Middleware.Costomer>
            <div className="application">
              <Helmet>
                <meta property="og:image" content="/favicon.ico" />
                <title>PLN - Bayar Tagihan Online</title>
                <link rel="canonical" href={process.env.REACT_APP_URL} />
              </Helmet>
            </div>
            <Navbar>
              <Hero />
              <Produk />
            </Navbar>
            <Footer />
          </Middleware.Costomer>
        </Route>

        <Route exact path="/bpjs">
          <Middleware.Costomer>
            <div className="application">
              <Helmet>
                <title>BPJS - Bayar Tagihan Online</title>
                <meta property="og:image" content="/favicon.ico" />
                <meta
                  data-hid="description"
                  name="description"
                  content={process.env.REACT_APP_META_CONTENT}
                />
                <link rel="icon" type="image/x-icon" href="/favicon.ico" />
              </Helmet>
            </div>
            <Navbar>
              <Hero />
              <Produk />
            </Navbar>
            <Footer />
          </Middleware.Costomer>
        </Route>

        <Route exact path="/pulsa">
          <div className="application">
            <Helmet>
              <title>PULSA - Bayar Tagihan Online</title>
              <meta property="og:image" content="/favicon.ico" />
              <meta
                data-hid="description"
                name="description"
                content={process.env.REACT_APP_META_CONTENT}
              />
              <link rel="icon" type="image/x-icon" href="/favicon.ico" />
            </Helmet>
          </div>
          <Middleware.Costomer>
            <Navbar>
              <Hero />
              <Produk />
            </Navbar>
            <Footer />
          </Middleware.Costomer>
        </Route>

        <Route exact path="/telkom">
          <div className="application">
            <Helmet>
              <title>TELKOM - Bayar Tagihan Online</title>
              <meta property="og:image" content="/favicon.ico" />
              <meta
                data-hid="description"
                name="description"
                content={process.env.REACT_APP_META_CONTENT}
              />
              <link rel="icon" type="image/x-icon" href="/favicon.ico" />
            </Helmet>
          </div>
          <Middleware.Costomer>
            <Navbar>
              <Hero />
              <Produk />
            </Navbar>
            <Footer />
          </Middleware.Costomer>
        </Route>

        <Route exact path="/pdam">
          <div className="application">
            <Helmet>
              <title>PDAM - Bayar Tagihan Online</title>
              <meta property="og:image" content="/favicon.ico" />
              <meta
                data-hid="description"
                name="description"
                content={process.env.REACT_APP_META_CONTENT}
              />
              <link rel="icon" type="image/x-icon" href="/favicon.ico" />
            </Helmet>
          </div>
          <Middleware.Costomer>
            <Navbar>
              <Hero />
              <Produk />
            </Navbar>
            <Footer />
          </Middleware.Costomer>
        </Route>

        <Route exact path="/data">
          <div className="application">
            <Helmet>
              <title>DATA - Bayar Tagihan Online</title>
              <meta property="og:image" content="/favicon.ico" />
              <meta
                data-hid="description"
                name="description"
                content={process.env.REACT_APP_META_CONTENT}
              />
              <link rel="icon" type="image/x-icon" href="/favicon.ico" />
            </Helmet>
          </div>
          <Middleware.Costomer>
            <Navbar>
              <Hero />
              <Produk />
            </Navbar>
            <Footer />
          </Middleware.Costomer>
        </Route>

        <Route exact path="/game">
          <div className="application">
            <Helmet>
              <title>GAME - Bayar Tagihan Online</title>
              <meta property="og:image" content="/favicon.ico" />
              <meta
                data-hid="description"
                name="description"
                content={process.env.REACT_APP_META_CONTENT}
              />
              <link rel="icon" type="image/x-icon" href="/favicon.ico" />
            </Helmet>
          </div>
          <Navbar>
            <Hero />
            <Produk />
          </Navbar>
          <Footer />
        </Route>

        <Route exact path="/listharga">
          <Middleware.Costomer>
            <Navbar>
              <HeroClean />
              <ListProduk />
            </Navbar>
            <Footer />
          </Middleware.Costomer>
        </Route>

        <Route exact path="/batch">
          <Navbar>
            <MenuBatch />
            <BatchPage />
          </Navbar>
          {/* <Footer/> */}
        </Route>

        {/* PAGE ADMIN */}

        <Route exact path="/cpadm/dashboard">
          <MenuAdmin />
          <Dashboard />
        </Route>

        <Route exact path="/cpadm/transaction">
          <MenuAdmin />
          <AdminTrasaction />
        </Route>

        <Route exact path="/cpadm/refound">
          <MenuAdmin />
          <AdminRefound />
        </Route>

        <Route exact path="/cpadm/Role">
          <MenuAdmin />
          <AdminRole />
        </Route>

        <Route exact path="/cpadm/users">
          <MenuAdmin />
          <AdminUsers />
        </Route>
        <Route exact path="/cpadm/denom">
          <MenuAdmin />
          <AdminDenom />
        </Route>
        <Route exact path="/cpadm/diskonproduk">
          <MenuAdmin />
          <AdminDiskonProduk />
        </Route>

        <Route exact path="/cpadm/report">
          <MenuAdmin />
          <AdminReport />
        </Route>
        <Route exact path="/cpadm/produk">
          <MenuAdmin />
          <AdminProduk />
        </Route>
        <Route exact path="/cpadm/produkcat">
          <MenuAdmin />
          <AdminProdukCat />
        </Route>
        <Route exact path="/cpadm/produkpri">
          <MenuAdmin />
          <AdminProdukPri />
        </Route>
        <Route exact path="/cpadm/mantopupsaldo">
          <MenuAdmin />
          <ManTopupSaldo />
        </Route>
        <Route exact path="/cpadm/report-mutation">
          <MenuAdmin />
          <ReportMutation />
        </Route>
        <Route exact path="/cpadm/report-transaction">
          <MenuAdmin />
          <ReportTransaction />
        </Route>

        {/* END ADMIN */}

        {/* PAGE MITRA */}
        <Route exact path="/cpmtr/dashboard">
          <MenuMitra />
          <DashboardMitra />
        </Route>
        <Route exact path="/cpmtr/topup">
          <MenuMitra />
          <MitraTopup />
        </Route>
        <Route exact path="/cpmtr/profile">
          <MenuMitra />
          <Profile />
        </Route>
        <Route exact path="/cpmtr/refund">
          <MenuMitra />
          <Refund />
        </Route>
        <Route exact path="/cpmtr/advice">
          <MenuMitra />
          <Advice />
        </Route>
        <Route exact path="/cpmtr/mutasiuser">
          <MenuMitra />
          <MutasiUser />
        </Route>
        <Route exact path="/cpmtr/transactionuser">
          <MenuMitra />
          <TransactionUser />
        </Route>
        {/* END MITRA */}
        <Route component={NotFoundPage} />
      </Switch>
    </AuthContext.Provider>
  );
}

export default Router;
