import React, { Component, useContext } from "react";
import Pln_token from "./pln_token";
import { Redirect } from "react-router-dom";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import Swal from "sweetalert2";
import LaddaButton, { l, EXPAND_RIGHT } from "react-ladda";
import Modal from "react-modal";
import ReactLoading from "react-loading";
import Axios from "axios";
import { each } from "jquery";
Axios.defaults.timeout = process.env.REACT_APP_TIMEOUT_INQ;

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Loader type="ThreeDots" color="#007bff" height="100" width="100" />
      </div>
    )
  );
};

const Loading = ({ type, color }) => (
  <ReactLoading type={type} color={color} height={"10%"} width={"10%"} />
);

const StrukInq = (props) => (
  <div>
    <div className="col-md-12">
      <hr></hr>
      <div className="row">
        <div className="col-md-10 text-right">
          <label></label>
          <h6></h6>
        </div>
        <div className="col-md-2">
          <button
            type="submit"
            className="btn btn-lg btn-primary"
            style={{ height: "100%" }}>
            Bayar Sekarang
          </button>
        </div>
      </div>
    </div>
  </div>
);

class pln extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showToken: false,
      idpel: null,
      idpelStatus: false,
      payment: false,
      inquiry: false,
      message: null,
      disconect: false,
      product_nomination: null,
      loading: true,
      email: "",
      periode_payment: "",
      trx_type: "2100",
      produk: "",
      list_product: [],
      list_token: [],
      product_id: null,
      product_type: "",
      token: localStorage.getItem("token"),
      inqres: [],
    };
  }

  GetProducts() {
    try {
      fetch(`${process.env.REACT_APP_URL}/products/42`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Barier " + this.state.token,
          Accept: "application/json",
        },
      })
        .then((response) => {
          response.json().then((result) => {
            if (result.data && result.data.length != 0) {
              result.data.reverse();
            }
            this.setState({ list_product: result.data, disconect: false });
            if (result.data) {
              result.data.forEach((data) => {
                if (data.code === "TOKENPLN") {
                  this.setState({
                    produk: data.code,
                    product_id: data.prod_id,
                    // showToken: true,
                  });
                }
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          if (error.response) {
            Swal.fire({
              title: "Maaf",
              text: error.response.data.message.desc,
              icon: "error",
              confirmButtonText: "oke",
            });
          } else {
            this.setState({
              disconect: true,
            });
            if (error.code === "ECONNABORTED")
              Swal.fire({
                title: "Maaf",
                text:
                  "Maaf Produk sedang gangguan atau tidak bisa bisa di transaksikan sekarang ",
                icon: "error",
                confirmButtonText: "oke",
              });
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  GetProduct() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}/products/42`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Barier " + process.env.REACT_APP_TOKEN,
        Accept: "application/json",
      },
    };

    try {
      Axios(config)
        .then((response) => {
          var result = response.data;
          // console.log(result.data);
          if (result.data && result.data.length != 0) {
            result.data.reverse();
          }
          this.setState({ list_product: result.data, disconect: false });
          if (result.data) {
            result.data.forEach((data) => {
              // console.log(data);
              if (data.code === "TOKENPLN") {
                this.setState({
                  produk: data.code,
                  product_id: data.prod_id,
                  // showToken: true,
                });
              }
            });
          }
        })
        .catch((error) => {
          // console.log(error);
          this.setState({
            loading: false,
          });
          if (error.response) {
            Swal.fire({
              title: "Maaf",
              text: error.response.data.message.desc == "" ? error.response.data.message.status : error.response.data.message.desc,
              icon: "error",
              confirmButtonText: "oke",
            });
          } else {
            this.setState({
              disconect: true,
            });
            if (error.code === "ECONNABORTED")
              Swal.fire({
                title: "Maaf",
                text:
                  "Maaf Produk sedang gangguan atau tidak bisa bisa di transaksikan sekarang ",
                icon: "error",
                confirmButtonText: "oke",
              });
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  GetToken() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}/denom`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Barier " + process.env.REACT_APP_TOKEN,
        Accept: "application/json",
      },
    };

    Axios(config)
      .then((response) => {
        var result = response.data;
        // console.log(result.data);
        if (result.status == 200) {
          this.setState({ list_token: result.data });
        } else {
          alert("failed get data from server");
        }
      })
      .catch((error) => {
        // console.log(error);
        this.setState({
          loading: false,
          message: error.response,
        });
      });
  }

  componentDidMount() {
    this.GetProduct();
    this.GetToken();
    this.setState({
      showToken: true,
      loading: false,
      payment: false,
    });
  }

  setTypeProduct(event) {
    this.setState({
      loading: true,
      inquiry: false,
      trx_type: "2100",
    });
    if (event.target.value == "TOKENPLN") {
      this.GetToken();
      this.setState({
        showToken: true,
        loading: false,
        product_id: event.target.id,
        product_type: event.target.value,
        message: null,
      });
    } else if (event.target.value == "PLNPOSTPAID") {
      this.setState({
        showToken: false,
        loading: false,
        product_id: event.target.id,
        product_type: event.target.value,
        message: null,
      });
    } else {
      this.setState({
        showToken: false,
        loading: false,
        product_id: event.target.id,
        product_type: event.target.value,
        message: null,
      });
    }
  }

  handleChangeIdpel(e) {
    let inIdpel = e.target.value;
    if (inIdpel == "" || inIdpel == null) {
      this.setState({ message: null });
    }

    this.setState({ inquiry: false, trx_type: "2100", idpelStatus: false });
    if (inIdpel.length >= 9 && inIdpel.length <= 12) {
      this.setState({ message: null });
      this.setState({ idpel: inIdpel, idpelStatus: true });
      // console.log(this.state.idpel)
    } else {
      this.setState({
        message: "Inputan untuk produk PLN minimal panjangnya adalah 11 digit ",
        inquiry: false,
        trx_type: "2100",
        idpelStatus: false,
      });
    }
  }

  rupiahFormat(num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  }

  async HitData() {
    if (
      this.state.product_nomination == null &&
      this.state.product_type == "TOKENPLN"
    ) {
      Swal.fire({
        title: "Maaf :)",
        text: "Anda belum memilih nominal pembelian",
        icon: "info",
        confirmButtonText: "oke",
      });
    } else {
      await this.setState({ loading: true });
      var data = JSON.stringify({
        trx_type: "2100",
        trx_id: "",
        idpel: this.state.idpel,
        product_id: this.state.product_id,
        product_nomination: this.state.product_nomination,
        periode_payment: "",
      });

      var config = {
        method: "POST",
        url: `${process.env.REACT_APP_URL}/transactions/inquiry`,
        headers: {
          Authorization: this.state.token,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: data,
      };
      Axios(config)
        .then((response) => {
          var result = response.data;
          if (result.data.rc == "0000" && result.status == 200) {
            localStorage.setItem(
              "private_inquiry",
              JSON.stringify({
                data: result.data,
                produk: this.state.product_id,
                produk_type: this.state.product_type,
                product_nomination: this.state.product_nomination,
              })
            );
            this.setState({ inquiry: true, inqres: result.data });
          } else {
            // alert("222222");
            this.setState({ message: result.message, loading: false });
            Swal.fire({
              title: "Maaf",
              text: result.data.desc || result.message.desc || result.message,
              icon: "info",
              confirmButtonText: "Tutup",
            });
            this.setState({ loading: false });
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({
            loading: false,
            message: error.response.data.message.desc == "" ? error.response.data.message.status : error.response.data.message.desc,
          });
          if (error.response) {
            Swal.fire({
              title: "Maaf",
              text: error.response.data.message.desc == "" ? error.response.data.message.status : error.response.data.message.desc,
              icon: "error",
              confirmButtonText: "oke",
            });
          } else {
            if (error.code === "ECONNABORTED")
              Swal.fire({
                title: "Maaf",
                text:
                  "Maaf Produk sedang gangguan atau tidak bisa bisa di transaksikan sekarang ",
                icon: "error",
                confirmButtonText: "oke",
              });
          }
        });
    }
  }

  handleClick() {
    this.setState({ buttonState: "loading" });
    setTimeout(() => {
      this.setState({ buttonState: "success" });
    }, 3000);
  }

  setNominal(nominal) {
    this.setState({ product_nomination: nominal });
    // console.log(this.state.product_nomination);
  }

  render() {
    return (
      <div>
        {!this.state.produk ? (
          <div>
            {this.state.disconect ? (
              <div className="text-center">
                <img
                  src="./img/dc.png"
                  alt=""
                  className="img-fluid"
                  width="50%"
                />
                <h5>Maaf Sedang Terjadi Gangguan</h5>
                <label>
                  Tunggu Sebentar kami akan memperbaiki secepat mungkin{" "}
                </label>
              </div>
            ) : (
              <div>
                {" "}
                <div className="w-100 h-100  d-flex justify-content-center">
                  <Loading type="bubbles" color="#008aff" className="m-auto" />
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            <div>
              {this.state.inquiry ? (
                <>
                  <Redirect to="/checkoutv1" />
                </>
              ) : (
                <></>
              )}

              {/* judul  */}
              <div className="">
                <h5 className="text-dark">Beli Token/Bayar Tagihan Listrik</h5>
              </div>

              {/* pilihan produk */}

              <div className="row mt-4">
                <div
                  className="col-md-12"
                  onChange={this.setTypeProduct.bind(this)}>
                  {this.state.list_product.map(function (value, i) {
                    return (
                      <label className="font-weight-bold d-inline mr-3">
                        <input
                          key={i}
                          type="radio"
                          value={value.code}
                          id={value.prod_id}
                          name="plnType"
                          className="mr-2"
                          defaultChecked={i === 0 ? "check" : ""}
                        />
                        {value.name}
                      </label>
                    );
                  })}
                </div>
              </div>

              <div className="">
                {this.state.showToken ? (
                  <div>
                    <div className="row mt-4">
                      <div className="col-md-12">
                        {this.state.message != null ? (
                          <div className="alert alert-danger" role="alert">
                            <label className="text-danger small">
                              {this.state.message}
                            </label>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-md-9">
                        <div className="form-group">
                          <h6 className="text-black">ID Pelanggan </h6>
                          <input
                            type="number"
                            className="form-control"
                            defaultValue=""
                            placeholder="No. Meter/ ID Pelanggan"
                            onKeyUp={(e) => {
                              this.handleChangeIdpel(e);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <LaddaButton
                          loading={this.state.loading}
                          disabled={!this.state.idpelStatus}
                          onClick={() => {
                            this.HitData();
                          }}
                          data-color="#008aff"
                          data-size={l}
                          className="m-btn m-btn-theme m-btn-radius w-100 shadow"
                          style={{ marginTop: "25px" }}
                          data-style={EXPAND_RIGHT}>
                          Beli Token {this.state.product_nomination}
                        </LaddaButton>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="row mt-4">
                      {/* <div className="col-md-12">
                        {this.state.message != null ? (
                          <div className="alert alert-warning" role="alert">
                            <label className="text-warning small">
                              {this.state.message}
                            </label>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div> */}
                      <div className="col-md-9">
                        <div className="form-group">
                          <h6 className="text-black">ID Pelanggan </h6>
                          <input
                            type="number"
                            className="form-control"
                            defaultValue=""
                            placeholder="No. Meter/ ID Pelanggan"
                            onKeyUp={(e) => {
                              this.handleChangeIdpel(e);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <LaddaButton
                          loading={this.state.loading}
                          disabled={!this.state.idpelStatus}
                          onClick={() => {
                            this.HitData();
                          }}
                          data-color="#008aff"
                          data-size={l}
                          className="m-btn m-btn-theme m-btn-radius w-100"
                          style={{ marginTop: "25px" }}
                          data-style={EXPAND_RIGHT}>
                          Cek Tagihan
                        </LaddaButton>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {this.state.showToken ? (
                <div>
                  <h6 className="text-black mt-3">Pilih Nominal Token</h6>
                  {this.state.list_token ? (
                    <div className="row">
                      <div className="tab-style-6 p-0 m-0">
                        <div className="row">
                          <div className="col-lg-12">
                            <ul className="nav nav-fill nav-tabs row m-2">
                              {this.state.list_token.map((value, index) => {
                                return (
                                  <li
                                    className="nav-item col-md-4 col-6 m-auto p-1"
                                    key={index}
                                    onClick={(e) => {
                                      this.setNominal(value.denom);
                                    }}>
                                    <a
                                      href="#"
                                      data-toggle="tab"
                                      className="nav-link"
                                      style={{ width: "100%" }}>
                                      <span>
                                        Rp.{" "}
                                        {this.rupiahFormat(
                                          parseFloat(value.denom)
                                        )}
                                      </span>
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="w-100 h-100  d-flex justify-content-center">
                      <Loading
                        type="bubbles"
                        color="#008aff"
                        className="m-auto"
                      />
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}

              <div
                className="card border border-primary bg-primary-300 mt-1"
                style={{ backgroundColor: "#dbeefd" }}>
                <div className="card-body">
                  <p className="small">
                    <b className="text-primary">Keterangan</b>
                    <br />
                    <br />
                    1. Produk Listrik PLN tidak tersedia pada jam cut off/maintenance (23.00 - 01.00).
                    <br />
                    2. Jatuh tempo pembayaran tagihan listrik adalah tanggal 20 di setiap bulannya.
                    <br />
                    3. Proses verifikasi pembayaran maksimum 1x24 jam hari kerja.
                    <br />
                    4. Untuk metode pembayaran menggunakan virtual account / transfer bank akan dikenakan biaya transaksi sebesar <b>Rp. 4.500</b>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default pln;
