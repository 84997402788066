import React from 'react';
import { faAddressBook, faAddressCard, faAdjust, faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function index(props) {
    return (
        <div>

        </div>
    );
}

export default index;