import React, { useState, useEffect, useContext } from "react";
import ReactTable from "react-table";
import { Redirect } from 'react-router-dom';
import { AuthContext } from "../../../router/Router";
import ReactLoading from "react-loading";
import Swal from "sweetalert2";
import { FaSearch, FaFilter } from "react-icons/fa";
import axios from "axios";
import Moment from "react-moment";

function Refound(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isReload, setReload] = useState(false);
  const [isAuth, setAuth] = useState(0);
  const [paramDate, setParamDate] = useState({ start_date: "", end_date: "" });
  const [refreshDate, setRefreshDate] = useState(Date().toLocaleString());
  const [isUpdate, setUpdate] = useState(0);
  const [isSearchInput, setSearchInput] = useState("");
  const [txtForm, setTxtForm] = useState("Tambah");
  const [displayAlert, setDisplayAlert] = useState({
    display: "none",
    type: "",
    message: "",
  });
  const [isDataRefund, setIsDataRefund] = useState([]);
  const Loading = ({ type, color }) => (
    <ReactLoading
      type={type}
      color={color}
      className="text-center"
      height={"4%"}
      width={"4%"}
      className="d-flex justify-content-center"
    />
  );
  const columns = [
    {
      Header: "#",
      maxWidth: 50,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
      style: {
        textAlign: "center",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "TGL REQ",
      accessor: "request_refund_date",
      width: 180,
      Cell: (row) => (
        <div>
          <Moment format="DD MMM YYYY - HH:mm:ss">
            {row.original.request_refund_date}
          </Moment>
        </div>
      ),
    },
    {
      Header: "USERNAME",
      accessor: "username",
      style: {
        textAlign: "left",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "IDPEL",
      accessor: "idpel",
      style: {
        textAlign: "left",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "TOTAL",
      accessor: "tagihan_amount",
      style: {
        textAlign: "left",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (props) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(props.value),
    },

    {
      Header: "DESKRIPSI",
      accessor: "tagihan_description",
      style: {
        textAlign: "left",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "TIPE BAYAR",
      accessor: "payment_method",
      style: {
        textAlign: "left",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "TGL TRX",
      accessor: "transaction_date",
      width: 180,
      Cell: (row) => (
        <div>
          <Moment format="DD MMM YYYY - HH:mm:ss">
            {row.original.transaction_date}
          </Moment>
        </div>
      ),
    },
    {
      Header: "AKSI",
      accessor: "id",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
      filterable: false,
      Cell: (row) => (
        <div>
          {row.original.status_approve == null ? (
            <>
              <button
                className="btn btn-success btn-flat btn-sm"
                onClick={() => approveConfirm("Approve", row.original.transaction_id)}
                title="Approve"
              >
                Approve
              </button>
              &nbsp;
              <button
                onClick={() => approveConfirm("Reject", row.original.transaction_id)}
                className="btn btn-danger btn-flat btn-sm"
                title="Reject"
              >
                Reject
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
  ];
  const token = localStorage.getItem("token");
  useEffect(() => {
    setIsLoading(true);
    fetchRefund();
    setIsLoading(false);
  }, [isSearchInput]);
  const fetchRefund = async () => {
    let searchInput = await isSearchInput;
    let url = "";
    if (paramDate.start_date == "" || paramDate.end_date == "") {
      url = await "/transactions/refound/admin/list";
    } else {
      url = await "/transactions/refound/admin/list?start_date=" + paramDate.start_date + "&end_date=" + paramDate.end_date;
    }

    await axios
      .get(`${process.env.REACT_APP_URL}` + url, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.warn("response refund", res.data.data);

        if (searchInput == "") {
          setIsDataRefund([...res.data.data]);
        } else {
          let filteredData = res.data.data.filter((value) => {
            return (
              value.request_refund_date
                .toString()
                .toLowerCase()
                .includes(searchInput.toString().toLowerCase()) ||
              value.username
                .toString()
                .toLowerCase()
                .includes(searchInput.toString().toLowerCase()) ||
              value.idpel
                .toString()
                .toLowerCase()
                .includes(searchInput.toString().toLowerCase()) ||
              value.tagihan_amount
                .toString()
                .toLowerCase()
                .includes(searchInput.toString().toLowerCase()) ||
              value.tagihan_description
                .toString()
                .toLowerCase()
                .includes(searchInput.toString().toLowerCase()) ||
              value.payment_method
                .toString()
                .toLowerCase()
                .includes(searchInput.toString().toLowerCase()) ||
              value.transaction_date
                .toString()
                .toLowerCase()
                .includes(searchInput.toString().toLowerCase())
            );
          });
          setIsDataRefund([...filteredData]);
        }
        setReload(true);
      }).catch((err) => {
        console.log(err.response.data)
        Swal.fire({
          title: "Uppss..",
          text: err.response.data.message,
          icon: "error",
          confirmButtonText: "Oke",
        });
        setAuth(err.response.data.status)
      })
  };
  const approveConfirm = (tipe, id) => {
    Swal.fire({
      title: tipe + " Refund ?",
      text: "",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: tipe,
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.value) {
        saveApprove(tipe, id);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //cencle
      }
    });
  };
  const saveApprove = async (tipe, id) => {
    setIsLoading(true);
    var status = true;
    if (tipe == "Reject") {
      status = false;
    }
    try {
      await axios
        .put(
          `${process.env.REACT_APP_URL}/transactions/refound/approve/` + id,
          {
            status: status,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((json) => {
          console.warn("resp appr", json.data);
          if (json.data.status == 200) {
            fetchRefund();
            showAlert("block", "success", "Refund berhasil di " + tipe);
          } else {
            showAlert("block", "danger", "Refund gagal di " + tipe);
          }
        });
    } catch (err) {
      showAlert("block", "danger", "Terjadi kesalahan sistem. Coba sesaat lagi");
    }
    // setUpdate(isDataDenom.length);
    setIsLoading(false);
  };
  const showAlert = (display, type, message) => {
    setDisplayAlert({
      display: display,
      type: type,
      message: message,
    });

    const timeId = setTimeout(() => {
      setDisplayAlert({ display: "none" });
    }, 3000);
    return () => {
      clearTimeout(timeId);
    };
  };
  const resetFilter = async () => {
    window.location.reload()
  }
  if (isAuth === 401) {
    return <Redirect to="/login" />;
  }
  return (
    <div>
      <>
        <main className="container-fluid pt-4 mt-5">
          <nav aria-label="breadcrumb my-2">
            <ol className="breadcrumb mb-4" type="none">
              <li className="breadcrumb-item">
                <a href="#">Admin</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Transaksi</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Refund
              </li>
            </ol>
          </nav>
          <div className="row">
            <div className="col-md-12">
              <div className="card mt-2 rounded-2 border-white shadow">
                <div className="card-header bg-light">
                  <b>Data Refund</b>
                </div>
                <div className="card-body">
                  <div
                    className={"alert alert-" + displayAlert.type}
                    style={{ display: displayAlert.display }}
                    role="alert"
                  >
                    <strong>{displayAlert.message}</strong>
                  </div>

                  {!isReload || isLoading ? (
                    <Loading type="spinningBubbles" color="#91091e" />
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group row">
                            <label className="col-md-4 col-form-label">Tgl. Awal</label>
                            <div className="col-md-8">
                              <input
                                type="date"
                                className="form-control form-control-sm"
                                name="start_date"
                                value={paramDate.start_date}
                                onChange={(e) =>
                                  setParamDate({
                                    start_date: e.target.value,
                                    end_date: paramDate.end_date,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group row">
                            <label className="col-md-4 col-form-label">Tgl. Akhir</label>
                            <div className="col-md-8">
                              <input
                                type="date"
                                className="form-control form-control-sm"
                                name="end_date"
                                value={paramDate.end_date}
                                onChange={(e) =>
                                  setParamDate({
                                    start_date: paramDate.start_date,
                                    end_date: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <button
                            type="button"
                            className="btn btn-primary btn-sm col-md-5"
                            onClick={() => fetchRefund()}
                          >
                            <FaFilter className="" /> FILTER
                          </button>

                          <button
                            type="button"
                            className="btn btn-danger btn-sm ml-1 col-md-5"
                            onClick={() => resetFilter()}
                          >
                            RESET
                          </button>

                        </div>
                        <div className="col-md-3">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text bg-light form-control-sm"
                                id="inputGroup-sizing-sm"
                              >
                                <FaSearch />
                              </span>
                            </div>
                            <input
                              type="text"
                              name="keyword"
                              value={isSearchInput}
                              onChange={(e) => setSearchInput(e.target.value)}
                              className="form-control form-control-sm"
                              aria-describedby="inputGroup-sizing-sm"
                              placeholder="Masukkan kata kunci pencarian..."
                            />
                          </div>
                        </div>
                      </div>
                      <ReactTable
                        data={isDataRefund}
                        // pageSize={this.state.dataDenom.length > 10 ? 10 : this.state.dataDenom.length}
                        defaultPageSize={isDataRefund.length >= 10 ? 10 : 5}
                        columns={columns}
                        className="mt-3 table-striped w-100"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    </div>
  );
}

export default Refound;
