import React from 'react';
import NumberFormat from 'react-number-format';
export const PlnPrepaid = (props) => (
    <>
        {/* <tr className="table-borderless">
            <td colSpan="2" className="text-muted">STRUK PEMBELIAN TOKEN PLN PREPAID</td>
        </tr> */}
        <tr>
            <td>NO METER</td>
            <td className="text-right border-none">{props.struk.material_number}</td>
        </tr>
        <tr>
            <td>ID PELANGGAN</td>
            <td className="text-right border-none">{props.struk.subscriber_id}</td>
        </tr>
        <tr>
            <td>NAMA</td>
            <td className="text-right">{props.struk.subscriber_name}</td>
        </tr>

        <tr>
            <td>TARIF/DAYA</td>
            <td className="text-right">{props.struk.subscriber_segmentation}/{props.struk.power}</td>
        </tr>

        <tr>
            <td>NO REF</td>
            <td className="text-right">{props.struk.switcher_refno}</td>
        </tr>

        <tr>
            <td>MATERAI</td>
            <td className="text-right"><NumberFormat value={props.struk.meterai} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>

        <tr>
            <td>PPN</td>
            <td className="text-right"><NumberFormat value={props.struk.ppn} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>

        <tr>
            <td>PPJ</td>
            <td className="text-right"><NumberFormat value={props.struk.ppj} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>

        <tr>
            <td>ANGSURAN</td>
            <td className="text-right"><NumberFormat value={props.struk.angsuran} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>
        <tr>
            <td>TOKEN</td>
            <td className="text-right"><NumberFormat
                format="#### - #### - #### - #### - ####"
                value={props.struk.token}
                displayType={"text"}
                renderText={(value) => <div>{value}</div>}
            /></td>
        </tr>
        <tr>
            <td>RP TOKEN</td>
            <td className="text-right"><NumberFormat value={props.struk.power_purchase} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>
        <tr>
            <td>DENOM</td>
            <td className="text-right"><NumberFormat value={props.struk.amount - props.struk.admin_charge} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>
        <tr>
            <td>ADMIN BANK</td>
            <td className="text-right"><NumberFormat value={props.struk.admin_charge} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>

        {/* <tr>
            <td>RP BAYAR</td>
            <td className="text-right"><NumberFormat value={props.struk.amount} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr> */}
        <tr>
            <td>BIAYA TRANSAKSI</td>
            <td className="text-right"><NumberFormat value={parseFloat(props.transaction.handling_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>
        <tr>
            <td className="font-weight-bold h6">SUBTOTAL</td>
            {props.discount.code ? (
                <>
                    <td className="font-weight-bold h6 text-right"><NumberFormat value={parseFloat(props.transaction.amount) + parseFloat(props.discount.admin_fee_before_discount)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
                </>
            ) : (<>
                <td className="font-weight-bold h6 text-right"><NumberFormat value={parseFloat(props.transaction.amount)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
            </>)}
        </tr>
        <tr>
            {props.discount.code ? (
                <>
                    <td className="font-weight-bold">PROMO BIAYA ADMIN <br /><span className="text-success">( {props.discount.code}  {props.discount.discount}% ) </span></td>
                    <td className="font-weight-bold h6 text-right align-middle"><NumberFormat value={parseFloat(props.discount.admin_fee_before_discount) - parseFloat(props.discount.admin_fee_after_discount)} displayType={'text'} thousandSeparator={true} prefix={'- Rp. '} renderText={value => <div className="text-success">{value}</div>} /></td>
                </>

            ) : (<>
                <td className="font-weight-bold">PROMO BIAYA ADMIN</td>
                <td className="font-weight-bold h6 text-right align-middle"><NumberFormat value={"0"} displayType={'text'} thousandSeparator={true} prefix={'- Rp. '} renderText={value => <div className="text-success">{value}</div>} /></td>
            </>)}
        </tr>
        <tr>
            <td className="font-weight-bold h6">TOTAL</td>
            <td className="font-weight-bold h6 text-right"><NumberFormat value={parseFloat(props.transaction.amount)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>
        <tr>
            <td colSpan="2" className="text-center"><b>Terimakasih </b><b>"Informasi Hubungi Call Center 123 Atau Hub PLN Terdekat"</b></td>
        </tr>


    </>
);