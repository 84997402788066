import React, { useContext, useEffect, useState } from "react";
import { PlnPostpaidInq } from "../Element/PlnPostpaidInq";
import { PlnPrepaidInq } from "../Element/PlnPrepaidInq";
import { BpjsInq } from "../Element/BpjsInq";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import { PulsaInq } from "../Element/PulsaInq";
import { DataInq } from "../Element/DataInq";
import { TelkomInq } from "../Element/TelkomInq";
import { PDAMInq } from "../Element/PDAMInq";
import { NavLink } from "react-router-dom";
import axios from "axios";
import LaddaButton, { l, EXPAND_RIGHT } from "react-ladda";
import { AuthContext } from "../../router/Router";
import { Redirect } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Moment from "react-moment";
import { FaCheckCircle } from "react-icons/fa";


// import bcrypt from 'bcryptjs';
const createHistory = require("history").createBrowserHistory;
const apiUrl = process.env.REACT_APP_URL;
const ProdukInquiry = ({ produk, data, fulldata, adminBank = 0 }) => {
  if (data.code == "PLNPOSTPAID") {
    return <PlnPostpaidInq struk={data} />;
  } else if (data.code == "TOKENPLN") {
    return <PlnPrepaidInq struk={fulldata} />;
  } else if (data.code == "BPJS") {
    return <BpjsInq struk={data} />;
  } else if (data.code == "TELKOM") {
    return <TelkomInq struk={data} />;
  } else if (data.code == "SPEEDY") {
    return <TelkomInq struk={data} />;
  } else if (data.code == "PDAM") {
    console.log(data.blth.substr(4, 2));
    return <PDAMInq struk={data} />;
  } else {
    console.warn("data", data);
    if (data.product_type == "PULSA") {
      return <PulsaInq struk={fulldata} adminBank={adminBank} />;
    } else if (data.product_type == "PAKET DATA") {
      return <DataInq struk={fulldata} adminBank={adminBank} />;
    }
  }

  return null;
};

function Checkoutv1(props) {
  let history = createHistory();
  const { dispatch } = useContext(AuthContext);
  const { state } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isReload, setReload] = useState(false);
  const [display, setDisplay] = useState([]);

  const [produkName, setProdukName] = useState("");
  const [product_id, setProduct_id] = useState("");
  const [transaction_id, setTransaction_id] = useState("");
  const [idpel, setIdpel] = useState("");
  const [hash, setHash] = useState("");
  const [admin_charge, setAdmin_carge] = useState("");
  const [total, setTotal] = useState("");
  const [useSaldo, setUseSaldo] = useState(true);
  const [harga, setHarga] = useState("");
  const [email, setEmail] = useState("");
  const [productNomination, setProductNomination] = useState("");
  const [periodePayment, setPeriodePayment] = useState("");
  const [handling_fee, setHandling_fee] = useState("");
  const [promo, setPromo] = useState([]);
  const [usePromo, setUsePromo] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const struk = JSON.parse(localStorage.getItem("private_inquiry"));
  const user = JSON.parse(localStorage.getItem("user_id"));
  if (struk == null) {
    history.push(
      "/pln"
    );
    let pathUrl = window.location.href;
    window.location.href = pathUrl;
  }

  const getData = async () => {
    // await setUseSaldo(false);
    // var balance = await state.balance;
    await setIsLoading(true);
    await setTransaction_id(struk.data.trx_id);

    try {
      let totalBayar = 0;
      if (struk.data.code == "PLNPOSTPAID") {
        await setProdukName("PLN Postpaid");
        await setProduct_id(struk.produk);
        await setIdpel(struk.data.subscriber_id);
        await setHarga(struk.data.amount);
        await setAdmin_carge(struk.data.admin_charge);
        totalBayar =
          parseInt(struk.data.amount) +
          parseInt(struk.data.admin_charge);
        // totalBayar = 12000000;
        await setHandling_fee(struk.data.handling_fee);
      } else if (struk.data.code == "TOKENPLN") {
        await setProdukName("PLN Prepaid");
        await setProduct_id(struk.produk);
        await setIdpel(struk.data.subscriber_id);
        await setHarga(struk.product_nomination);
        await setProductNomination(struk.product_nomination);
        await setAdmin_carge(struk.data.admin_charge);
        totalBayar =
          parseInt(struk.product_nomination) +
          parseInt(struk.data.admin_charge)
          ;
        await setHandling_fee(struk.data.handling_fee);
      } else if (struk.data.code == "BPJS") {
        await setProdukName("BPJS Kesehatan");
        await setProduct_id(struk.produk);
        await setIdpel(struk.data.no_va);
        await setHarga(struk.data.premi);
        await setPeriodePayment(struk.periode);
        await setAdmin_carge(struk.data.admin_charge);
        totalBayar =
          parseInt(struk.data.premi) +
          parseInt(struk.data.admin_charge);
        await setHandling_fee(struk.data.handling_fee);
      } else if (struk.data.code == "SPEEDY") {
        await setProdukName("Internet (Indihome/Speedy)");
        await setProduct_id(struk.produk);
        await setIdpel(struk.data.id_pelanggan);
        await setHarga(struk.data.jumlah_tagihan);
        await setPeriodePayment(struk.data.bulan_thn);
        await setAdmin_carge(struk.data.jumlah_adm);
        totalBayar =
          parseInt(struk.data.jumlah_tagihan) +
          parseInt(struk.data.jumlah_adm);
        // totalBayar = 999999999999;
        await setHandling_fee(struk.data.handling_fee);
      } else if (struk.data.code == "TELKOM") {
        await setProdukName("Telepon Rumah");
        await setProduct_id(struk.produk);
        await setIdpel(struk.data.id_pelanggan);
        await setHarga(struk.data.jumlah_tagihan);
        await setPeriodePayment(struk.bulan_thn);
        await setAdmin_carge(struk.data.jumlah_adm);
        totalBayar =
          parseInt(struk.data.jumlah_tagihan) +
          parseInt(struk.data.jumlah_adm);
        await setHandling_fee(struk.data.handling_fee);
      } else if (struk.data.code == "PDAM") {
        await setProdukName("PDAM");
        await setProduct_id(struk.product_id);
        await setIdpel(struk.data.idpel);
        await setHarga(parseInt(struk.data.rp_tag));
        await setPeriodePayment(struk.blth);
        await setAdmin_carge(parseInt(struk.data.admin_charge));
        totalBayar =
          parseInt(struk.data.rp_tag) +
          parseInt(struk.data.admin_charge);
        await setHandling_fee(struk.data.handling_fee);
      } else {
        if (
          struk.data.product_type == "PULSA" ||
          struk.data.product_type == "PAKET DATA"
        ) {
          if (struk.data.product_type == "PULSA") {
            await setProdukName("Pulsa");
          } else {
            await setProdukName("Paket Data");
          }
          await setProduct_id(struk.product_id);
          await setIdpel(struk.data.msisdn);
          await setHarga(struk.data.price);
          await setPeriodePayment("");
          await setAdmin_carge(struk.data.admin_fee);
          totalBayar =
            parseInt(struk.data.price) + parseInt(struk.data.admin_fee);
          await setHandling_fee(struk.data.handling_fee);
        }
      }
      if (user) {
        await setTotal(totalBayar);
      } else {
        await setTotal(totalBayar + parseInt(struk.data.handling_fee));
      }
    } catch (e) {
      console.warn("error", e);
    }
    setIsLoading(false);
  };

  const getPromo = async () => {
    await setUsePromo(JSON.parse(localStorage.getItem("mydiscount")));
    let url = null;
    if (state.user) {
      url = `${process.env.REACT_APP_URL}/promos/diskon/list`;
    } else {
      url = `${process.env.REACT_APP_URL}/promos/promo/list`;
    }
    await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Barier " + state.token,
      },
    }).then((response) => {
      response.json().then((result) => {
        setPromo(result.data);
      });
    });
  };

  const setActiveDiscount = (val) => {
    localStorage.removeItem("mydiscount");
    localStorage.setItem("mydiscount", JSON.stringify(val));

    setUsePromo(val);
    handleClose();
  };

  const resetUseDisacount = () => {
    localStorage.removeItem("mydiscount");
    setUsePromo(null);
    console.log("promo terhapus");
  };

  const generateHash = () => {
    var bcrypt = require("bcryptjs");
    var salt = bcrypt.genSaltSync(10);
    var text = `${transaction_id}|2200|${idpel}`;
    console.log("text= " + text);
    var hash = bcrypt.hashSync(text, salt);
    console.log("hash= " + hash);
    return hash;
  };

  useEffect(() => {
    if (!user) {
      setUseSaldo(false);
    }
    getPromo();
    getData();
  }, []);

  const ProcesPaymentSaldo = async () => {
    await setIsLoading(true);
    Swal.fire({
      title: "Lanjutkan Pembayaran Dengan Saldo ?",
      text: "",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Lanjut",
      cancelButtonText: "Nanti Dulu",
    }).then((result) => {
      if (result.value) {
        hitData();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //cencle
      }
    });
    await setIsLoading(false);
  };

  const ProcesPayment = async () => {
    await setIsLoading(true);
    Swal.fire({
      title: "Lanjutkan Pembayaran?",
      text: "",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Lanjut",
      cancelButtonText: "Nanti Dulu",
    }).then((result) => {
      if (result.value) {
        // console.warn('total', total);
        // console.warn('prod nom', harga);
        hitData();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //cencle
      }
    });
    await setIsLoading(false);
  };

  const hitData = async () => {
    await setIsLoading(true);

    //create hash to keytransaction
    var bcrypt = require("bcryptjs");
    var salt = bcrypt.genSaltSync(10);
    var text = `${transaction_id}|2200|${idpel}|${harga}|${admin_charge}|${handling_fee}`;
    var hash = bcrypt.hashSync(text, salt);

    //provide token guest and member
    let token = null;
    if (!state.isAuthenticated) {
      // console.log("token env");
      token = process.env.REACT_APP_TOKEN;
    } else {
      // console.log("token response");
      token = state.token;
    }


    //provide url to send transaction data
    let url = `${process.env.REACT_APP_URL}/transactions/wh/guest/merchant`;
    // console.log("pakaisaldooo => " + useSaldo);
    if (state.isAuthenticated) {
      if (useSaldo) {
        // console.log("url saldo member");
        url = `${process.env.REACT_APP_URL}/transactions/wh/member/saldo`;
      } else {
        // console.log("url normal member");
        url = `${process.env.REACT_APP_URL}/transactions/wh/member/merchant`;
      }
    } else {
      // console.log("url guest multibank");
      let url = `${process.env.REACT_APP_URL}/transactions/wh/guest/merchant`;
    }

    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Barier " + token,
        Accept: "application/json",
      },
      body: JSON.stringify({
        trx_type: "2200",
        idpel: idpel,
        produkName: produkName,
        product_id: product_id,
        product_nomination: struk.product_nomination || "",
        harga: harga,
        periode_payment: struk.periode_payment || "",
        email: email || state.email,
        admin: admin_charge,
        trx_id: "",
        keyinquiry: transaction_id,
        handling_fee: handling_fee,
        media: `${process.env.REACT_APP_PARAM}`,
        // amount: total-(((usePromo.diskon*admin_charge)/100)),
        keystore_origin: text,
        keystore: hash,
        amount: total,
        payment_method: useSaldo ? "saldo" : "xendit",
        type: state.isAuthenticated ? "member" : "guest",
        description: "PAYMENT " + produkName,
        code_discount: usePromo != null ? usePromo.kode : "",
      }),
    }).then((response) => {
      response.json().then((result) => {
        console.warn("result", result);
        if (result.status == "200") {
          localStorage.setItem(
            "private_payment",
            JSON.stringify({
              data: result.data,
              produk: product_id,
              product_nomination: productNomination,
              product_type: produkName,
            })
          );
          // alert("success");
          console.log(state);
          if (state.isAuthenticated) {
            if (useSaldo) {
              // alert("berhasil pakai saldo");
              history.push(
                "/invoice/" + result.data.transactions.transaction_id
              );
              let pathUrl = window.location.href;
              window.location.href = pathUrl;
            } else {
              history.push("/payment");
              let pathUrl = window.location.href;
              window.location.href = pathUrl;
            }
          } else {
            // alert("masuk else transaksi tanpa login");
            // console.log("RESULT DATANYA => " + result.data.transaction_id);
            history.push("/payment");
            let pathUrl = window.location.href;
            window.location.href = pathUrl;
          }
        } else {
          Swal.fire({
            title: "Upss!",
            text: result.message[0].msg || result.message,
            icon: "error",
            confirmButtonText: "oke",
          });
        }
      });
    });
    await setIsLoading(false);
  };

  const MetodePayment = async (value) => {
    alert("mantap" + value);
  };

  return (
    <div>
      <section className="section g-bg-gray p-0px-t section-top-up-100">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-xs-12">
              {/* <div className="col-sm-12 col-lg-12 col-12 m-15px-tb"> */}
              <div className="card border-radius-20">
                <div className="card-header bg-light">
                  <h5>Detail Tagihan {produkName}</h5>
                </div>
                <div className="card-body">
                  <table className="table small ">
                    <tbody>
                      <ProdukInquiry
                        produk={struk.produk}
                        data={struk.data}
                        fulldata={struk}
                        adminBank={state.adminBank}
                      />
                      <tr>
                        <td className="h6">SUBTOTAL</td>
                        <td className="text-right">
                          <NumberFormat
                            value={useSaldo ? parseInt(total) : parseInt(total) - 4500}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp. "}
                            renderText={(value) => <h6>{value}</h6>}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-header bg-light">
                  <h5>Pembayaran</h5>
                </div>
                <div className="card-body">
                  {user ? (
                    <div>
                      <label className="text-dark mt-2">Metode Pembayaran</label>
                      <div className="alert alert-info">
                        <div className="row">
                          <div className="col-md-8">
                            <span className="font-weight-bold">Saldo Cuan</span><br></br>
                            <NumberFormat
                              value={state.balance}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rp. "}
                              className="font-weight-bold text-warning"
                              renderText={(value) => <div>{value}</div>}
                            />
                            {state.balance < total ? (
                              <small className="text-danger">Saldo tidak mencukupi</small>
                            ) : (<></>)}
                          </div>
                          <div className="col-md-4">
                            {state.balance < total ? (
                              <>
                                <NavLink className="nav-link btn btn-danger btn-sm float-right mt-4 btn-block" to="/cpmtr/topup">Top Up</NavLink>
                              </>) : (
                              <>
                                {useSaldo == 1 ? (<><FaCheckCircle className="text-success float-right fa-lg mt-3"></FaCheckCircle></>) : (<>
                                  <button className="btn btn-info btn-sm float-right mt-2 btn-block" onClick={(e) => {
                                    setUseSaldo(1);
                                    setTotal(parseInt(total) - parseInt(handling_fee));
                                  }}>Gunakan</button>
                                </>)}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="alert alert-info">
                        <div className="row">
                          <div className="col-md-8">
                            <span className="font-weight-bold">Transfer Bank</span>
                          </div>
                          <div className="col-md-4">
                            {useSaldo == 0 ? (<><FaCheckCircle className="text-success float-right fa-lg"></FaCheckCircle></>) : (
                              <>
                                <button className="btn btn-info btn-sm float-right btn-block" onClick={(e) => {
                                  setUseSaldo(0);
                                  setTotal(parseInt(total) + parseInt(handling_fee));
                                }}>Gunakan</button>
                              </>)}
                          </div>

                        </div>
                      </div>

                    </div>
                  ) : (
                    <div>
                      <div className="alert alert-primary">
                        <p align="justify" style={{ fontSize: "14px" }}>Daftar gratis sekarang dan topup saldo untuk menikmati pembayaran tanpa ada tambahan biaya transaksi sebesar
                          <NumberFormat
                            value={handling_fee}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={" Rp. "}
                            renderText={(value) => <b>{value}</b>}
                          /></p>
                      </div>
                    </div>
                  )}
                  <table className="table small table-borderless">
                    <tbody>
                      {usePromo ? (
                        <>
                          {usePromo.product_id == struk.produk ||
                            usePromo.product_id == null ? (
                            <>
                              <tr>
                                <td className="text-success">
                                  KODE PROMO({" "}
                                  {usePromo.kode + " " + usePromo.diskon + "%"}{" "}
                                  )
                                </td>
                                {/* <td className="text-success"> */}
                                <NumberFormat
                                  value={(usePromo.diskon * admin_charge) / 100}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"Rp. "}
                                  renderText={(value) => (
                                    <td className="float-right text-success font-weight-bold">
                                      - {value}
                                    </td>
                                  )}
                                />
                                {/* </td> */}
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      {!useSaldo ? (
                        <>
                          <tr>
                            <td className="font-weight-bold h6">BIAYA TRANSAKSI </td>
                            <td className="text-right"><NumberFormat
                              value={handling_fee}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rp. "}
                              renderText={(value) => <h6>{value}</h6>}
                            /></td>
                          </tr>
                        </>
                      ) : (
                        <></>
                      )}
                      {/* {!state.balance && useSaldo == 1 ? (
                        <>
                          <tr>
                            <td className="font-weight-bold h6">BIAYA TRANSAKSI </td>
                            <td className="text-right"><NumberFormat
                              value={handling_fee}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rp. "}
                              renderText={(value) => <h6>{value}</h6>}
                            /></td>
                          </tr>
                        </>
                      ) : (
                        <></>
                      )} */}

                      <tr>
                        <td className="font-weight-bold h6">TOTAL BAYAR </td>
                        <td className="text-right">
                          {usePromo ? (
                            <>
                              {usePromo.product_id == struk.produk ||
                                usePromo.product_id == null ? (
                                <div>
                                  <NumberFormat
                                    value={
                                      total -
                                      (usePromo.diskon * admin_charge) / 100
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"Rp. "}
                                    renderText={(value) => <h6>{value}</h6>}
                                  />
                                  <NumberFormat
                                    value={total}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"Rp. "}
                                    renderText={(value) => <s>{value}</s>}
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <>
                              <NumberFormat
                                value={total}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rp. "}
                                renderText={(value) => <h6>{value}</h6>}
                              />
                            </>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {promo && promo.length > 0 ? (
                    <>
                      <div
                        className="card mt-2 border-primary"
                        style={{ backgroundColor: "#dbeefd" }}>
                        <div className="row my-2 mx-2">
                          <div className="col-md-9 col-9 m-auto font-wight-bold text-primary">
                            {usePromo ? (
                              <>
                                Kode Promo : <b>{usePromo.kode}</b>
                                <br />
                                Diskon : <b>{usePromo.diskon}%</b>
                                {usePromo.product_id != struk.produk &&
                                  usePromo.product_id != null ? (
                                  <span className="small text-danger">
                                    <br />
                                    Promo tidak dapat digunakan pada produk{" "}
                                    {produkName}
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <div>
                                Kamu punya {promo.length} Kode Promo Dan Diskon
                              </div>
                            )}
                          </div>
                          <div className="col-md-3 col-3 text-right">
                            {promo ? (
                              usePromo ? (
                                <a
                                  href="#"
                                  className="btn btn-primary btn-sm w-100 mt-2"
                                  onClick={() => resetUseDisacount()}>
                                  Batal
                                </a>
                              ) : (
                                <a
                                  href="#"
                                  onClick={handleShow}
                                  className="btn btn-primary btn-sm  w-100 text-white">
                                  Lihat
                                </a>
                              )
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* Promo Menarik dengan meningkatkan transaksi anda{" "} */}
                    </>
                  )}

                  <div className="form-group mt-2">
                    <label htmlFor="Email" className="text-dark mt-2">
                      Email Penerima Struk
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={state.email}
                      placeholder="example@email.com"
                      onKeyUp={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="card-footer">
                  <LaddaButton
                    loading={isLoading}
                    onClick={() => {
                      ProcesPayment();
                    }}
                    disabled={useSaldo == 1 && state.balance < total ? "disabled" : ""}
                    data-color="#008aff"
                    data-size={l}
                    className="m-btn m-btn-theme m-btn-radius w-100"
                    data-style={EXPAND_RIGHT}>
                    Bayar Sekarang
                  </LaddaButton>
                </div>
              </div>
            </div>
            <Modal
              show={show}
              onHide={handleClose}
              size="lg"
              scrollable={true}
              xaria-labelledby="contained-modal-title-vcenter"
              centered>
              <Modal.Header>
                <Modal.Title>Promo Dan Diskon</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  {promo ? (
                    promo.map(function (value, i) {
                      return (
                        <div className="col-md-12 mt-2" key={i}>
                          <div
                            className="card border-primary"
                            style={{ backgroundColor: "#dbeefd" }}>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-8 col-8 m-auto text-primary">
                                  <h5 className="text-primary">{value.kode}</h5>
                                  <p className="small">
                                    <b className="ml-4">{"Keterangan :"}</b>
                                    <br />
                                    <ol>
                                      {value.product_name ? (
                                        <>
                                          <li>
                                            {"Produk : " +
                                              value.product_name.toUpperCase()}
                                          </li>
                                          <li>
                                            Diskon Biaya Admin {value.diskon} %
                                          </li>
                                        </>
                                      ) : (
                                        <>
                                          <li>Semua Produk</li>
                                          <li>
                                            Diskon Biaya Admin {value.diskon} %
                                          </li>
                                        </>
                                      )}
                                      <li>
                                        Gunakan Sebelum :{" "}
                                        <Moment format="DD MMM YYYY">
                                          {value.due_date}
                                        </Moment>
                                      </li>
                                      <li>
                                        Maksimal Kuota : {value.pemakaian}{" "}
                                        transaksi
                                      </li>
                                      <li>
                                        Hanya dapat digunakan di aplikasi web
                                      </li>
                                    </ol>
                                  </p>
                                </div>
                                <div className="col-md-4 col-4 text-center justify-content-center">
                                  {value.product_id == null ||
                                    value.product_id == struk.produk ? (
                                    <>
                                      <h3 className="m-auto text-danger">
                                        {value.diskon} %
                                      </h3>
                                      <a
                                        className="btn btn-primary mx-auto text-white mt-2"
                                        onClick={() =>
                                          setActiveDiscount(value)
                                        }>
                                        Gunakan Promo
                                      </a>
                                    </>
                                  ) : (
                                    <div className="mt-4">
                                      <span className="text-danger mx-auto small">
                                        Tidak dapat digunakan pada produk <br />
                                        {produkName}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div></div>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
          </div>
        </div>
        {/* </div> */}
      </section>
    </div>
  );
}

export default Checkoutv1;
