import React from 'react';

function Report(props) {
    return (
        <div>
            <main className="container pt-5 mt-5">
               
               <nav aria-label="breadcrumb">
                   <ol className="breadcrumb">
                       <li className="breadcrumb-item active" aria-current="page">Admin</li>
                       <li className="breadcrumb-item active" aria-current="page">Report</li>
                   </ol>
               </nav>

               <div className="card my-2">
                   <div className="card-body">
                       Page Report
                   </div>
               </div>
           </main>
        </div>
    );
}

export default Report;