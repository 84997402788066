import React from 'react';
import NumberFormat from 'react-number-format';
import Moment from "react-moment";

export const PDAM = (props) => (
    <>
        <tr>
            <td width="35%">ID PELANGGAN</td>
            <td className="text-right">{props.struk.idpel}</td>
        </tr>
        <tr>
            <td>NAMA</td>
            <td className="text-right">{props.struk.name}</td>
        </tr>

        <tr>
            <td>BL/TH</td>
            {/* <td>{props.struk.blth.substr(4,2)}/{props.struk.blth.substr(0,4)} - {props.struk.blth.substr(10,2)}/{props.struk.blth.substr(6,4)}</td> */}
            <td className="text-right">{props.struk.blth}</td>
        </tr>
        <tr>
            <td>NO REF</td>
            <td className="text-right">{props.struk.switching_ref}</td>
        </tr>
        <tr>
            <td>WAKTU LUNAS</td>
            <td className="text-right"><Moment format="DD MMM YYYY HH:mm:ss">{props.struk.waktu_lunas.substr(0, 4) + "-" + props.struk.waktu_lunas.substr(4, 2) + "-" + props.struk.waktu_lunas.substr(6, 2) + " " + props.struk.waktu_lunas.substr(8, 2) + ":" + props.struk.waktu_lunas.substr(10, 2) + ":" + props.struk.waktu_lunas.substr(12, 2)}</Moment></td>
        </tr>

        <tr>
            <td>RP TAG</td>
            <td className="text-right"><NumberFormat value={parseInt(props.struk.rp_tag)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>

        <tr>
            <td>ADM BANK</td>
            <td className="text-right"><NumberFormat value={parseInt(props.struk.admin_charge)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>

        <tr>
            <td>BIAYA TRANSAKSI</td>
            <td className="text-right"><NumberFormat value={parseFloat(props.transaction.handling_fee)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>
        <tr>
            <td className="font-weight-bold h6">SUBTOTAL</td>
            {props.discount.code ? (
                <>
                    <td className="font-weight-bold h6 text-right"><NumberFormat value={parseFloat(props.transaction.amount) + parseFloat(props.discount.admin_fee_before_discount)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
                </>
            ) : (<>
                <td className="font-weight-bold h6 text-right"><NumberFormat value={parseFloat(props.transaction.amount)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
            </>)}
        </tr>
        <tr>
            {props.discount.code ? (
                <>
                    <td className="font-weight-bold">PROMO BIAYA ADMIN <br /><span className="text-success">( {props.discount.code}  {props.discount.discount}% ) </span></td>
                    <td className="font-weight-bold h6 text-right align-middle"><NumberFormat value={parseFloat(props.discount.admin_fee_before_discount) - parseFloat(props.discount.admin_fee_after_discount)} displayType={'text'} thousandSeparator={true} prefix={'- Rp. '} renderText={value => <div className="text-success">{value}</div>} /></td>
                </>

            ) : (<>
                <td className="font-weight-bold">PROMO BIAYA ADMIN</td>
                <td className="font-weight-bold h6 text-right align-middle"><NumberFormat value={"0"} displayType={'text'} thousandSeparator={true} prefix={'- Rp. '} renderText={value => <div className="text-success">{value}</div>} /></td>
            </>)}
        </tr>
        <tr>
            <td className="font-weight-bold h6">TOTAL</td>
            <td className="font-weight-bold h6 text-right"><NumberFormat value={parseFloat(props.transaction.amount)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
        </tr>
    </>
);