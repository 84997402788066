import React, { useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../../router/Router';
import { Redirect } from 'react-router-dom';
import { FaUserCircle, FaChartLine, FaListOl, FaShoppingCart } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";

function MenuAdmin(props) {
  const { register, handleSubmit, errors } = useForm();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const onSubmit = () => {
    fetch(`${process.env.REACT_APP_URL}/users/` + user_id, {
      method: "put",
      headers: {
        'Authorization': "Barier " + token,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        "name": fullnamef,
        "email": emailf,
        "username": username,
        "phone": phonef,
        "update_password": updatepass,
        "old_password": oldpassword,
        "new_password": newpassword
      }),
    }).then((response) => {
      response.json().then((result) => {
        if (result.status == "200") {
          Swal.fire({
            title: 'Sukses Disimpan',
            text: result.data.desc,
            icon: 'success',
            confirmButtonText: 'oke'
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: result.message,
            icon: 'error',
            confirmButtonText: 'oke'
          });
        }
        console.warn(result);
      })
    })
  };

  const { dispatch } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const user = localStorage.getItem("user");
  const fullname = localStorage.getItem("fullname");
  const phone = localStorage.getItem("phone");
  const role = localStorage.getItem("role");
  const email = localStorage.getItem("email");

  const [username, setUsername] = useState(user);
  const [fullnamef, setFullname] = useState(fullname);
  const [emailf, setEmail] = useState(email);
  const [phonef, setPhone] = useState(phone);
  const [oldpassword, setOldPassword] = useState(null);
  const [newpassword, setNewPassword] = useState(null);
  const [updatepass, setUpdatepass] = useState(false);
  const update = () => setUpdatepass(!updatepass);
  if (!user && !token) {
    return <Redirect to="/login" />;
  }
  // const {state} = useContext(AuthContext);
  // if(!state.isAuthenticated){
  //     return <Redirect to="/login" />
  // }
  const roles = "ADMIN";
  function handleClickProfile(e) {
    e.preventDefault();
    console.log('The link was clicked.');
    Swal.fire({
      title: 'Detail Profil',
      icon: 'info',
      confirmButtonText: '<a href="profile" style="color: white;">Ganti Password</a>',
      cancelButtonText: 'Tutup',
      showConfirmButton: false,
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: '#3085d6',
      html: `
        <table id="table" border=0 style="width:100%; text-align: left; padding: 15px;">
          <tbody>
              <tr>
                  <td width="35%">Username</td>
                  <td>: `+ user + `</td>
              </tr>
              <tr>
                  <td>Name Lengkap</td>
                  <td>: `+ fullname + `</td>
              </tr>
              <tr>
                  <td>Nomor HP</td>
                  <td>: `+ phone + `</td>
              </tr>
              <tr>
                  <td>Hak Akses</td>
                  <td>: `+ roles + `</td>
              </tr>
          </tbody>
        </table>`
    })
  }
  return (
    <div>
      <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
        <NavLink to="/cpadm/dashboard" className="navbar-brand">
          Dashboard
        </NavLink>
        <button
          className="navbar-toggler bg-dark"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item px-dropdown">
              <a
                className="nav-link"
                href="#"
                id="master"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <FaListOl className="mr-2 ml-2" />
                Master
              </a>
              <div className="px-dropdown-menu w-120" aria-labelledby="master">
                <NavLink className="dropdown-item" to="/cpadm/role">
                  Role
                </NavLink>
                <NavLink className="dropdown-item" to="/cpadm/users">
                  Users
                </NavLink>
                <NavLink className="dropdown-item" to="/cpadm/produk">
                  Produk
                </NavLink>
                <NavLink className="dropdown-item" to="/cpadm/produkcat">
                  Kategori Produk
                </NavLink>
                <NavLink className="dropdown-item" to="/cpadm/produkpri">
                  Harga Produk
                </NavLink>
                <NavLink className="dropdown-item" to="/cpadm/denom">
                  Denom PLN
                </NavLink>
                <NavLink className="dropdown-item" to="/cpadm/diskonproduk">
                  Diskon Produk
                </NavLink>
              </div>
            </li>
            <li className="nav-item px-dropdown">
              <a
                className="nav-link"
                href="#"
                id="transaksi"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <FaShoppingCart className="mr-2" />
                Transaksi
              </a>
              <div className="px-dropdown-menu w-120" aria-labelledby="transaksi">
                <NavLink className="dropdown-item" to="/cpadm/mantopupsaldo">
                  Topup Saldo
                </NavLink>
                <NavLink className="dropdown-item" to="/cpadm/refound">
                  Refund Saldo
                </NavLink>

              </div>
            </li>
            <li className="nav-item px-dropdown">
              <a
                className="nav-link"
                href="#"
                id="report"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <FaChartLine className="mr-2" />
                Laporan
              </a>
              <div className="px-dropdown-menu w-120" aria-labelledby="report">
                <NavLink
                  className="dropdown-item"
                  to="/cpadm/report-transaction"
                >
                  Transaksi
                </NavLink>
                <NavLink className="dropdown-item" to="/cpadm/report-mutation">
                  Mutasi
                </NavLink>
              </div>
            </li>
          </ul>
          <form className="form-inline mt-2 mt-md-0">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item px-dropdown">
                <a
                  className="nav-link"
                  href="#"
                  id="acount"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <FaUserCircle className="mr-2" />
                  {user ? user : "Akun"}
                </a>
                <div className="px-dropdown-menu w-120" aria-labelledby="acount">
                  <a className="dropdown-item" href="#"
                    onClick={handleClickProfile}
                  >
                    Profil
                  </a>
                  <a className="dropdown-item" href="#"
                    onClick={handleShow}
                  >
                    Pengaturan
                  </a>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() =>
                      dispatch({
                        type: "LOGOUT",
                      })
                    }
                  >
                    Keluar
                  </a>
                </div>
              </li>
            </ul>
          </form>
        </div>
      </nav>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Pengaturan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <LoginForm onSubmit={onLoginFormSubmit} /> */}
          <div className="container">
            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-5">
                    <label htmlFor="inputForName">Username</label>
                    <span className="mandatory">*</span>
                  </div>
                  <div className="col-md-7">
                    <input
                      id="inputForUsername"
                      name="username"
                      type="text"
                      className="form-control form-control-sm"
                      aria-describedby="Masukkan username"
                      placeholder="Masukkan username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      ref={register({
                        required: {
                          value: true,
                          message: "Username masih kosong",
                        },
                        minLength: {
                          value: 6,
                          message: "Minimum username 6 karakter",
                        },
                      })}
                    />
                    {errors.username && (
                      <span className='small text-danger text-center'>
                        {errors.username.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-md-5">
                    <label htmlFor="inputForFullname">Nama Lengkap</label>
                    <span className="mandatory">*</span>
                  </div>
                  <div className="col-md-7">
                    <input
                      id="inputForFullname"
                      name="fullname"
                      type="fullname"
                      className="form-control form-control-sm"
                      aria-describedby="Masukkan nama lengkap"
                      placeholder="Masukkan nama lengkap"
                      value={fullnamef}
                      onChange={(e) => setFullname(e.target.value)}
                      ref={register({
                        required: {
                          value: true,
                          message: "Nama lengkap masih kosogn",
                        },
                        maxLength: {
                          value: 100,
                          message: "Maksimal nama lengkap 100 karakter",
                        },
                      })}
                    />
                    {errors.fullname && (
                      <span className='small text-danger text-center'>
                        {errors.fullname.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col-md-5">
                    <label htmlFor="inputForName">Email</label>
                    <span className="mandatory">*</span>
                  </div>
                  <div className="col-md-7">
                    <input
                      id="inputForEmail"
                      name="email"
                      type="email"
                      className="form-control form-control-sm"
                      aria-describedby="Masukkan email"
                      placeholder="Masukkan email"
                      value={emailf}
                      onChange={(e) => setEmail(e.target.value)}
                      ref={register({
                        required: {
                          value: true,
                          message: "Email masih kosong",
                        },
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Format email tidak sesuai",
                        },
                      })}
                    />
                    {errors.email && (
                      <span className='small text-danger text-center'>
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col-md-5">
                    <label htmlFor="inputForName">Nomor HP</label>
                    <span className="mandatory">*</span>
                  </div>
                  <div className="col-md-7">
                    <input
                      id="inputForPhone"
                      name="phone"
                      type="text"
                      className="form-control form-control-sm"
                      aria-describedby="Masukkan nomor HP"
                      placeholder="Masukkan nomor HP"
                      value={phonef}
                      onChange={(e) => setPhone(e.target.value)}
                      ref={register({
                        required: {
                          value: true,
                          message: "Nomor HP masih kosong",
                        },
                        minLength: {
                          value: 11,
                          message: "Minimum nomor HP 11 digit",
                        },
                      })}
                    />
                    {errors.phone && (
                      <span className='small text-danger text-center'>
                        {errors.phone.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-md-1">
                    <input
                      name="checkbox"
                      type="checkbox"
                      checked={updatepass}
                      onChange={() => setUpdatepass(!updatepass)}
                    />
                  </div>
                  <div className="col-md-11">
                    <label htmlFor="inputForOldPassword">Centang jika password diubah</label>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-md-5">
                    <label htmlFor="inputForOldPassword">Password Lama</label>
                    <span className="mandatory">*</span>
                  </div>
                  <div className="col-md-7">
                    <input
                      type="oldpassword"
                      name="oldpassword"
                      className="form-control form-control-sm"
                      id="inputForOldPassword"
                      placeholder="Masukkan password lama"
                      disabled={!updatepass}
                      value={oldpassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      ref={register({
                        required: {
                          value: true,
                          message: "Password lama masih kosong",
                        },
                      })}
                    />
                    {errors.oldpassword && (
                      <span className='small text-danger text-center'>
                        {errors.oldpassword.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col-md-5">
                    <label htmlFor="inputForNewPassword">Password Baru</label>
                    <span className="mandatory">*</span>
                  </div>
                  <div className="col-md-7">
                    <input
                      type="newpassword"
                      name="newpassword"
                      className="form-control form-control-sm"
                      id="inputForNewPassword"
                      placeholder="Masukkan password baru"
                      disabled={!updatepass}
                      value={newpassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      ref={register({
                        required: {
                          value: true,
                          message: "Password masih kosong",
                        },
                        minLength: {
                          value: 6,
                          message: "Minimum password 6 karakter",
                        },
                        maxLength: {
                          value: 100,
                          message: "Maksimal password 100 karakter",
                        },
                      })}
                    />
                    {errors.newpassword && (
                      <span className='small text-danger text-center'>
                        {errors.newpassword.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-primary btn-block">
                Simpan
              </button>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Batal
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MenuAdmin;