import React, { useState, useEffect, useContext } from "react";
import ReactTable from "react-table";
import { AuthContext } from '../../../router/Router';
import ReactLoading from "react-loading";
import { FaTrash, FaEdit, FaPlus, FaSearch } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import { Redirect } from 'react-router-dom';
import Swal from "sweetalert2";
import Select from "react-select";
import LaddaButton from "react-ladda";
import axios from "axios";


function Users(props) {
  const { state } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState({ display: false, type: "" });
  const [toogleBtn, setToogleBtn] = useState(true);
  const [txtForm, setTxtForm] = useState("Tambah");
  const [isAuth, setAuth] = useState(0);
  const [isStatusRole, setStatusRole] = useState({ value: "", label: "" });
  const [displayAlert, setDisplayAlert] = useState({
    display: "none",
    type: "",
    message: "",
  });
  const [userState, setUserState] = useState({
    id: 0,
    full_name: "",
    phone: "",
    email: "",
    username: "",
    password:"",
    roles_id: 1,
    // active: 1,
  });
  const [isUpdate, setUpdate] = useState(0);
  const [isSearchInput, setSearchInput] = useState("");
  const [isReload, setReload] = useState(false);
  const [isDataUser, setIsDataUser] = useState([]);
  const [passwordMessage, setPasswordMessage] = useState("");
  const [isDataRoleId, setIsDataRoleId] = useState([]);
  const [isRoleId, setRoleId] = useState({ value: "", label: "" });
  const token = localStorage.getItem("token");
  const Loading = ({ type, color }) => (
    <ReactLoading
      type={type}
      color={color}
      className="text-center"
      height={"4%"}
      width={"4%"}
      className="d-flex justify-content-center"
    />
  );
  useEffect(() => {
    setIsLoading(true);
    fetchUsers();
    fetchRoleId();
    setIsLoading(false);
  }, [isSearchInput]);
  const fetchUsers = async () => {
    let searchInput = await isSearchInput;
    await axios
      .get(`${process.env.REACT_APP_URL}/users`, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if(searchInput == ""){
          setIsDataUser([...res.data.data]);
        }else{
          let filteredData = res.data.data.filter((value) => {
          return (
              value.username.toString().toLowerCase().includes(searchInput.toString().toLowerCase()) ||
              value.name.toString().toLowerCase().includes(searchInput.toString().toLowerCase()) ||
              value.phone.toString().toLowerCase().includes(searchInput.toString().toLowerCase()) ||
              value.email.toString().toLowerCase().includes(searchInput.toString().toLowerCase())
            );
          });
          setIsDataUser([...filteredData]);
        }
        console.warn("res user", res.data.data);
        setReload(true);
      }).catch((err) => {
        console.log(err.response.data)
        Swal.fire({
          title: "Uppss..",
          text: err.response.data.message,
          icon: "error",
          confirmButtonText: "Oke",
        });
        setAuth(err.response.data.status)
      })
  };
  const fetchRoleId = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL}/roles`, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setIsDataRoleId([...res.data.data]);
        console.warn("res role id", res.data.data);
      });
  };
  const columns = [
    {
      Header: "#",
      maxWidth: 50,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      },
      style: {
        textAlign: "center",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "USERNAME",
      accessor: "username",
      style: {
        textAlign: "left",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "NAMA LENGKAP",
      accessor: "name",
      maxWidth: "5%",
      style: {
        textAlign: "left",
        textTransform: "uppercase",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "NO TELEPON",
      accessor: "phone",
      style: {
        textAlign: "left",
        flexDirection: "column",
        justifyContent: "center",
      },
    },

    {
      Header: "ROLE",
      accessor: "roleid",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
      Cell: (row) => (
        <div>
          {row.original.roleid == 1 ? (
            <span
              className="badge badge-success"
              style={{
                fontSize: "12px",
                letterSpacing: "1px",
              }}
            >
              Admin
            </span>
          ) : (
            <span
              className="badge badge-danger"
              style={{
                fontSize: "12px",
                letterSpacing: "1px",
              }}
            >
              Mitra
            </span>
          )}
        </div>
      ),
    },
    {
      Header: "EMAIL",
      accessor: "email",
      style: {
        textAlign: "left",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    {
      Header: "AKSI",
      accessor: "id",
      style: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
      filterable: false,
      Cell: (row) => (
        <div>
          <button
            className="btn btn-warning btn-flat btn-sm"
            onClick={() => showModal("Edit", row.original)}
            title="Edit"
          >
            <FaEdit className="text-white text-center" />
          </button>
          &nbsp;
          <button
            onClick={() => confirmDelete(row.original.id)}
            className="btn btn-danger btn-flat btn-sm"
            title="Hapus"
          >
            <FaTrash className="text-white text-center" />
          </button>
        </div>
      ),
    },
  ];
  const showModal = (type, data) => {
    if (type == "Edit") {
      setTxtForm("Edit");
      setUserState({
        id: data.id,
        full_name: data.name,
        phone: data.phone,
        email: data.email,
        username: data.username,
        password: "",
        roles_id:data.roleid
        // role: data.role,
      });

      if (data.active == 1) {
        setToogleBtn(true);
      } else {
        setToogleBtn(false);
      }
      if (data.role == 1) {
        setStatusRole({ value: 1, label: "Admin" });
      } else {
        setStatusRole({ value: 0, label: "Umum" });
      }
      setPasswordMessage("Masukkan password sebelumnya jika tidak ada perubahan");
      console.warn("userState", userState);
    } else {
      setTxtForm("Tambah");
      resetForm();
      setPasswordMessage("");
      setToogleBtn(true);
      
    }
    setIsOpen({ display: true, type: type });
  };
  const handleChange = (e) => {
    setUserState({ ...userState, [e.target.name]: e.target.value });
  };
  const optRoleId = isDataRoleId.map((d) => ({
    value: d.id,
    label: d.id + " - " + d.name.toUpperCase(),
  }));
  const handleChangeRoleId = (data) => {
    userState.roles_id = data.value;
    setRoleId({ label: data.label, value: data.value });
  };
  const saveUser = async (typemodal) => {
    if (
      userState.username == "" ||
      userState.full_name == "" ||
      userState.phone == "" ||
      userState.email == "" ||
      userState.password == ""
    ) {
      Swal.fire({
        title: "Uppss..",
        text: "Lengkapi dahulu input yang kosong atau belum dipilih",
        icon: "error",
        confirmButtonText: "Tutup",
      });
    } else {
      if (typemodal == "Tambah") {
        await createUser();
      } else {
        await updateUser();
      }
      setTxtForm("Tambah");
      await resetForm();
      await setIsOpen({display:false, type:"Tambah"});
      await fetchUsers();
    }
  };
  const createUser = () => {
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_URL}/users`,
        {
          full_name: userState.full_name,
          email: userState.email,
          username: userState.username,
          password: userState.password,
          phone: userState.phone,
          roles_id:userState.roles_id
        },
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((json) => {
        if (json.data.status == 200) {
          showAlert("block", "success", "Data user berhasil ditambah");
        } else {
          var errors = " ";
          for (var i = 0; i < json.data.message.length; i++) {
            errors += json.data.message[i].msg + ", ";
          }
          showAlert("block", "danger", "Data user gagal ditambah. " + errors);
          console.warn("error", json.data.message);
        }
      });
    setIsLoading(false);
  };
  const updateUser = async () => {
    setIsLoading(true);
    await axios
      .put(
        `${process.env.REACT_APP_URL}/users/` + userState.id,
        {
          full_name: userState.full_name,
          email: userState.email,
          username: userState.username,
          password: userState.password,
          phone: userState.phone,
          roles_id:userState.roles_id
        },
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((json) => {
        if (json.data.status == 200) {
          showAlert("block", "success", "Data user berhasil diedit");
        } else {
           var errors = " ";
           for (var i = 0; i < json.data.message.length; i++) {
             errors += json.data.message[i].msg + ", ";
           }
           showAlert("block", "danger", "Data user gagal diedit. " + errors);
           console.warn("error", json.data.message);
        }
      });
    setIsLoading(false);
  };
  const confirmDelete = (iduser) => {
    Swal.fire({
      title: "Hapus Data ?",
      text: "",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Hapus",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.value) {
        deleteUser(iduser);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //cencle
      }
    });
  };
  const deleteUser = async (iduser) => {
     setIsLoading(true);
     await axios
       .delete(`${process.env.REACT_APP_URL}/users/` + iduser, {
         headers: {
           Authorization: "Bearer " + token,
           "Content-Type": "application/json",
         },
       })
       .then((json) => {
         if (json.data.status == 200) {
           fetchUsers();
           showAlert("block", "success", "Data user berhasil dihapus");
         } else {
           showAlert("block", "danger", "Data user berhasil dihapus");
         }
       });
     resetForm();
     setIsLoading(false);
  };
  const showAlert = (display, type, message) => {
    setDisplayAlert({
      display: display,
      type: type,
      message: message,
    });

    const timeId = setTimeout(() => {
      setDisplayAlert({ display: "none" });
    }, 5000);
    return () => {
      clearTimeout(timeId);
    };
  };
  const resetForm = () => {
    setUserState({
      id: 0,
      full_name: "",
      email: "",
      username: "",
      password: "",
      phone:""
    });
    // setProdId({ value: "", label: "" });
    setToogleBtn(true);
  };
  const styleText = () => {
    if (txtForm == "Edit") {
      return "text-danger";
    }
  };
  if(isAuth === 401){
    return <Redirect to="/login" />;
  }
  return (
    <div>
      <main className="container-fluid pt-4 mt-5">
        <nav aria-label="breadcrumb my-2">
          <ol class="breadcrumb mb-4" type="none">
            <li class="breadcrumb-item">
              <a href="#">Admin</a>
            </li>
            <li class="breadcrumb-item">
              <a href="#">Master</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Users
            </li>
          </ol>
        </nav>
        <div className="row mt-2">
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card mt-2 rounded-2 border-white shadow">
              <div className="card-header bg-light">
                <b>Data Users</b>
                <button
                  className="btn btn-primary btn-flat btn-sm float-right"
                  onClick={() => showModal("Tambah")}
                >
                  <FaPlus /> Data User
                </button>
              </div>
              <div className="card-body">
                <div
                  className={"alert alert-" + displayAlert.type}
                  style={{ display: displayAlert.display }}
                  role="alert"
                >
                  <strong>{displayAlert.message}</strong>
                </div>
                {!isReload || isLoading ? (
                  <Loading type="spinningBubbles" color="#91091e" />
                ) : (
                  <>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span
                        class="input-group-text bg-light"
                        id="inputGroup-sizing-default"
                      >
                        <FaSearch />
                      </span>
                    </div>
                    <input
                      type="text"
                      name="keyword"
                      value={isSearchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                      className="form-control"
                      aria-describedby="inputGroup-sizing-default"
                      placeholder="masukkan filter berdasarkan username, nama lengkap, no telepon atau email"
                    />
                  </div>
                  <ReactTable
                    data={isDataUser}
                    defaultPageSize={isDataUser.length >= 10 ? 10 : 5}
                    columns={columns}
                    className="mt-3 table-striped w-100"
                  />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>

      <Modal
        show={isOpen.display}
        onHide={() => setIsOpen({ display: false })}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title className={styleText()}>{isOpen.type} User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  Nama Lengkap <sup className="text-danger">*</sup>
                </label>
                <input
                  type="text"
                  name="full_name"
                  className="form-control"
                  value={userState.full_name}
                  onChange={(e) => handleChange(e)}
                  placeholder="Minimal 5 karakter"
                />
              </div>
              <div className="form-group">
                <label>
                  No Telepon <sup className="text-danger">*</sup>
                </label>
                <input
                  type="text"
                  name="phone"
                  className="form-control"
                  value={userState.phone}
                  onChange={(e) => handleChange(e)}
                  placeholder="Minimal 10 karakter maksimal 12 karakter"
                />
              </div>
              <div className="form-group">
                <label>Role</label>
                <Select
                  name="roles_id"
                  options={optRoleId}
                  // value={isProdId}
                  value={optRoleId.filter(
                    (obj) => obj.value === userState.roles_id
                  )}
                  onChange={(value) => handleChangeRoleId(value)}
                  placeholder={<div>Pilih Role</div>}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  Email <sup className="text-danger">*</sup>
                </label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  value={userState.email}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="form-group">
                <label>
                  Username <sup className="text-danger">*</sup>
                </label>
                <input
                  type="text"
                  name="username"
                  className="form-control"
                  value={userState.username}
                  onChange={(e) => handleChange(e)}
                  placeholder="Minimal 5 karakter maksimal 15 karakter"
                />
              </div>
              <div className="form-group">
                <label>
                  Password <sup className="text-danger">*</sup>
                </label>
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  value={userState.password}
                  onChange={(e) => handleChange(e)}
                />
                <small className="text-danger">{passwordMessage}</small>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setIsOpen({ display: false })}
            className="btn btn-outline-secondary"
          >
            Batal
          </button>
          <LaddaButton
            loading={isLoading}
            onClick={() => saveUser(isOpen.type)}
            className="btn btn-primary"
          >
            Simpan
          </LaddaButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Users;
