import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

function NavBack({children}) {
    return (
        <div>
        <header className="header-nav header-height header-dark header-bg border py-3">
        <div className="">
            <div className="navbar navbar-main navbar-expand-lg">
                <div className="container">
                    <NavLink className="navbar-brand"  to="/">
                       <FaArrowLeft className=""/> <span className="small">Kembali</span>
                    </NavLink>
                    <div className="collapse navbar-collapse navbar-collapse-overlay" id="navbar-main-collapse">
                        <ul className="navbar-nav ml-auto">
                            <label htmlFor="">Butuh Bantuan ?</label>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <div className="py-4">
        {children}
    </div>
    </div>
    );
}

export default NavBack;