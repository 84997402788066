import { faLaptop } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { FaAlipay, FaCalculator, FaCoins, FaLaptop, FaPager, FaUserShield } from 'react-icons/fa';


function Step(props) {
    return (
        <div>
            <section className="counter section bg-light">
            <div className="container">
                <div className="row">
                <div className="col-lg-3 col-md-6 m-15px-tb">
                    <div className="media">
                    <div className="icon-70 theme-bg white-color border-radius-50">
                        <FaCalculator className="ml-1 mt-2"/>
                    </div>
                    <div className="media-body p-15px-l align-self-center">
                        <h6 className="count h4 m-5px-b" data-to={640} data-speed={640}>640</h6>
                        <span>Total Pesanan</span>
                    </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 m-15px-tb">
                    <div className="media">
                    <div className="icon-70 theme-bg white-color border-radius-50">
                        <FaUserShield className="text-white ml-2 mt-2"/>
                    </div>
                    <div className="media-body p-15px-l align-self-center">
                        <h6 className="count h4 m-5px-b" data-to={890} data-speed={890}>890</h6>
                        <span>Pengguna</span>
                    </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 m-15px-tb">
                    <div className="media">
                    <div className="icon-70 theme-bg white-color border-radius-50">
                        <FaPager className="ml-1 mt-2" />
                    </div>
                    <div className="media-body p-15px-l align-self-center">
                        <h6 className="count h4 m-5px-b" data-to={290} data-speed={290}>40</h6>
                        <span>Total Produk</span>
                    </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 m-15px-tb">
                    <div className="media">
                    <div className="icon-70 theme-bg white-color border-radius-50">
                        <FaCoins className="text-white ml-1 mt-2"/>
                    </div>
                    <div className="media-body p-15px-l align-self-center">
                        <h6 className="count h4 m-5px-b" data-to={260} data-speed={260}>12</h6>
                        <span>Cara Pembayaran</span>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>

        </div>
    );
}

export default Step;