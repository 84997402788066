import React, { Component } from 'react';
import LaddaButton, { l, EXPAND_RIGHT } from 'react-ladda';
import { FaCheck, FaClock } from 'react-icons/fa';
import ReactLoading from 'react-loading';
import { NavLink } from 'react-router-dom';
import Moment from 'react-moment';
import Swal from 'sweetalert2'
// import 'moment-timezone';
import moment from 'moment-timezone';
// import moment from 'moment';

const Loading = ({ type, color }) => (
  <ReactLoading type={type} color={color} height={'10%'} width={'10%'} />
);

class Chektransaksi extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      login: false,
      store: null,
      created_at: null,
      idpel: null,
      response: []

    }
  }

  componentDidMount() {
    this.storeCollector()
  }

  storeCollector() {
    // let store=JSON.parse(localStorage.getItem('login'));
    let store = "CUAN-REST $2a$10$a1hNujOfYRHT6bGacbBkEOXBk476HswO8cNgLSJ6e2EtLEfYavqZG";
    this.setState({ login: true, store: store })
    // if(store && store.login)
    // {
    //   this.setState({login:true,store:store})
    // }else{

    // }
  }

  handleChangeDate = (event, e) => {
    console.log(event);
    this.setState({ created_at: event.target.value });
    console.log(this.state.created_at)
  };

  handleChangeIdpel = (event) => {
    this.setState({ idpel: event.target.value });
    console.log(this.state.idpel)
  };


  getTransaction() {
    if (((this.state.idpel == "") || (this.state.idpel == null)) || ((this.state.created_at == "") || (this.state.created_at == null))) {
      Swal.fire({
        title: 'Maaf :)',
        text: 'Anda harus memilih tanggal transaksi terlebih dahulu',
        icon: 'info',
        confirmButtonText: 'oke'
      })
    } else {
      this.setState({ loading: true })
      let store = process.env.REACT_APP_TOKEN;
      let token = "Bearer " + store
      fetch(`${process.env.REACT_APP_URL}/transaction/check/history?created_at=${this.state.created_at}&idpel=${this.state.idpel}`, {
        method: "GET",
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
      }).then((response) => {
        response.json().then((result) => {
          console.warn("result", result);
          this.setState({ response: result.data })
          console.log(this.state.response)
          this.setState({ loading: false })
        })
      })
    }
  }


  rupiahFormat(num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  }



  render() {

    return (
      <div>
        <section className="section p-0px-t section-top-up-100 bg-white">
          <div className="container">
            <div className="card shadow">
              <div className="card-header" style={{ backgroundColor: '#f6f9ff' }}>
                <h5 className="mt-3"> Cek Status Pembayaran</h5>
              </div>
              <div className="card-body">

                <div className="row mt-3">
                  <div className="col-md-4 mb-2">
                    <div className="form-group">
                      <label className="text-dark">Tanggal Transaksi</label>
                      <input type="date" className="form-control" onChange={(event) => this.setState({ created_at: event.target.value })} />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="form-group">
                      <label className="text-dark">ID Pelanggan</label>
                      <input
                        type="text"
                        className="form-control mb-2"
                        placeholder="ID Pelanggan"
                        name="idpel"
                        onKeyUp={(e) => {
                          this.handleChangeIdpel(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    {
                      this.state.login ?
                        <div>
                          <LaddaButton
                            loading={this.state.loading}
                            onClick={() => { this.getTransaction() }}
                            data-color="#008aff"
                            data-size={l}
                            style={{marginTop:"35px"}}
                            className="m-btn m-btn-theme m-btn-radius w-100"
                            data-style={EXPAND_RIGHT}>
                            Cari Transaksi
                          </LaddaButton>
                        </div>
                        :
                        <button className="btn btn-primary w-100" >Cari</button>
                    }
                  </div>
                </div>

                <div className="row d-flex justify-content-center">
                </div>
              </div>
            </div>
            <div className="card mt-3 shadow">
              <div className="card-header">
                <h6 className="pt-4 pb-2 text-muted">Hasil Pencarian</h6>
              </div>
              <div className="card-body text-dark">
                {
                  this.state.loading ? (
                    <div className="w-100 text-center justify-content-center">
                      <Loading type="bubbles" color="#008aff" className="text-center m-auto" />
                    </div>
                  ) : (
                    <div>
                      {this.state.response.length > 0
                        ? (
                          <div>
                            {this.state.response.map((value, index) => {
                              return (
                                <div key={value.transaction_id}>
                                  <div className="row m-2">
                                    <div className="col-md-1">
                                      {value.status == "PAID" ? (
                                        <div>
                                          <div className="card bg-success p10 pt-3 text-center white-bg box-shadow border-radius-5 border-0 theme-hover-bg text-decoration-none">
                                            <div className="only-icon-30 d-inline-block w-100 mb-3">
                                              <FaCheck className="text-white" />
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div>
                                          <div className="card bg-warning p10 pt-3 text-center white-bg box-shadow border-radius-5 border-0 theme-hover-bg text-decoration-none">
                                            <div className="only-icon-30 d-inline-block w-100 mb-3">
                                              <FaClock className="text-white" />
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-md-5">
                                      <b htmlFor="product">
                                        {value.name} -{" "}
                                      </b>{" "}
                                      <label htmlFor="amount">
                                        {this.rupiahFormat(
                                          parseFloat(value.amount)
                                        )}
                                      </label>
                                      <br />
                                      <small htmlFor="">
                                        <Moment
                                          format="DD MMM YYYY - HH:mm"
                                          tz="Asia/Jakarta"
                                        >
                                          {moment(
                                            // value.payment_created_datetime.const
                                            value.created_at
                                          ).add(0, "hours")}
                                        </Moment>
                                      </small>
                                    </div>
                                    <div className="col-md-3">
                                      <small>{value.idpel}</small>
                                      <br />
                                      <label htmlFor="product">
                                        {value.status}
                                      </label>
                                    </div>
                                    <div className="col-md-3 text-center">
                                      {value.status == "PAID" ? (
                                        <div>
                                          <small className="text-muted small">
                                            *struk pembayaran telah kami kirim ke email
                                          </small>
                                        </div>
                                      ) : (
                                        <div>
                                          {value.status == "PENDING" ? (
                                            <div>
                                              <a
                                                className="btn btn-lg btn-warning btn-block"
                                                target="_blank"
                                                href={value.invoice_url}
                                              >
                                                BAYAR
                                              </a>
                                              <small className="text-muted small">
                                                *Menunggu Pembayaran
                                              </small>
                                            </div>
                                          ) : (
                                            <div>EXPIRED</div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <hr />
                                </div>
                              );
                            })
                            }
                          </div>
                        )
                        : (
                          <div className="m-auto w-100 text-center">
                            <img
                              className="m-auto"
                              src="https://cdn.dribbble.com/users/1113690/screenshots/6231933/empty_state_bino.jpg"
                              style={{ width: "300px", margin: "auto" }}
                              alt="" />
                            <h5>Tidak Ada Transaksi</h5>
                          </div>
                        )
                      }
                    </div>
                  )
                }
              </div>
            </div>

          </div>
        </section>
      </div>
    )
  }
}

export default Chektransaksi;