import React from 'react';
import NumberFormat from 'react-number-format';


export const TelkomInq = (props) => (
<>
<tr className="table-borderless">
    <td>ID PELANGGAN</td>
    <td className="text-right">{props.struk.id_pelanggan}</td>
</tr>
<tr>
    <td>NAMA</td>
    <td className="text-right">{props.struk.nama_pelanggan}</td>
</tr>
<tr>
    <td>BL/TH</td>
    <td className="text-right">{props.struk.bulan_thn}</td>
</tr>

<tr>
    <td>RP TAG</td>
    <td className="text-right"><NumberFormat value={props.struk.jumlah_tagihan} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr>

<tr>
    <td>ADMIN BANK</td>
    <td className="text-right"><NumberFormat value={props.struk.jumlah_adm} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} renderText={value => <div>{value}</div>} /></td>
</tr>
</>
);