import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { FaCheckCircle, FaWhatsapp, FaTimesCircle } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import NumberFormat from "react-number-format";
import axios from "axios";
import { AuthContext } from "../../router/Router";
import ReactLoading from "react-loading";
import { PlnPrepaid } from "../Element/PlnPrepaid";
import { PlnPostpaid } from "../Element/PlnPostpaid";
import { Bpjs } from "../Element/Bpjs";
import { PulsaData } from "../Element/PulsaData";
import { Telkom } from "../Element/Telkom";
import { PDAM } from "../Element/PDAM";
import LaddaButton, { l, EXPAND_RIGHT } from "react-ladda";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";

const Loading = ({ type, color }) => (
  <ReactLoading type={type} color={color} height={"10%"} width={"10%"} />
);

const ProdukData = ({
  produk,
  data,
  transaction,
  product_type = null,
  adminBank = 0,
  kel_produk = null,
  discount = null,
}) => {
  if (produk == 100) {
    return (
      <PlnPostpaid struk={data} transaction={transaction} discount={discount} />
    );
  } else if (produk === 80) {
    return (
      <PlnPrepaid struk={data} transaction={transaction} discount={discount} />
    );
  } else if (produk === 375) {
    return <Bpjs struk={data} transaction={transaction} discount={discount} />;
  } else if (produk === 101) {
    return (
      <Telkom struk={data} transaction={transaction} discount={discount} />
    );
  } else if (produk === 102) {
    return (
      <Telkom struk={data} transaction={transaction} discount={discount} />
    );
  } else if (product_type === "PDAM") {
    return <PDAM struk={data} transaction={transaction} discount={discount} />;
  } else if (product_type == "PULSA" || product_type == "PAKET DATA") {
    return (
      <PulsaData
        struk={data}
        adminBank={adminBank}
        product_type={product_type}
        transaction={transaction}
        discount={discount}
      />
    );
  } else {
    return (
      <div className="text-center w-100">
        <ReactLoading
          type={"bubbles"}
          color={"#007bff"}
          height={"10%"}
          width={"10%"}
          className="m-auto"
        />
      </div>
    );
  }
};

const Header = ({
  produk = null,
  data = null,
  product_type = null,
  amount = 0,
  transaction = null,
  discount = null,
}) => {
  if (data.rc == "0000" || data.rc == "00") {
    if (produk === 100) {
      return (
        <div>
          <div className="row">
            <div className="col-md-12 col-12">
              <h6>{data.product_name.toUpperCase()}</h6>
            </div>
          </div>
          <div className="card border border-primary">
            <div className="card-header">
              <div className="row mt-2">
                <div className="col-md-6 col-6 text-left m-auto">
                  <h6>Total Pembayaran</h6>
                </div>
                <div className="col-md-6 col-6 text-right m-auto">
                  <h6>
                    <NumberFormat
                      value={transaction.amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rp. "}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (produk === 80) {
      return (
        <div>
          <div className="row">
            <div className="col-md-12 col-12">
              <h6>{data.product_name.toUpperCase()}</h6>
            </div>
          </div>
          <h6 className="mt-1">Token</h6>
          <div className="card border border-warning">
            <div className="card-header">
              <div className="row mt-2">
                <div className="col-md-12 col-12 text-center m-auto text-warning">
                  <h4 className="text-warning">
                    <NumberFormat
                      format="#### - #### - #### - #### - ####"
                      value={data.token}
                      displayType={"text"}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <h6 className="mt-3">Jumlah Kwh : {data.jml_kwh}</h6>
          <div className="card border">
            <div className="card-header">
              <div className="row mt-2">
                <div className="col-md-6 col-6 text-left m-auto">
                  <h6>Total Bayar</h6>
                </div>
                <div className="col-md-6 col-6 text-right m-auto">
                  <h6>
                    <NumberFormat
                      value={transaction.amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rp. "}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (produk == 375) {
      return (
        <div>
          <div className="row">
            <div className="col-md-12 col-12">
              <h6>{data.product_name.toUpperCase()}</h6>
            </div>
          </div>
          <div className="card border border-primary">
            <div className="card-header">
              <div className="row mt-2">
                <div className="col-md-6 col-6 text-left m-auto">
                  <h6>Total Pembayaran</h6>
                </div>
                <div className="col-md-6 col-6 text-right m-auto">
                  <h6>
                    <NumberFormat
                      value={transaction.amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rp. "}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (produk == 102 || produk == 101) {
      return (
        <div>
          <div className="row">
            <div className="col-md-12 col-12">
              <h6>{data.product_name.toUpperCase()}</h6>
            </div>
          </div>
          <div className="card border border-primary">
            <div className="card-header">
              <div className="row mt-2">
                <div className="col-md-6 col-6 text-left m-auto">
                  <h6>Total Pembayaran</h6>
                </div>
                <div className="col-md-6 col-6 text-right m-auto">
                  <h6>
                    <NumberFormat
                      value={transaction.amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rp. "}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (product_type == "PDAM") {
      return (
        <div>
          <div className="row">
            <div className="col-md-12 col-12">
              <h6>{data.product_name.toUpperCase()}</h6>
            </div>
          </div>
          <div className="card border border-primary">
            <div className="card-header">
              <div className="row mt-2">
                <div className="col-md-6 col-6 text-left m-auto">
                  <h6>Total Pembayaran</h6>
                </div>
                <div className="col-md-6 col-6 text-right m-auto">
                  <h6>
                    <NumberFormat
                      value={transaction.amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rp. "}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (product_type == "PULSA" || product_type == "PAKET DATA") {
      if (data.code == "0000") {
        return (
          <div>
            <div className="row">
              <div className="col-md-12 col-12">
                <h6>{product_type}</h6>
              </div>
            </div>

            <div className="card border border-primary">
              <div className="card-header">
                <div className="row mt-2">
                  <div className="col-md-6 col-6 text-left m-auto">
                    <h6>Total Pembayaran</h6>
                  </div>
                  <div className="col-md-6 col-6 text-right m-auto">
                    <h6>
                      <NumberFormat
                        value={parseInt(transaction.amount)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rp. "}
                        renderText={(value) => <div>{value}</div>}
                      />
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return <div></div>;
      }
    } else {
      return (
        <div>
          <ReactLoading
            type={"bubbles"}
            color={"#007bff"}
            height={"10%"}
            width={"10%"}
            className="m-auto"
          />
        </div>
      );
    }
  } else if (
    (data.rc == "0001" && product_type == "PULSA") ||
    product_type == "PAKET DATA"
  ) {
    return (
      <div>
        <div className="row">
          <div className="col-md-12 col-12">
            <h6>
              {transaction.description} - {transaction.idpel}
            </h6>
          </div>
        </div>
        <div className="card border">
          <div className="card-header">
            <div className="row mt-2">
              <div className="col-md-6 col-6 text-left m-auto">
                <h6>Total Pembayaran</h6>
              </div>
              <div className="col-md-6 col-6 text-right m-auto">
                <h6>
                  <NumberFormat
                    value={transaction.amount}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"Rp. "}
                    renderText={(value) => <div>{value}</div>}
                  />
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    // function abnormal transaction . please only pick response from transaction tag if you want
    if (data.rc != null && data.code != null) {
      return (
        <div>
          <div className="row">
            <div className="col-md-12 col-12">
              <h6>
                REFOUND {transaction.description} - {transaction.idpel}
              </h6>
            </div>
          </div>
          <div className="card border">
            <div className="card-header">
              <div className="row mt-2">
                <div className="col-md-6 col-6 text-left m-auto">
                  <h6>Total Refound</h6>
                </div>
                <div className="col-md-6 col-6 text-right m-auto">
                  <h6>
                    <NumberFormat
                      value={transaction.amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rp. "}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
};

function Invoice(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isReload, setReload] = useState(false);
  const [display, setDisplay] = useState([]);
  const [harga, setHarga] = useState();
  const [total, setTotal] = useState();
  const [produk, setProduk] = useState();
  const [idpel, setIdpel] = useState();
  const [product_id, setProduct_id] = useState();
  const [status, setStatus] = useState();
  const [nama, setNama] = useState();
  const [isAuth, setAuth] = useState(0);
  const [struk, setStruk] = useState([]);
  const [discount, setDiscount] = useState([]);
  const [transaction, setTransaction] = useState([]);
  const pulsaStruk = JSON.parse(localStorage.getItem("private_struk"));
  const token = process.env.REACT_APP_TOKEN;
  const { dispatch, state } = useContext(AuthContext);
  const ref = React.createRef();
  const options = {
    orientation: "landscape",
  };

  let identifier = useParams();

  const getData = async () => {
    await setIsLoading(true);
    try {
      let response = await axios
        .get(
          `${process.env.REACT_APP_URL}/transaction/reprint/${identifier.Id}`,
          // `${process.env.REACT_APP_URL}/merchant/status/${identifier.Id}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .catch((err) => {
          // console.log(err.response.data)
          if (err.response.data) {
            if (err.response.data.status == 401) {
              setAuth(401);
            }
          }
        });
      // console.log(response.data);
      if (response.data.data.discounts != null) {
        await setDiscount(response.data.data.discounts);
      }
      await setStruk(response.data.data.invoice);
      await setTransaction(response.data.data.transactions);
      await setIsLoading(false);

      // console.warn("struk", struk);
      // console.warn("transaction", transaction);
    } catch (e) {
      console.warn("error", e);
    }
    await setIsLoading(false);
  };

  useEffect(() => {
    // setIsLoading(true);
    getData();
    // setIsLoading(false);
  }, [identifier, setStruk]);

  if (isAuth === 401) {
    dispatch({
      type: "LOGOUT",
    });
  }
  return (
    <div>
      {isLoading ? (
        <div>
          <section
            className="g-bg-gray p-0px-t section-top-up-100"
            style={{ display: display }}>
            <div className="container" style={{ maxWidth: "600px" }}>
              <div className="row">
                <div className="col-sm-12 col-lg-12 col-12 m-15px-tb ">
                  <div className="card border-radius-2 text-center m-auto py-3">
                    <ReactLoading
                      type={"spin"}
                      color={"#007bff"}
                      height={"10%"}
                      width={"10%"}
                      className="mx-auto my-5"
                    />
                    <h6>Mengambil Data</h6>
                    <label htmlFor="loading">
                      Tunggu sebentar kami sedang mengambil data transaksi anda{" "}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <>
          {struk.length == 0 ? (
            <div>
              <section
                className="g-bg-gray p-0px-t section-top-up-100"
                style={{ display: display }}>
                <div className="container" style={{ maxWidth: "600px" }}>
                  <div className="row">
                    <div className="col-sm-12 col-lg-12 col-12 m-15px-tb ">
                      <div className="card border-radius-2 text-center m-auto py-3 align-items-center">
                        <FaTimesCircle
                          className="text-danger"
                          style={{ fontSize: "80px" }}
                        />
                        <label htmlFor="loading" className="mt-4">
                          Transaksi tidak ditemukan
                        </label>
                        <NavLink className="btn btn-success mt-2" to="/pln">
                          Transaksi Sekarang
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          ) : (
            <div>
              <section
                className="g-bg-gray p-0px-t section-top-up-100"
                style={{ display: display }}>
                <div className="container" style={{ maxWidth: "600px" }}>
                  <div className="row">
                    <div className="col-sm-12 col-lg-12 col-12 m-15 px-tb">
                      <div className="card border-radius-2 text-center m-auto">
                        {struk.rc == "0000" ||
                        struk.rc == "00" ||
                        struk.code == "0000" ||
                        struk.code == "00" ? (
                          <div>
                            <img
                              className="mt-5 mb-2"
                              src="./../img/hand-oke-g.png"
                              style={{ width: "120px", margin: "auto" }}
                            />
                            <h5>Transaksimu Berhasil</h5>
                          </div>
                        ) : (
                          <div>
                            {struk.rc == "0001" ? (
                              <>
                                <div>
                                  <img
                                    className="mt-5"
                                    src="https://www.flaticon.com/svg/static/icons/svg/4244/4244329.svg"
                                    style={{ width: "70px", margin: "auto" }}
                                  />
                                  <h5 className="mt-3">
                                    Transaksi Dalam Proses
                                  </h5>
                                </div>
                              </>
                            ) : (
                              <>
                                {struk.rc != null ? (
                                  <div>
                                    <img
                                      className="mt-5"
                                      src="https://cdn.dribbble.com/users/1373705/screenshots/6079781/no_transaction_yiran.png"
                                      style={{
                                        width: "70px",
                                        margin: "auto",
                                      }}
                                    />
                                    <h5>
                                      Transaksimu dalam proses penanganan{" "}
                                    </h5>
                                  </div>
                                ) : (
                                  <div>
                                    <img
                                      className="mt-5"
                                      src="https://cdn.dribbble.com/users/4841378/screenshots/13921868/media/cea5408c3416011ae98e7c16658664df.png"
                                      style={{
                                        width: "70px",
                                        margin: "auto",
                                      }}
                                    />
                                    <h5>Transaksimu Tidak Ditemukan </h5>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        )}
                        <div className="row mt-5">
                          <div className="col-md-6 col-6">
                            <label className="small">
                              <Moment format="DD MMM YYYY - HH:mm:ss">
                                {transaction.create_transaction}
                              </Moment>
                            </label>
                          </div>
                          <div className="col-md-6 col-6 m-auto">
                            <label href="#" className="small">
                              {struk.trx_id}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-lg-12 col-12 mt-2">
                      <div className="card border-radius-20">
                        <div className="card-header">
                          {struk.rc == "0000" ||
                          struk.rc == "00" ||
                          struk.code == "0000" ? (
                            <div>
                              <FaCheckCircle className="mr-2 text-success" />
                              <label>Transaksi Berhasil</label>
                            </div>
                          ) : (
                            <div>
                              {struk.rc == "0090" || struk.rc == "0001" ? (
                                <div>
                                  <FaClock className="mr-2 text-warning" />
                                  <label>Transaksi Dalam Proses</label>
                                </div>
                              ) : (
                                <div>
                                  <FaClock className="mr-2 text-warning" />
                                  <label htmlFor="">
                                    Transaksi Tidak di temukan
                                  </label>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <Header
                                produk={struk.product_id}
                                data={struk}
                                product_type={struk.product_type}
                                amount={struk.amount}
                                transaction={transaction}
                                discount={discount}
                              />
                              {struk.rc == "0001" ? (
                                <>
                                  <div className="row mt-2">
                                    <div className="col-md-6 col-6 text-left m-auto">
                                      <label>Metode Pembayaran</label>
                                    </div>
                                    <div className="col-md-6 col-6 text-right m-auto">
                                      <label>
                                        {transaction.payment_method.replace(
                                          "_",
                                          " "
                                        )}{" "}
                                        -{" "}
                                        {transaction.payment_channel.replace(
                                          "_",
                                          " "
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6 col-6 text-left m-auto">
                                      <label>Tanggal Bayar</label>
                                    </div>
                                    <div className="col-md-6 col-6 text-right m-auto">
                                      <label>
                                        <Moment format="DD MMM YYYY - HH:mm:ss">
                                          {transaction.paid_at}
                                        </Moment>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-12 col-12 mb-3 text-center">
                                      <LaddaButton
                                        loading={isLoading}
                                        onClick={() => {
                                          getData();
                                        }}
                                        data-color="#008aff"
                                        data-size={l}
                                        className="btn btn-primary btn-lg border w-100 font-weight-bold text-white shadow"
                                        data-style={EXPAND_RIGHT}>
                                        Perbarui Status
                                      </LaddaButton>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              {struk.rc == "0000" ||
                              struk.rc == "00" ||
                              struk.code == "0000" ? (
                                <>
                                  <div className="row mt-2">
                                    <div className="col-md-6 col-6 text-left m-auto">
                                      <label>Metode Pembayaran</label>
                                    </div>
                                    <div className="col-md-6 col-6 text-right m-auto">
                                      <label>
                                        {transaction.payment_method
                                          .toString()
                                          .replace("_", " ")}{" "}
                                        - {transaction.payment_channel}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6 col-6 text-left m-auto">
                                      <label>Tanggal Bayar</label>
                                    </div>
                                    <div className="col-md-6 col-6 text-right m-auto">
                                      <label>
                                        <Moment format="DD MMM YYYY - HH:mm:ss">
                                          {transaction.paid_at}
                                        </Moment>
                                      </label>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div>
                                  {/*  kondisi abormal di sini  */}
                                  {struk.rc == "0090" ? (
                                    <div className="mt-2">
                                      <a
                                        href={
                                          "https://api.whatsapp.com/send?phone=6283870574102&text=*info transaksi pending* \n id_transaksi=" +
                                          struk.trx_id +
                                          "\n produk =" +
                                          transaction.description +
                                          "\n idpel =" +
                                          transaction.idpel +
                                          "\n pay time =" +
                                          transaction.paid_at
                                        }
                                        className="w-100 btn btn-success"
                                        target="_blank">
                                        Proses Refound{" "}
                                        <FaWhatsapp className="ml-2" />
                                      </a>
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion accordion-03 m-30px-b mt-3"
                        ref={ref}>
                        {struk.rc == "0000" || struk.rc == "00" ? (
                          <div>
                            <div className="acco-group">
                              <a className="acco-heading">Detail Transaksi</a>
                              <div
                                className="acco-des"
                                style={{ display: "block" }}>
                                <table className="table small">
                                  <tbody>
                                    <ProdukData
                                      produk={struk.product_id}
                                      data={struk}
                                      transaction={transaction}
                                      product_type={struk.product_type}
                                      adminBank={struk.admin_charge}
                                      kel_produk={struk.product_name}
                                      discount={discount}
                                    />
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            {struk.rc == "0090" ? (
                              <div>
                                <div className="acco-group">
                                  <a className="acco-heading">
                                    Detail Transaksi
                                  </a>
                                  <div
                                    className="acco-des"
                                    style={{ display: "block" }}>
                                    <table className="table small">
                                      <tbody>
                                        <tr className="table-borderless">
                                          <td>Deskripsi Transaksi</td>
                                          <td className="text-right text-danger">
                                            {struk.desc}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>ID transaksi</td>
                                          <td className="text-right text-danger">
                                            {struk.trx_id}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                {struk.rc == null ? (
                                  <div></div>
                                ) : (
                                  <div>
                                    {(struk.rc == "0001" &&
                                      struk.product_type == "PULSA") ||
                                    struk.product_type == "PAKET DATA" ? (
                                      <></>
                                    ) : (
                                      <div>
                                        <div>
                                          <div className="acco-group">
                                            <a className="acco-heading">
                                              Detail Transaksi
                                            </a>
                                            <div
                                              className="acco-des"
                                              style={{ display: "block" }}>
                                              <table className="table small">
                                                <tbody>
                                                  <tr className="table-borderless">
                                                    <td>Deskripsi Transaksi</td>
                                                    <td className="text-right text-danger">
                                                      {struk.desc}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>ID transaksi</td>
                                                    <td className="text-right text-danger">
                                                      {struk.trx_id}
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Invoice;
